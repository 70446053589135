import _ from "lodash";

export const addAllKeyWordsIntoArrays = (array, key) => {
    if (array.length > 0) {
        let Obj = { ...array[0] };
        Object.keys(Obj).map((x) => { Obj[x] = null; key ? Obj[key] = 'All' : '' });
        return [Obj].concat(array);
    } else return array;
};

export const addAllKeyWordsIntoArrays_2 = (array, key) => {
    console.log('array, key :', array, key);
    if (array.length > 0) {
        let Obj = { ...array[0] };
        Object.keys(Obj).map((x) => { Obj[x] = null; key ? Obj[key] = 'All' : '' });
        console.log('Obj :', Obj);
        return [Obj].concat(array);
    } else return array;
};

export const generateRandomCode = (max) => {
    return Array(max).fill('').reduce((accumulator) => accumulator += Math.floor(Math.random() * (max - 2) + 2), '');
};

export const removeDuplicates = (originalArray, prop) => {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
    return newArray;
}

export const getSeatLayoutModified = (
    flatSeats = [],
    screen_seat_type = [],
    oldSeatlayout = []
  ) => {
    let seatsRowsRaw = [];
  
    /** get the grid */
    seatsRowsRaw = _(flatSeats)
      .groupBy(item => String(item.sl_row_num).padStart(4, "0"))
      .sortBy(group => flatSeats.indexOf(group[0]))
      .value()
      .map(row => {
        row.forEach(singleSeat => {
          singleSeat.selectStatus = singleSeat.SelectStatusFlag = false;
        });
        /** sorting by col asc for each row, just in case */
        return row.sort((a, b) => a.sl_col_num - b.sl_col_num);
      });
  
    let ssts = [];
    let sstsPayload = Array.from(screen_seat_type);
    let ssts_seats = [];
    let seatsRowsRawPrevIndex = 0;
    let seatsRowsRawlength = seatsRowsRaw.length;
  
    /** get sst seat types from the grid */
    seatsRowsRaw.forEach((row, index) => {
      let sst = sstsPayload.filter(x => x.sst_id == row[0].sst_id);
      if (ssts.length > 0 && ssts[ssts.length - 1].sst_id != row[0].sst_id) {
        ssts.push(sst[0]);
      }
      if (ssts.length == 0) {
        ssts.push(sst[0]);
      }
      /** get seat slice for sst seat types of the grid */
      const rowNext = seatsRowsRaw[index + 1];
      if (rowNext && row[0].sst_id != rowNext[0].sst_id) {
        ssts_seats.push(getSubarray(seatsRowsRaw, seatsRowsRawPrevIndex, index));
        seatsRowsRawPrevIndex = index + 1;
      }
      if (rowNext == undefined) {
        ssts_seats.push(getSubarray(seatsRowsRaw, seatsRowsRawPrevIndex, index));
        seatsRowsRawPrevIndex = seatsRowsRawlength;
      }
    });
  
    if (ssts.length !== ssts_seats.length) {
      //   throw new Error('something went wrong with the seats array, ssts, ssts_seats doesnt match')
      return oldSeatlayout;
    }
  
    /** zip seat slice into sst seat types of the grid */
    return _.zipWith(ssts, ssts_seats, function(types, seats) {
      return {
        ...types,
        seatType: types.sst_seat_type,
        seat_net_price: seats.flatMap(x => x)[0].seat_net_price || "",
        seat_price: seats.flatMap(x => x)[0].seat_price || "",
        seats: seats.flatMap(x => x),
        seatsFinal: seats.map(x => x),
        debug: seats
          .flatMap(x => x)
          .map(x => x.sl_seat_name)
          .join(",")
      };
    });
  };
  
  const getSubarray = (array = [], fromIndex = 0, toIndex = 0) => {
    let a = array;
    return a.slice(fromIndex, toIndex + 1);
  };