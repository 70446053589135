import Api from './Api'
import axios from 'axios'
import {
  BASEURL
} from '@/config/index'
export default {
  GetCinemaList(payload) {
    return Api().get('/api/admin/cinemas_list?limit=' + payload.limit + '&currentPage=' + payload.currentPage)
  },
  GetCinemaSettingList() {
    return Api().get('/api/admin/cinemas_setting_list')
  },
  InsertValue(payload) {
    return Api().post('api/end/point', payload)
  },
  DeleteValue(payload) {
    return Api().post('api/end/point', payload)
  },
  UpdateValue(payload) {
    return Api().post('api/end/point', payload)
  },
  getCinemaList(payload) {
    return Api().get('ms_cinemas', payload)
  },
  getScreenList(payload) {
    return Api().get('screens', payload)
  },
  addScreenLayout(payload) {
    return Api().post('screen_layouts', payload)
  },
  getSeatType(payload) {
    return Api().get('ms_seat_reserve_types', payload)
  },
  getScreenSeatType(payload) {
    return Api().get('ms_seat_reserve_types', payload)
  },
  getMovieList(payload) {
    return Api().get('movie/movielist', payload)
  },
  getSeatLayout(payload) {
    return Api().get('screen_layouts/' + payload.screenID, payload)
  },
  AddAggregator(payload) {
    return Api().post(`/admin/ms_aggregators`, payload)
  },
  GlobalRequest(body) {
    switch (body.method) {
      case 'GET':
        return Api().get(`${body.url}`)
        break
      case 'POST':
        return Api().post(`${body.url}`, body.payload)
        break
      case 'PUT':
        return Api().put(`${body.url}`, body.payload)
        break
      case 'DELETE':
        return Api().delete(`${body.url}`, body.payload)
        break
      default:
        break
    }
  },
  GlobalUploadImage(payload) {
    // return Api().post('uploadimage',payload)

    const configHeaders = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${BASEURL}/uploadimage`, payload, configHeaders)
        .then(res => resolve(res))
        .catch(error => {
          reject(error)
        })
    })
  },

  GlobalUploadFile(payload) {
    // return Api().post('uploadimage',payload)

    const configHeaders = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${BASEURL}/uploadfile`, payload, configHeaders)
        .then(res => resolve(res))
        .catch(error => {
          reject(error)
        })
    })
  },

  GetGlobalOptions() {
    return Api().get(`/global_options`)
  },
  // GetActiveListByConditionalId(payload) {
  //   return Api().get(`/api/admin/tablelist/${payload.tableName}?activekey=${payload.activekey}&condition_col_name=${payload.condition_col_name}&condition_id=${payload.condition_id}`)
  // },
  GetActiveList(payload) {
    let query = ''
    let length = Object.keys(payload).length
    if (length > 0) {
      query = '?'
      Object.keys(payload).map((x, index) => {
        if (x !== 'tableName') {
          query += index === length - 1 ? `${x}=${payload[x]}` : `${x}=${payload[x]}&`
        }
      })
    }
    return Api().get(`/api/admin/tablelist/${payload.tableName}${query}`)
  },
  /**
   * Denominations
   */
  AddDenomination(payload) {
    return Api().post(`/api/admin/denominations`, payload)
  },
  GetDenomination(country_id, payload) {
    // console.log('payload :', payload);
    return Api().get(`/api/admin/denominations?country_id=${country_id}&limit=${payload.limit}&currentPage=${payload.currentPage}`)
  },
  GetDenominationById(payload) {
    return Api().get(`/api/admin/denominations/${payload.denomination_id}`)
  },
  UpdateDenomination(payload) {
    return Api().put(`/api/admin/denominations/${payload.denomination_id}`, payload)
  },
  /**
   * Denominations End
   */
  /**
   *  Shift
   */
  GetUserShiftsByUserId(payload) {
    return Api().get(`/api/admin/usershiftsbyuserid/${payload.user_id}`)
  },

  /**
   *  ShiftEnd
   */

  GetAllUserShifts(payload) {
    return Api().get(`/user_shifts?limit=${payload.limit}&currentPage=${payload.currentPage}`);
  },
  AddUserShifts(payload) {
    return Api().post(`/user_shifts`, payload)
  },
  GetUserShiftDetails(payload) {
    return Api().get(`/user_shifts/${payload.us_id}`)
  },
  GetAllUserShiftsDates() {
    return Api().get(`/get_user_shifts_dates`)
  },
  UpdateUserShifts(payload) {
    return Api().put(`/user_shifts`, payload)
  },

  /**
   *  User Info
   */

  GetUserInfoById(payload) {
    return Api().get(`/api/admin/userinfo/${payload.user_id}`)
  },

  /**
   *  User Info End
   */

  // DELETE, May be extra
  GetData(payload) {
    console.log('payload :', payload)
    // Delete
    // return Api().delete(`/api/users/${payload.ID}`)
    // POST
    // return Api().post('/api/users', payload)
    // return Api().put('/api/users', payload)
    // GET
    return Api().get('/api/users:', payload)
    // params
    // return Api().get(`/api/users/${payload.ID}`)
    // Query
    // return Api().get(`/api/users?${payload.ID}`)
  },
  AddUnitOfMeasurement(payload) {
    return Api().post('/ms_unit_of_measurement', payload);
  },
  editUnitOfMeasurementOperation(payload) {
    return Api().put(`/ms_unit_of_measurement/${payload.uom_id}`, payload);
  },

  GetUoMTList(payload) {
    return Api().post(`/get_conversion_to_list`, payload)
  },

  GlobalRemoveFile(payload) {
    return Api().post(`/api/admin/removereportfile`, payload);
  },

  AddScreens(payload) {
    return Api().post(`/api/admin/screens`, payload);
  },
  GetScreens(payload) {
    // console.log('payload :', payload);
    return Api().get(`/api/admin/screens?s_id=${payload.s_id}`);
  },

  EditScreens(payload) {
    return Api().put(`/api/admin/screens`, payload);
  },

  AddWeekends(payload) {
    return Api().post(`/api/admin/weekends`, payload);
  },

  GetWeekendsList(cinema_id) {
    return Api().get(`/api/admin/weekends?cinema_id=${cinema_id}`);
  },

  EditWeekends(payload) {
    return Api().put(`/api/admin/weekends`, payload);
  },

  // VERIFY DOCUMENTS API'S
  // BANK API'S
  getAllVerificationDocument(perPage, currentPage) {
    return Api().get(`/api/viewverificationdocuments?limit=${perPage}&currentPage=${currentPage}`);
  },
  getVerificationDocumentDetails(payload) {
    return Api().get(`/api/viewsingledocument/${payload.vd_id}`)
  },
  addVerificationDocument(payload) {
    return Api().post('/api/addverificationdocument', payload)
  },
  editVerificationDocument(payload) {
    return Api().put(`/api/updateverificationdocumnet/${payload.vd_id}`, payload)
  },


}
