const state = {
  items: [],
  paging: {},
  item: {},
  dol: null,
  rol: "",
  isActive: ''
}

const mutations = {
  SET_USER_ITEMS: (state, payload) => {
    state.items = payload
    // const pages = Object.assign(payload.pages, payload.items);
    // state.paging = Object.assign({}, pages);
  },
  SET_USER_ITEM: (state, payload) => {
    state.item = Object.assign({}, payload)
  },
  SET_USER_DOL: (state, payload) => {
    state.dol = payload;
  },
  SET_USER_ROL: (state, payload) => {
    state.rol = payload;
  },
  SET_USER_IsActive: (state, payload) => {
    state.isActive = payload;
  }
}

const actions = {
  setUserItems({ commit }, payload) {
    commit('SET_USER_ITEMS', payload)
  },
  setUserItem({ commit }, payload) {
    commit('SET_USER_ITEM', payload)
  },
  setUserDoL({ commit }, payload) {
    commit("SET_USER_DOL", payload)
  },
  setUserRol({ commit }, payload) {
    commit("SET_USER_ROL", payload)
  },
  setUserisActive({ commit }, payload) {
    commit('SET_USER_IsActive', payload)
  }
}

const getters = {
  userItems: state => state.items,
  userPaging: state => state.paging,
  userItem: state => state.item,
  userDol: state => state.dol,
  userRol: state => state.rol,
  userisActive: state => state.isActive
}

export default {
  state,
  mutations,
  actions,
  getters
}
