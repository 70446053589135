import PosService from "@/services/PosService";
import MasterService from "@/services/MasterService";
import { groupBy } from "lodash";
import { exists, stat } from "fs";
import vue from "vue";
import moment from "moment";
import VueSocketIO from "vue-socket.io";
import store from "@/store";
import { router } from "@/router";
import { authenticationService } from "@/services/AuthenticationService";
import { parse } from "path";
import { dispatch } from "rxjs/internal/observable/pairs";
import { EventBus } from "@/main.js";
import _ from 'lodash';
import { getSeatLayoutModified } from "@/helper/collection";

function initialState() {
  return {
    seatTypes: [
      {
        seatName: "Regular Seat",
        size: 1,
        value: 1,
        imageClass: "regular-seat",
      },
      {
        seatName: "No Seat",
        size: 1,
        value: 2,
        imageClass: "no-seat",
      },
      {
        seatName: "Wheel Chair",
        size: 1,
        value: 3,
        imageClass: "wheelchair",
      },
      {
        seatName: "VIP Seat",
        size: 1,
        value: 5,
        imageClass: "vip-seat",
      },
      {
        seatName: "Only POS",
        size: 1,
        value: 9,
        imageClass: "pointofsale",
      },
      {
        seatName: "Sofa",
        size: 1,
        value: 10,
        imageClass: "sofa-seat",
      },
      {
        seatName: "Recliner",
        size: 1,
        value: 11,
        imageClass: "recliner",
      },
      {
        seatName: "Blocked Seat",
        size: 1,
        value: 12,
        imageClass: "block-seat",
      },
      {
        seatName: "Broken Seat",
        size: 1,
        value: 13,
        imageClass: "broken-seat",
      },
    ],
    disablePayNow: false,
    showGenBtn: false,
    booking_complete: false,
    isCashCardTransaction: false,
    usedCashCards: [],
    paidflag: false,
    paid_amount: 0,
    cashCards: [],
    selectedCashCard: null,
    // timeZone: {
    //     tz_name: "",
    //     tz_utc_offset: ""
    // },
    reservationIdForValidatePoints: "",
    customerID: "",
    seatNames: "",
    cinema_id: "",

    dates: [],
    currentDate: moment().format("ddd DD MMM"),
    currentTime: moment().format("LT"),
    selectedDate: moment().format("DD-MM-YYYY"),
    showTimes: [],
    userINFO: [],
    filteredShowTimes: [],
    selectedFormat: "",
    isDataLoading: true,
    visibleShowsDates: [],
    visibleShowsScreens: [],
    visibleShowsLanguage: [],
    seatLayoutData: [],
    additionalCharges: [],
    seatsSelected: [],
    isVipSeatSelected: false,
    scheduleShowId: "",
    totalAmount: 0,
    cashInput: 0,
    netAmount: 0,
    totalCashCollected: 0,
    totalCardAmount: 0,
    showBookingId: "",
    currentScreen: "",
    showDetails: {},
    offerApplied: {},
    bookingDetails: {},
    ticketakDetails: {},
    shiftDetails: {},
    seatOccupancy: [],
    screens: [],
    cashCardLogs: [],
    customers: [],
    movieDetails: {
      lang_name: null,
      md_thumbnail_url: null,
      movie_cast_crew: [],
      mf_name: null,
      sound_format_name: null,
      //
      mc_plot: null,
      mc_title: null,
      mrrdr_release_date: null,
      rating: null,
      mrrdr_runtime: null,
      g_name: null,
      //
      movie_cast_crew: [],
      //
      movie_production: [],
    },
    enterTicketDetails: true,
    showTicketInfo: false,
    ChangeTemplateFlag: {
      movieDetail: true,
      seatLayout: false,
      checkout: false,
    },
    currency: "",

    //
    ActualTotalAmount: 0,
    ActualNetTotalAmount: 0,

    totalNumberOfSeats: 0,
    prevSeatType: "",
    reservationId: "",
    selectedPaymentMode: {},
    paymentModes: [],
    HardwareName: "",
    booking_id: "",
    isOldCustomer: false,
    customerDetails: {},
    templateDetails: [],
    customerAvailablePoints: 0,
    customerPointsToBeBurn: 0,
    BurnRatio: 0,
    earnPoints: 0,
    isSearch: false,
    isEdit: false,
    LoyaltyProgram: null,
    selectedCountryCode: "91",
    customer_mobile_number: "",
    first_name: "",
    last_name: "",
    email_address: "",
    loyaltyAmount: 0,
    loyaltyAmountCopy: 0,
    boxOfficeLoyaltyAmount: 0,
    fnbLoyaltyAmount: 0,
    isLoyaltyApplied: false,
    ApproverId: null,
    isMultiSelectAllowed: true,
    isOfferValid: null,
    BoxOfficeEjsData: {},
    //
    isReservationDetailsAvailable: false,
    isCashCardActive: false,
    isCashCardDetail: {},
    isGroupExist: false,
    isCompleteRateCard: true,
    seatGroupAll: [],
    seatGroupByType: [],
    newSeatLayoutArrayState: [],
    isSplitUsingLoyaltyCashCard: false,
    showCashCardLogsModal: false,
    cust_mob_code: "",
  };
}
const state = initialState();

const mutations = {
  SET_CUST_MOB_CODE: (state, payload) => (state.cust_mob_code = payload),
  SET_CASH_CARD_LOGS: (state, payload) => (state.cashCardLogs = payload),
  SET_CUSTOMERS: (state, payload) => (state.customers = payload),
  SET_IS_SPLIT_USING_LOYALTY_CASH_CARD: (state, payload) =>
    (state.isSplitUsingLoyaltyCashCard = payload),
  SET_RESERVE_TYPE_GROUP: (state, payload) => {
    state.seatGroupByType = payload;
  },
  SET_NEW_SEAT_LAYOUT: (state, payload) => {
    state.newSeatLayoutArrayState = payload;
  },
  SET_TOTAL_BOX_OFFICE_AMOUNT: (state, payload) => {
    state.totalAmount = payload.totalAmount;
    state.netAmount = payload.netAmount;
  },
  SET_GROUP_RATE_CARD: (state, payload) => {
    state.isGroupExist = payload;
  },
  SET_SEAT_GROUP_ALL: (state, payload) => {
    state.seatGroupAll = payload;
  },

  SET_COMPLETE_RATE_CARD: (state, payload) => {
    state.isCompleteRateCard = payload;
  },
  CHANGE_CASH_CARD: (state, payload) => {
    state.isCashCardActive = true;
    // let newAmount = state.isCashCardDetail.hasOwnProperty('cardAmount') ? state.isCashCardDetail.cardAmount : 0
    // state.totalAmount = state.totalAmount - parseInt(newAmount)
    state.isCashCardDetail = payload;

    // console.log(state.totalAmount + parseInt(payload.cardAmount), state.totalAmount, payload.cardAmount)
    // state.totalAmount = state.totalAmount + parseInt(payload.cardAmount)
  },
  CLEAR_IDS_FOR_PRINT: (state, payload) => {
    state.BoxOfficeEjsData = {};
  },
  SET_SBID_CINEMAID: (state, payload) => {
    state.BoxOfficeEjsData.sb_id = payload.Records[0].sb_id;
    state.BoxOfficeEjsData.cinema_id = payload.Records[0].cine_id;
  },
  SET_VERIFIED_OFFER_STATUS: (state, payload) => {
    state.isOfferValid = payload;
  },
  SET_HARDWARE_NAME: (state, payload) => {
    state.HardwareName = payload.hs_hardware_name;
  },
  ON_RESET_DATE_STORE_STATE: (state, payload) => {
    // state = initialState();
    const s = initialState();
    Object.keys(s).forEach((key) => {
      if (
        "movieDetails" !== key &&
        "dates" !== key &&
        "showTimes" !== key &&
        "selectedDate" !== key &&
        "visibleShowsDates" !== key &&
        "visibleShowsScreens" !== key &&
        "visibleShowsLanguage" !== key &&
        "isDataLoading" !== key
      ) {
        state[key] = s[key];
      }
    });
  },
  SET_TEMPLATES_FLAG: (state, payload) => {
    state.ChangeTemplateFlag = payload;
  },
  SET_CURRENCY: (state, payload) => {
    state.currency = payload;
  },
  SET_SHIFT_DETAILS: (state, payload) => {
    state.shiftDetails = payload;
  },
  SET_EMPTY_SEAT_BOOKING_DETAILS: (state, payload) => {
    state.seatsSelected.map((x) => {
      state.netAmount = 0.0;
      state.totalAmount = 0.0;
      state.seatLayoutData[x.classIndex].seatsFinal[x.rowIndex][
        x.seatIndex
      ].selectStatus = false;
      state.seatLayoutData[x.classIndex].seatsFinal[x.rowIndex][
        x.seatIndex
      ].SelectStatusFlag = false;
    });
    state.seatsSelected = [];
    state.additionalCharges = [];
    state.totalAmount = 0;
    state.netAmount = 0;
    state.offerApplied = {};
    state.reservationId = "";
    state.isSearch = false;
    state.isMultiSelectAllowed = true;
    state.totalNumberOfSeats = 0;
    state.isLoyaltyApplied = false;
    state.loyaltyAmount = 0;
    state.loyaltyAmountCopy = 0;
    state.boxOfficeLoyaltyAmount = 0;
    state.fnbLoyaltyAmount = 0;
    state.first_name = "";
    state.last_name = "";
    state.email_address = "";
    state.isReservationDetailsAvailable = false;
    state.paidflag = false;
    state.paid_amount = 0;
    state.cashCards = [];
    state.selectedCashCard = null;
    state.usedCashCards = [];
    state.isCashCardTransaction = false;
  },
  SET_EMPTY_DATA: (state, payload) => {
    state.seatLayoutData = [];
    state.isSearch = false;
    state.customer_mobile_number = "";
    state.customerAvailablePoints = 0;
    state.customerPointsToBeBurn = 0;
    state.LoyaltyProgram = "";
    state.scheduleShowId = "";
    state.cashInput = 0;
    state.showBookingId = "";
    state.showBookingId = "";
    state.showDetails = {};
    state.totalAmount = 0;
    state.offerApplied = {};
    state.netAmount = 0;
    state.seatsSelected = [];
    state.selectedSeats = [];
    state.bookingDetails = {};
    state.ticketakDetails = {};
    state.additionalCharges = [];
    state.isLoyaltyApplied = false;
    state.isEdit = false;
    state.customerID = "";
    state.loyaltyAmount = 0;
    state.loyaltyAmountCopy = 0;
    state.boxOfficeLoyaltyAmount = 0;
    state.fnbLoyaltyAmount = 0;
    state.first_name = "";
    state.last_name = "";
    state.email_address = "";
    state.isVipSeatSelected = false;
    state.earnPoints = 0;
    state.ApproverId = null;
    state.paidflag = false;
    state.paid_amount = 0;
    state.cashCards = [];
    state.selectedCashCard = null;
    state.usedCashCards = [];
    (state.isCashCardTransaction = false),
      (state.isReservationDetailsAvailable = false);
  },
  GET_CASH_INPUT: (state, payload) => {
    state.cashInput = payload;
  },
  GET_TOTAL_CASH_COLLECTED: (state, payload) => {
    state.totalCashCollected = payload;
  },
  GET_TOTAL_CARD_AMOUNT: (state, payload) => {
    state.totalCardAmount = payload;
  },
  CURRENT_TIME: (state, payload) => {
    state.currentTime = moment().format("LT");
  },
  SET_SCREENS: (state, payload) => {
    state.screens = payload.Records;
  },
  SET_BOOKING_ID: (state, payload) => {
    state.booking_id = payload;
  },
  TICKET_DETAILS: (state, payload) => {
    state.ticketakDetails = payload;
    state.totalAmount = payload.sb_total_amount;
    let payment_mode_details = {
      pm_id: payload.pm_id,
      pm_payment_mode: payload.pm_payment_mode,
      pm_icon_url: null,
      pm_is_active: "Y",
    };
    state.selectedPaymentMode = payment_mode_details;
    state.netAmount = payload.sb_total_net_amount;
    state.ticketakDetails["sb_qr_code"] =
      "http://api.qrserver.com/v1/create-qr-code/?data=" +
      payload.sb_qr_code +
      "&size=100x100";
    state.ticketakDetails["show_date"] = moment(payload.ss_start_date).format(
      "ddd MMM Do, YY"
    );
    state.enterTicketDetails = false;
    state.showTicketInfo = true;
    state.booking_id = payload.sb_id;
  },
  SHOW_BOOKING_DETAILS: (state, payload) => {
    state.bookingDetails = payload.Records[0];
    state.bookingDetails["seats"] = payload.Records[0].seats.join(", ");
    state.bookingDetails["sb_qr_code"] =
      "http://api.qrserver.com/v1/create-qr-code/?data=" +
      payload.Records[0].sb_qr_code +
      "&size=100x100";
    state.bookingDetails["show_date"] = moment(
      payload.Records[0].ss_start_date
    ).format("ddd, Do MMM, YYYY");
    state.bookingDetails["og_show_date"] = payload.Records[0].ss_start_date;
    // state.bookingDetails['ss_start_show_time'] = payload.Records[0]['ss_start_show_time'] ? moment(`${moment(new Date()).format('DD-MM-YYYY')} ${payload.Records[0].ss_start_show_time}`, 'DD-MM-YYYY HH:mm').format('h:mm A') : '';
    // state.bookingDetails['og_show_time'] = payload.Records[0]['ss_start_show_time'] ? moment(`${moment(new Date()).format('DD-MM-YYYY')} ${payload.Records[0].ss_start_show_time}`, 'DD-MM-YYYY HH:mm').format('h:mm A') : '';
    console.log("state.bookingDetails :", state.bookingDetails["og_show_time"]);
  },
  SET_SHOW_DETAILS: (state, payload) => {
    console.log("payload :", payload);
    state.showDetails = payload;
    state.showDetails["og_ss_start_date"] = payload.ss_start_date;
    // console.log('payload.ss_start_date :', payload.actualDate);
    state.showDetails["ss_start_date"] = moment(
      payload.ss_actual_start_date
    ).format("ddd, Do MMM, YYYY");
    state.showDetails["ss_start_show_time"] = payload["ss_start_show_time"]
      ? moment(
          `${moment(new Date()).format("DD-MM-YYYY")} ${
            payload.ss_start_show_time
          }`,
          "DD-MM-YYYY HH:mm"
        ).format("h:mm A")
      : "";

    // tConvert(payload.ss_start_show_time);

    // function tConvert(time) {
    //     // Check correct time format and split into components
    //     time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    //     if (time.length > 1) { // If time format correct
    //         time = time.slice(1);  // Remove full string match value
    //         time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    //         time[0] = +time[0] % 12 || 12; // Adjust hours
    //     }
    //     return time.join(''); // return adjusted time or original string
    // }

    //   tConvert (payload.ss_start_show_time);
  },
  SET_SELECTED_DATE: (state, payload) => {
    state.selectedDate = payload;
    // const pages = Object.assign(payload.pages, payload.items);
    // state.paging = Object.assign({}, pages);
  },
  SET_FORMAT: (state, payload) => {
    state.selectedFormat = payload;
    if (payload !== "All Formats") {
      state.filteredShowTimes = state.showTimes.filter((x) => {
        if (x.movie_format == payload) {
          return x;
        }
      });
    } else {
      state.filteredShowTimes = state.showTimes;
    }
  },
  SET_ALL_DATES: (state, payload) => {
    state.dates = payload.Records;
    // state.dates.push(moment().format('DD-MM-YYYY'));
    // dates.map(x => {
    //   if (moment(x).isAfter(moment().format('DD-MM-YYYY'))) {
    //     state.dates.push(x);
    //   }
    // })

    function parseDate(dateStr) {
      var date = dateStr.split("-");
      var day = date[0];
      var month = date[1] - 1; //January = 0
      var year = date[2];
      return new Date(year, month, day);
    }
  },
  SET_ALL_SHOWS: (state, payload) => {
    state.userINFO = payload._result;
    if (payload != "filter") {
      state.showTimes = payload.Records;
      state.filteredShowTimes = payload.Records;
    }
    //visibleShowsDates
    let showData_Date = groupBy(state.filteredShowTimes, "md_id");
    let DatestempArray = [];
    for (let key in showData_Date) {
      if (showData_Date.hasOwnProperty(key)) {
        DatestempArray.push(showData_Date[key]);
      }
    }
    state.visibleShowsDates = DatestempArray;

    //visibleShowsScreens
    let showData_Screen = groupBy(state.filteredShowTimes, "screen_id");
    let tempArray = [];
    for (let key in showData_Screen) {
      if (showData_Screen.hasOwnProperty(key)) {
        tempArray.push(showData_Screen[key]);
      }
    }
    let finalTempArray = [];
    tempArray.map((item, index) => {
      let tempArray1 = [];
      let newtempArray1 = groupBy(item, "md_id");

      for (let key in newtempArray1) {
        if (newtempArray1.hasOwnProperty(key)) {
          tempArray1.push(newtempArray1[key]);
        }
      }
      finalTempArray.push(tempArray1);
    });
    // console.log('state.visibleShowsScreens :', state.visibleShowsScreens);
    state.visibleShowsScreens = finalTempArray;

    //visibleShowsLanguage
    let showData_language = groupBy(state.filteredShowTimes, "language_name");
    let _tempArray = [];
    for (let key in showData_language) {
      if (showData_language.hasOwnProperty(key)) {
        _tempArray.push(showData_language[key]);
      }
    }
    let _finalTempArray = [];
    _tempArray.map((item, index) => {
      let _tempArray1 = [];
      let _newtempArray1 = groupBy(item, "md_id");

      for (let key in _newtempArray1) {
        if (_newtempArray1.hasOwnProperty(key)) {
          _tempArray1.push(_newtempArray1[key]);
        }
      }
      _finalTempArray.push(_tempArray1);
    });
    // console.log('_finalTempArray :', _finalTempArray);
    state.visibleShowsLanguage = _finalTempArray;
  },
  GET_ADITIONAL_CHARGES: (state, payload) => {
    // state.additionalCharges = payload.Additional_Charges;
    state.additionalCharges = _.sortBy(payload.Additional_Charges, [
      "charge_type_id",
    ]);
    // if (payload.totalAmount) {
    //   state.totalAmount = payload.totalAmount;
    // }
  },
  ON_REMOVE_ALL_SELECTED_SEATS: () => {
    // state.seatLayoutData.map((x) => {
    //     x.seatsFinal.map((y) => {
    //         y.map((z) => {
    //             let selectedSeatsIndex = state.seatsSelected.findIndex((n) => n.sl_id === z.sl_id);
    //             if (selectedSeatsIndex !== -1) {
    //                 z.selectStatus = !z.selectStatus;
    //                 state.seatsSelected.splice(selectedSeatsIndex, 1)
    //             }
    //         })
    //     })
    // });
    state.seatsSelected.map((x) => {
      state.netAmount = 0.0;
      state.totalAmount = 0.0;
      state.seatLayoutData[x.classIndex].seatsFinal[x.rowIndex][
        x.seatIndex
      ].selectStatus = false;
      state.seatLayoutData[x.classIndex].seatsFinal[x.rowIndex][
        x.seatIndex
      ].SelectStatusFlag = false;
    });
    state.seatsSelected = [];
    state.totalAmount = 0;
    state.netAmount = 0;
    state.additionalCharges = [];
    // console.log('object :', object);
  },
  SELECTED_SEATS: (state, payload) => {
    state.seatLayoutData.map((x) => {
      x.seatsFinal.map((y) => {
        y.map((z) => {
          let filterIndex = state.seatsSelected.findIndex((newX) => {
            return newX.sl_id == z.sl_id;
          });
          if (filterIndex >= 0) {
            z.SelectStatusFlag = true;
            z.selectStatus = true;

            EventBus.$emit("ScrollIntoSelectedSeat", z.sl_id);
            // router.push({ path: "/Test", query: z.sl_id });
            // router.replace({ query: z.sl_id });
          } else {
            z.SelectStatusFlag = false;
            z.selectStatus = false;
          }
        });
      });
    });

    // state.seatsSelected.map((seatS,Index)=>{
    //   let findIndex=state.seatLayoutData[seatS.classIndex]
    // })
    //If seat already selected, remove, else add
    // if (!state.isMultiSelectAllowed) {
    //   console.log("here");
    //   state.prevSeatType = payload.classIndex;
    //   console.log("state.prevSeatType :", state.prevSeatType);
    //   state.seatLayoutData[payload.classIndex].seatsFinal[payload.rowIndex][
    //     payload.seatIndex
    //   ].selectStatus = !state.seatLayoutData[payload.classIndex].seatsFinal[
    //     payload.rowIndex
    //   ][payload.seatIndex].selectStatus;
    // }
    // if (
    //   state.seatLayoutData[payload.classIndex].seatsFinal[payload.rowIndex][
    //     payload.seatIndex
    //   ].selectStatus == false
    // ) {
    //   console.log("else");
    //   //find and remove
    //   // state.seatsSelected.map((singleSeat)=>{
    //   // })
    //   state.seatsSelected = state.seatsSelected.filter(function (
    //     singleSeat,
    //     index,
    //     arr
    //   ) {
    //     return !(singleSeat.sl_id == payload.seat.sl_id);
    //   });
    //   state.totalAmount = 0;
    //   state.netAmount = 0;
    //   state.seatsSelected.map(x => {
    //     state.totalAmount = state.totalAmount + x.seat_price;
    //     state.netAmount = state.netAmount + x.seat_net_price;
    //   });

    //   // console.log('1: if state.netAmount :', state.netAmount);
    // }
    // if (
    //   state.seatLayoutData[payload.classIndex].seatsFinal[payload.rowIndex][
    //     payload.seatIndex
    //   ].selectStatus == true
    // ) {
    //   console.log("if :");
    //   // state.seatsSelected = state.seatsSelected.filter(function (singleSeat, index, arr) {
    //   //     return !(singleSeat.sl_id == payload.seat.sl_id)
    //   // });
    //   // console.log('payload.seat :', payload.seat);
    //   if (payload.seat.is_booked == "Y") {
    //     payload.seat.seat_price = 0.0;
    //     payload.seat.seat_net_price = 0.0;
    //     if (!state.isMultiSelectAllowed) {
    //       payload.seat["seatIndex"] = payload.seatIndex;
    //       payload.seat["rowIndex"] = payload.rowIndex;
    //       payload.seat["classIndex"] = payload.classIndex;
    //       console.log("payload.seat :", payload.seat);
    //       state.seatsSelected.push(payload.seat);
    //     }
    //     state.netAmount = 0;
    //     state.totalAmount = 0;
    //     state.seatsSelected.map(x => {
    //       // console.log(' state.netAmount + x.seat_net_price :', state.netAmount, x.seat_net_price);
    //       state.netAmount = 0.0;
    //       state.totalAmount = 0.0;
    //     });
    //     console.log("state.seatsSelected if:", state.seatsSelected);
    //   } else {
    //     if (!state.isMultiSelectAllowed) {
    //       payload.seat["seatIndex"] = payload.seatIndex;
    //       payload.seat["rowIndex"] = payload.rowIndex;
    //       payload.seat["classIndex"] = payload.classIndex;
    //       console.log("objepayload.classIndex;ct :", payload.seat);
    //       state.seatsSelected.push(payload.seat);
    //     }
    //     // state.seatsSelected.push(payload.seat)
    //     state.netAmount = 0;
    //     state.totalAmount = 0;
    //     state.seatsSelected.map(x => {
    //       // console.log(' state.netAmount + x.seat_net_price :', state.netAmount, x.seat_net_price);
    //       state.netAmount = state.netAmount + x.seat_net_price;
    //       state.totalAmount = state.totalAmount + x.seat_price;
    //     });
    //     console.log("state.seatsSelected else:", state.seatsSelected);
    //   }
    //   // console.log('ifstate.netAmount :', state.netAmount);
    // }
    // let seatsArray = "";
    // for (let i in state.seatsSelected) {
    //   console.log("i :", state.seatsSelected[i].sl_seat_name);
    //   seatsArray += " " + state.seatsSelected[i].sl_seat_name;
    // }
    // store.dispatch("onSetAndSendLog", {
    //   action: `Selected seats are : ${seatsArray} `,
    //   page_name: "pos booking",
    //   api_name: ""
    // });

    // console.log(state.seatsSelected)

    // console.log('state.netAmount :', state.netAmount);
  },
  SEAT_SELECTED_TRUE: (state, payload) => {
    payload.seat.selectStatus = true;
    payload.seat["prev_seat_id"] = payload.seat.seat_reserve_type_id;
    // console.log('payload.seat true: ', payload.seat['prev_seat_id']);/
    payload.seat.seat_reserve_type_id = 8;
  },
  SEAT_SELECTED_FALSE: (state, payload) => {
    payload.seat.selectStatus = false;
    console.log("payload.seat false: :", payload.seat["prev_seat_id"]);
    payload.seat.seat_reserve_type_id = payload.seat.prev_seat_id;
    // payload.seat.seat_reserve_type_id = payload.seattype.value;
  },
  DEP_SET_SEAT_LAYOUT: (state, payload) => {
    console.log(payload.seatType.Records, "dsdsaad", payload);
    let filterCheck = payload.seats.Records.filter((x) => {
      return x.rate_card_group.length > 0;
    });
    if (filterCheck.length > 0) {
      state.isGroupExist = true;
      state.isCompleteRateCard = false;
    } else {
      state.isGroupExist = false;
      state.isCompleteRateCard = true;
    }
    state.scheduleShowId = payload.seats.schedule_show_id;
    let seatData = [];
    let seatData1 = groupBy(payload.seats.Records, "sst_seat_type");
    payload.seatType.Records = payload.seatType.Records.sort(function (a, b) {
      return a.sst_order - b.sst_order;
    });
    let tempScreenSeatTypeArray = [];
    payload.seatType.Records.map((type, index) => {
      type["typeIndex"] = index;
      tempScreenSeatTypeArray.push(type);
      for (var key in seatData1) {
        if (seatData1.hasOwnProperty(key)) {
          if (key == type.sst_seat_type) {
            seatData[key] = seatData1[key];
          }
        }
      }
      // seatData.seat
    });
    let tempArray = [];
    for (let key in seatData) {
      if (seatData.hasOwnProperty(key)) {
        let seat_price = 0;
        let seat_price_obj = seatData[key].filter((x) => {
          return x;
        });
        // console.log('seat_price_obj :', seat_price_obj);
        if (seat_price_obj) {
          seat_price = seat_price_obj[0].seat_price;
        }
        // console.log('seat_price :', seat_price);
        tempArray.push({
          seatType: key,
          seat_price: seat_price,
          seats: seatData[key],
        });
      }
    }
    // let finalTempArray = [];
    // tempArray.map(item=>{
    //   let tempArray1 = [];
    //   let newtempArray =groupBy(item,"")
    // })
    let finalArray = [];

    // [{
    //   seatType: 'gold',
    //   seats: []
    // }]

    for (let i = 0; i < tempArray.length; i++) {
      let singleSeatType = tempArray[i];

      let seatData_1 = groupBy(singleSeatType.seats, "sl_row_num");
      // seatData_1 = {
      //   0: [],
      //   1: []
      // }
      let tempArray_1 = [];
      for (let key in seatData_1) {
        if (seatData_1.hasOwnProperty(key)) {
          let seatData_2 = seatData_1[key].map((singleSeat) => {
            singleSeat.selectStatus = false;
            singleSeat.SelectStatusFlag = false;
            return singleSeat;
          });
          seatData_2 = seatData_2.sort(function (a, b) {
            return a.sl_col_num - b.sl_col_num;
          });

          tempArray_1.push(seatData_2);
        }
      }
      // console.log('tempArray_1 before', tempArray_1)

      // console.log('tempArray_1 after', tempArray_1)
      // tempArray_1 = [[], []]
      tempArray[i].seatsFinal = tempArray_1;
      // finalArray.push({ seatType: tempArray, seats: tempArray_1 })
    }
    if (state.totalNumberOfSeats == 0) {
      vmVue.$root.$emit("bv::show::modal", "seat_select_modal");
    }
    // state.seatLayoutData = tempArray;
    // state.oldSeatLayout = _.cloneDeep(tempArray);
    let seatLayoutArr = _.cloneDeep(payload.seats.Records);
    let screenSeatTypeArr = _.cloneDeep(tempScreenSeatTypeArray);
    let oldArr = _.cloneDeep(tempArray);
    state.seatLayoutData = getSeatLayoutModified(
      seatLayoutArr,
      screenSeatTypeArr,
      oldArr
    );
    console.log("state.seatLayoutData :>> ", state.seatLayoutData);
  },
  SET_SEAT_LAYOUT: (state, payload) => {
    state.seatLayoutData = [];
    state.scheduleShowId = payload.seats.schedule_show_id;
    let seatData_1 = groupBy(payload.seats.Records, "sl_row_num");
    payload.seatType.Records = payload.seatType.Records.sort(function (a, b) {
      return a.sst_order - b.sst_order;
    });
    let tempArray = [];
    for (let key in seatData_1) {
      if (seatData_1.hasOwnProperty(key)) {
        let seatData_2 = seatData_1[key].map((singleSeat) => {
          singleSeat["seatName"] = singleSeat.sl_seat_name;
          state.seatTypes.map((x) => {
            if (x.value == singleSeat.seat_reserve_type_id) {
              singleSeat["seatType"] = x;
              singleSeat["selectStatus"] = false;
              singleSeat["SelectStatusFlag"] = false;
              singleSeat["seatTypeImageClass"] = x.imageClass;
            }
            payload.seatType.Records.map((y) => {
              if (y.sst_id == singleSeat.screen_seat_type_id) {
                singleSeat["screenSeatType"] = y;
                singleSeat["sst_seat_type"] = y.sst_seat_type;
              }
            });
          });
          return singleSeat;
        });
        tempArray.push(seatData_2);
      }
    }

    payload.seatType.Records.map((x) => {
      x["seatsFinal"] = [];
      tempArray.map((y) => {
        if (x.sst_id == y[0].screenSeatType.sst_id) {
          x.seatsFinal.push(y);
        }
      });
      state.seatLayoutData.push(x);
    });

    // state.seatLayoutData.reduce((acc, currentItem) => {
    //   payload.seatType.Records.map((y) => {
    //     if (y.sst_id == currentItem[0].screenSeatType.sst_id) {
    //       acc.push({ seatsFinal: currentItem });
    //     }
    //   })
    //   return acc
    // }, [])

    console.log("state.seatLayoutData :>> ", state.seatLayoutData);
    if (state.totalNumberOfSeats == 0) {
      vmVue.$root.$emit("bv::show::modal", "seat_select_modal");
    }
  },
  SHOW_BOOKING_ID: (state, payload) => {
    state.showBookingId = payload.Records;
  },
  CURRENT_SCREEN: (state, payload) => {
    state.currentScreen = payload;
  },
  SET_MOVIE_DETAILS: async (state, payload) => {
    Object.assign(state.movieDetails, payload.Records[0]);
  },
  SET_SEAT_OCCUPANCY: (state, payload) => {
    let movieData = groupBy(payload.Records, "md_id");
    state.seatOccupancy = [];
    let tempArray = [];
    for (let key in movieData) {
      if (movieData.hasOwnProperty(key)) {
        tempArray.push(movieData[key]);
      }
    }
    state.seatOccupancy = tempArray;
    store.dispatch("onSetAndSendLog", {
      action: `SEAT OCCUPANCY FOR : ${state.seatOccupancy} `,
      page_name: "pos booking",
      api_name: "",
    });
  },
  GET_SCHEDULE_SHOW_ID: (state, payload) => {
    state.scheduleShowId = payload;
  },
  //for offers
  GET_TOTAL_AMOUNT: (state, payload) => {
    //For Gross Amount
    state.offerApplied = payload;
    if (payload.offer_applicable_on_gross_amount == "Y") {
      if (payload.offer_type_id == 6) {
        if (payload.pcs_offer_value) {
          if (payload.pcs_offer_value_is_percentage == "N") {
            state.totalAmount =
              parseFloat(state.totalAmount) -
              parseFloat(payload.pcs_offer_value);
            state.offerApplied["oc_discount_value"] = parseFloat(
              payload.pcs_offer_value
            );
          } else if (payload.pcs_offer_value_is_percentage == "Y") {
            state.totalAmount -=
              (parseFloat(state.totalAmount) *
                parseInt(payload.pcs_offer_value)) /
              100;
            state.offerApplied["oc_discount_value"] =
              (parseFloat(state.totalAmount) *
                parseInt(payload.pcs_offer_value)) /
              100;
          }
        }
      } else {
        if (payload.oc_get_free_ticket) {
          // state.seatsSelected.map((x, index) => {
          // if (index < payload.oc_get_free_ticket) {
          state.totalAmount =
            parseFloat(state.totalAmount) -
            parseFloat(state.seatsSelected[0].seat_price) *
              payload.oc_get_free_ticket;
          state.offerApplied["oc_discount_value"] =
            parseFloat(state.seatsSelected[0].seat_price) *
            payload.oc_get_free_ticket;
          state.offerApplied["oc_discount_value_is_percentage"] = "N";
          // }
          // })
        } else if (payload.oc_discount_value) {
          if (payload.oc_discount_value_is_percentage == "N") {
            state.totalAmount =
              parseFloat(state.totalAmount) -
              parseFloat(payload.oc_discount_value);
            state.offerApplied["oc_discount_value"] = parseFloat(
              payload.oc_discount_value
            );
          } else if (payload.oc_discount_value_is_percentage == "Y") {
            state.totalAmount -=
              (parseFloat(state.totalAmount) *
                parseInt(payload.oc_discount_value)) /
              100;
            state.offerApplied["oc_discount_value"] =
              (parseFloat(state.totalAmount) *
                parseInt(payload.oc_discount_value)) /
              100;
          }
        }
      }
    }
    //For Net Amount
    else if (payload.offer_applicable_on_net_amount == "Y") {
      if (payload.offer_type_id == 6) {
        if (payload.pcs_offer_value) {
          if (payload.pcs_offer_value_is_percentage == "N") {
            state.totalAmount =
              parseFloat(state.totalAmount) -
              parseFloat(payload.pcs_offer_value);
            state.offerApplied["oc_discount_value"] = parseFloat(
              payload.pcs_offer_value
            );
          } else if (payload.pcs_offer_value_is_percentage == "Y") {
            state.totalAmount -=
              (parseFloat(state.totalAmount) *
                parseInt(payload.pcs_offer_value)) /
              100;
            state.offerApplied["oc_discount_value"] =
              (parseFloat(state.totalAmount) *
                parseInt(payload.pcs_offer_value)) /
              100;
          }
        }
      } else {
        if (payload.oc_get_free_ticket) {
          // state.seatsSelected.map((x, index) => {
          // if (index < payload.oc_get_free_ticket) {
          state.totalAmount =
            parseFloat(state.totalAmount) -
            parseFloat(state.seatsSelected[0].seat_net_price) *
              payload.oc_get_free_ticket;
          // }
          state.offerApplied = payload;
          state.offerApplied["oc_discount_value"] =
            parseFloat(state.seatsSelected[0].seat_net_price) *
            payload.oc_get_free_ticket;
          state.offerApplied["oc_discount_value_is_percentage"] = "N";
          // })
        } else if (payload.oc_discount_value) {
          if (payload.oc_discount_value_is_percentage == "N") {
            state.totalAmount =
              parseFloat(state.totalAmount) -
              parseFloat(payload.oc_discount_value);
            state.offerApplied["oc_discount_value"] = parseFloat(
              payload.oc_discount_value
            );
          } else if (payload.oc_discount_value_is_percentage == "Y") {
            state.totalAmount -=
              (parseFloat(state.totalAmount) *
                parseInt(payload.oc_discount_value)) /
              100;
            state.offerApplied["oc_discount_value"] =
              (parseFloat(state.totalAmount) *
                parseInt(payload.oc_discount_value)) /
              100;
          }
        }
      }
    }

    state.totalAmount = this.CashCardDetails.hasOwnProperty("cardAmount")
      ? state.totalAmount + parseInt(this.CashCardDetails.cardAmount)
      : state.totalAmount;
    console.log("state.offerApplied :", state.offerApplied);
    store.dispatch("onSetAndSendLog", {
      action: `TOTAL Amount of this selection was : ${state.totalAmount} `,
      page_name: "pos booking",
      api_name: "",
    });
  },
  GET_OFFER: (state, payload) => {
    state.offerApplied = payload;
  },
  SELECT_SEAT_HANDLDER: (state, payload) => {
    let {
      seat,
      rowIndex,
      seatIndex,
      originalIndex,
      backward,
      e,
      type,
      scheduleShowId,
    } = payload;
    handlerSeatSelected(
      seat,
      rowIndex,
      seatIndex,
      originalIndex,
      backward,
      e,
      type
    );
    function handlerSeatSelected(
      seat,
      rowIndex,
      seatIndex,
      originalIndex,
      backward,
      e,
      type
    ) {
      console.log(
        "  seat,>> ",
        seat,
        rowIndex,
        seatIndex,
        originalIndex,
        backward,
        e,
        type
      );
      let firstTime = 0;
      console.log("originalIndex", originalIndex);
      if (!originalIndex && originalIndex != 0) {
        firstTime = 1;
        originalIndex = seatIndex;
      } else if (state.totalNumberOfSeats != state.seatsSelected.length) {
        state.seatLayoutData[type].seatsFinal[rowIndex][
          seatIndex
        ].selectStatus = true;
      }
      if (
        state.seatLayoutData[type].seatsFinal[rowIndex][seatIndex]
          .selectStatus == false
      ) {
        if (state.totalNumberOfSeats != state.seatsSelected.length) {
          state.seatLayoutData[type].seatsFinal[rowIndex][
            seatIndex
          ].selectStatus = true;
        }
      }
      console.log(
        "state.totalNumberOfSeats , state.seatsSelected.length :>> ",
        state.totalNumberOfSeats,
        state.seatsSelected.length
      );
      if (
        state.totalNumberOfSeats == state.seatsSelected.length &&
        firstTime == 1
      ) {
        //deselect all
        console.log("state.seatsSelected :", state.seatsSelected);
        state.seatsSelected.map((x) => {
          console.log("type :", type);
          console.log(
            "state.prevSeatType :",
            state.prevSeatType,
            state.seatLayoutData
          );
          console.log("x 2:", x);
          if (x.sst_id != seat.sst_id) {
            state.seatLayoutData[state.prevSeatType].seatsFinal[x.rowIndex][
              x.seatIndex
            ].selectStatus = false;
            state.seatLayoutData[state.prevSeatType].seatsFinal[x.rowIndex][
              x.seatIndex
            ].SelectStatusFlag = false;
          } else {
            state.seatLayoutData[type].seatsFinal[x.rowIndex][
              x.seatIndex
            ].selectStatus = false;
            state.seatLayoutData[type].seatsFinal[x.rowIndex][
              x.seatIndex
            ].SelectStatusFlag = false;
          }
        });

        state.seatLayoutData.map((zw, index) => {
          console.log(zw);
          if (index != type) {
            zw.seatsFinal.map((diss) => {
              diss.map((sl) => {
                sl.selectStatus = false;
                sl.SelectStatusFlag = false;
              });
            });
          }
        });
        // state.seatsSelected = [];
      }

      if (
        state.seatLayoutData[type].seatsFinal[rowIndex][seatIndex][
          "SelectStatusFlag"
        ] == false
      ) {
        //check if seat is selected, if yes, do nothing else
        //check how many selected
        if (
          parseInt(state.totalNumberOfSeats) - state.seatsSelected.length >
          0
        ) {
          if (seat.sl_seat_name != "") {
            state.seatLayoutData[type].seatsFinal[rowIndex][seatIndex][
              "SelectStatusFlag"
            ] = true;
            state.seatLayoutData[type].seatsFinal[rowIndex][
              seatIndex
            ].selectStatus = true;
            seat.seatIndex = seatIndex;
            seat.rowIndex = rowIndex;
            seat.classIndex = type;
            state.prevSeatType = type;
            state.seatsSelected.push(seat);
            console.log("Nesss", seatIndex);
            seatIndex + 1;
          } else {
            state.seatLayoutData[type].seatsFinal[rowIndex][seatIndex][
              "SelectStatusFlag"
            ] = false;
            state.seatLayoutData[type].seatsFinal[rowIndex][
              seatIndex
            ].selectStatus = false;
          }
          //check on right if available, if yes, select
          if (
            state.seatLayoutData[type].seatsFinal[rowIndex][
              parseInt(seatIndex) + 1
            ] &&
            backward != "backward" &&
            (state.seatLayoutData[type].seatsFinal[rowIndex][
              parseInt(seatIndex) + 1
            ].seat_reserve_type_id == 1 ||
              state.seatLayoutData[type].seatsFinal[rowIndex][
                parseInt(seatIndex) + 1
              ].seat_reserve_type_id == 11 ||
              state.seatLayoutData[type].seatsFinal[rowIndex][
                parseInt(seatIndex) + 1
              ].seat_reserve_type_id == 5 ||
              state.seatLayoutData[type].seatsFinal[rowIndex][
                parseInt(originalIndex) + 1
              ].seat_reserve_type_id == 3)
          ) {
            //                        state.seatsSelected.push(seat);
            //                        state.seatData.SeatLayouts[classIndex].Seats[rowIndex][parseInt(seatIndex) + 1].SelectStatus = true;
            handlerSeatSelected(
              state.seatLayoutData[type].seatsFinal[rowIndex][
                parseInt(seatIndex) + 1
              ],
              rowIndex,
              seatIndex + 1,
              originalIndex,
              backward,
              e,
              type
            );

            //check on left if available, if yes, select
          } else if (
            state.seatLayoutData[type].seatsFinal[rowIndex][
              parseInt(originalIndex) - 1
            ] &&
            (state.seatLayoutData[type].seatsFinal[rowIndex][
              parseInt(originalIndex) - 1
            ].seat_reserve_type_id == 1 ||
              state.seatLayoutData[type].seatsFinal[rowIndex][
                parseInt(originalIndex) - 1
              ].seat_reserve_type_id == 11 ||
              state.seatLayoutData[type].seatsFinal[rowIndex][
                parseInt(originalIndex) - 1
              ].seat_reserve_type_id == 5 ||
              state.seatLayoutData[type].seatsFinal[rowIndex][
                parseInt(originalIndex) - 1
              ].seat_reserve_type_id == 3)
          ) {
            //                        state.seatsSelected.push(seat);
            //                        state.seatData.SeatLayouts[classIndex].Seats[rowIndex][parseInt(originalIndex) - 1].SelectStatus = true;
            handlerSeatSelected(
              state.seatLayoutData[type].seatsFinal[rowIndex][
                parseInt(originalIndex) - 1
              ],
              rowIndex,
              originalIndex - 1,
              originalIndex - 1,
              "backward",
              e,
              type
            );
          }
        } else {
          if (firstTime == 1) {
            state.seatsSelected = [];
            handlerSeatSelected(
              state.seatLayoutData[type].seatsFinal[rowIndex][
                parseInt(seatIndex)
              ],
              rowIndex,
              seatIndex,
              originalIndex,
              null,
              e,
              type
            );
          }
        }
      }
    }
    let vipSeatArray = state.seatsSelected.filter((x) => {
      return x.seat_reserve_type_id == 5 || x.seat_reserve_type_id == 12;
    });
    if (vipSeatArray.length > 0) {
      state.isVipSeatSelected = true;
    } else {
      state.isVipSeatSelected = false;
    }
  },
  NUMBER_OF_SEATS: (state, payload) => {
    state.totalNumberOfSeats = payload;
    if (state.isGroupExist) {
      state.isCompleteRateCard = false;
    }
    // state.seatsSelected.map(x => {
    //   state.netAmount = 0.0;
    //   state.totalAmount = 0.0;
    //   state.seatLayoutData[state.prevSeatType].seatsFinal[x.rowIndex][
    //     x.seatIndex
    //   ].selectStatus = false;
    //   state.seatLayoutData[state.prevSeatType].seatsFinal[x.rowIndex][
    //     x.seatIndex
    //   ].SelectStatusFlag = false;
    // });
    // state.reservationId = '';
    state.prevSeatType = "";
    state.seatsSelected = [];
  },
  ON_RESERVE_SEATS: (state, payload) => {
    state.reservationId = payload;
  },
  SET_APPROVER_ID: (state, payload) => {
    state.ApproverId = payload;
  },
  SET_PAYMENT_MODES: (state, payload) => {
    if (payload.Records && payload.Records.length > 0) {
      payload.Records.map((x) => {
        x["amount"] = 0;
        return x;
      });
      let modes = payload.Records; //.reverse();
      state.paymentModes = modes;
      state.selectedPaymentMode = modes.length > 0 ? modes[0] : {};
    }
  },
  SET_SELECTED_PAYMENT_MODE: (state, payload) => {
    state.selectedPaymentMode = payload;
    // console.log('payload :', payload);
    store.dispatch("onSetAndSendLog", {
      action: `SELECTED ${payload.pm_payment_mode} mode for payment.`,
      page_name: "POS BOOKING Payment Mode",
      api_name: window.location.hash,
    });
  },
  SET_CUSTOMER_DETAILS: (state, payload) => {
    state.isOldCustomer = payload.status;
    state.customerDetails =
      payload.Records && payload.Records.length > 0 ? payload.Records[0] : [];
    state.customerID =
      payload.Records && payload.Records.length > 0
        ? payload.Records[0].cust_id
        : [];
    state.email_address =
      payload.Records && payload.Records.length > 0
        ? payload.Records[0].cust_email
        : "";
    state.first_name =
      payload.Records && payload.Records.length > 0
        ? payload.Records[0].first_name
        : "";
    state.last_name =
      payload.Records && payload.Records.length > 0
        ? payload.Records[0].last_name
        : "";
    state.isSearch = true;
    state.isEdit = true;
  },
  RESET_CUSTOMER_DETAILS: (state) => {
    console.log("fsdfdsfdsfsdfdsResetr");

    state.isLoyaltyApplied = false;
    state.customerAvailablePoints = 0;
    state.earnPoints = 0;
    state.customerPointsToBeBurn = 0;

    state.BurnRatio = 0;
    state.isOldCustomer = false;
    state.customerDetails = {};
    state.customerID = "";
    state.email_address = "";
    state.first_name = "";
    state.last_name = "";
    state.isSearch = false;
    state.isEdit = false;
    // state.customer_mobile_number = "";
  },
  SET_NEW_CUSTOMER_DETAILS: (state, payload) => {
    console.log("payload 1:", payload);
    state.customerID = payload.Customer_ID;
    state.isOldCustomer = true;
    state.isSearch = true;
    state.isEdit = true;
    // console.log('state.isOldCustomer  :', state.isOldCustomer);
    console.log("state.customerID :", state.customerID);
  },
  SET_TEMPLATE_DETAILS: (state, payload) => {
    state.templateDetails = payload;
  },
  RESET_PICKUP_TICKET_DETAILS: (state, payload) => {
    state.ticketakDetails = {};
    state.additionalCharges = [];
    state.bookingDetails = {};
    state.reservation_id = "";
    state.enterTicketDetails = true;
    state.showTicketInfo = false;
    state.totalAmount = 0;
    state.netAmount = 0;
    state.booking_id = null;
    state.selectedPaymentMode = {};
    state.customerID = "";
    state.isSearch = false;
    state.isEdit = false;
    state.isLoyaltyApplied = false;
    state.loyaltyAmount = 0;
    state.loyaltyAmountCopy = 0;
    state.boxOfficeLoyaltyAmount = 0;
    state.fnbLoyaltyAmount = 0;
    state.first_name = "";
    state.last_name = "";
    state.email_address = "";
  },
  SET_LOYALTY_POINTS: (state, payload) => {
    state.customerAvailablePoints = payload.AvailablePoints;
    state.customerPointsToBeBurn = payload.BurnPoints;
    state.BurnRatio = payload.BurnRatio;
    state.loyaltyAmountCopy = payload.LoyaltyAmount;
    state.LoyaltyProgram = payload.LoyaltyProgram;
    state.boxOfficeLoyaltyAmount = payload.boxOfficeLoyaltyAmount;
    state.fnbLoyaltyAmount = payload.fnbLoyaltyAmount;
  },
  SET_EARN_POINTS: (state, payload) => {
    state.earnPoints = payload;
  },
  SET_MOBILE_NUMBER: (state, payload) => {
    state.customer_mobile_number = payload;
  },
  CLEAR_ALL_INPUTS: (state, payload) => {
    state.customerID = "";
    state.loyaltyAmount = 0;
    state.isLoyaltyApplied = false;
    state.customerAvailablePoints = 0;
    state.customerPointsToBeBurn = 0;
    state.BurnRatio = 0;
    state.loyaltyAmountCopy = 0;
    state.boxOfficeLoyaltyAmount = 0;
    state.fnbLoyaltyAmount = 0;
    state.LoyaltyProgram = "";
    state.customer_mobile_number = "";
    state.email_address = "";
    state.first_name = "";
    state.last_name = "";
    state.isCashCardActive = false;
    state.isCashCardDetail = {};
  },
  UPDATE_CUSTOMER_DETAILS: (state, payload) => {
    if (payload.type == "fname") {
      state.first_name = payload.value;
    } else if (payload.type == "lname") {
      state.last_name = payload.value;
    } else if (payload.type == "email") {
      state.email_address = payload.value;
    }
  },
  ON_APPLY_LOYALTY: (state, payload) => {
    state.isLoyaltyApplied = true;
    state.loyaltyAmount = state.loyaltyAmountCopy;
    // let loyaltyPrice = (state.customerPointsToBeBurn * state.BurnRatio) || 0;
    if (store.getters.SelectedFnbItemList.length > 0) {
      let boxofficeFnbTotal =
        store.getters.selectedFnBItemsTotalPrice +
        store.getters.selectedAddonsTotalPrice +
        store.getters.totalAddonsAdditionalTaxes +
        store.getters.TotalAdditionalTaxes +
        store.getters.customizationTotalPrice +
        state.totalAmount;
      state.loyaltyAmount =
        state.loyaltyAmountCopy >= boxofficeFnbTotal
          ? boxofficeFnbTotal
          : state.loyaltyAmountCopy;
    } else {
      state.loyaltyAmount =
        state.loyaltyAmountCopy >= state.totalAmount
          ? state.totalAmount
          : state.loyaltyAmountCopy;
    }
    // state.totalAmount -= loyaltyPrice;
    state.selectedPaymentMode = {
      pm_id: 1,
      pm_payment_mode: "Cash",
    };
  },
  ON_RESET_LOYALTY_DATA: (state, payload) => {
    state.isLoyaltyApplied = false;
    state.loyaltyAmount = 0;

    console.log("RESET_LOY");

    // state.loyaltyAmountCopy = 0;
  },
  SLICE_NUMBER: (state, payload) => {
    state.customer_mobile_number = state.customer_mobile_number.slice(0, -1);
  },
  EDIT_NUMBER: (state, payload) => {
    state.isSearch = false;
    state.isEdit = false;
    state.first_name = "";
    state.last_name = "";
    state.email_address = "";
  },
  CHANGE_SEAT_SELECTER: (state, payload) => {
    state.isMultiSelectAllowed = payload;
    state.reservation_id = "";
    state.seatsSelected.map((x) => {
      state.netAmount = 0.0;
      state.totalAmount = 0.0;
      state.seatLayoutData[x.classIndex].seatsFinal[x.rowIndex][
        x.seatIndex
      ].selectStatus = false;
      state.seatLayoutData[x.classIndex].seatsFinal[x.rowIndex][
        x.seatIndex
      ].SelectStatusFlag = false;
    });
    state.prevSeatType = "";
    state.additionalCharges = [];

    state.totalAmount = 0;
    state.netAmount = 0;
    state.offerApplied = {};
    state.totalNumberOfSeats = 0;
    state.seatsSelected = [];
  },
  SET_CASH_CARDS: (state, payload) => {
    state.cashCards = payload;
    console.log("state.cashCards :>> ", state.cashCards);
  },
  ON_RESET_CASH_CARD_DATA: (state) => {
    state.paidflag = false;
    state.paid_amount = 0;
    state.cashCards = [];
    state.selectedCashCard = null;
    state.usedCashCards = [];
    state.isCashCardTransaction = false;
  },
  SELECT_CASH_CARD: (state, payload) => {
    if (
      state.paidflag == true &&
      parseFloat(state.paid_amount) <
        parseFloat(state.totalAmount) -
          parseFloat(store.getters.getOfferPrice) -
          parseFloat(state.loyaltyAmount) +
          parseFloat(
            store.getters.selectedFnBItemsTotalPrice +
              store.getters.TotalAdditionalTaxes
          ) +
          parseFloat(store.getters.selectedAddonsTotalPrice) +
          parseFloat(store.getters.customizationTotalPrice) +
          parseFloat(store.getters.customizationTaxesPrice)
    ) {
      // state.selectedCashCard['paid_amount'] = state.paid_amount
      // state.usedCashCards.push(state.selectedCashCard)
      state.paidflag = false;
      state.selectedCashCard = payload;
    } else {
      state.selectedCashCard = payload;
    }

    console.log("state.selectedCashCard :>> ", state.selectedCashCard);
  },
  SET_PAY_BUTTON: (state, payload) => {
    state.disablePayNow = payload;
  },
  SET_BALANCE_AMOUNT: (state, payload) => {
    state.selectedCashCard.remaining_balance = payload;
  },
  SET_BALANCE_AFTER_DEDUCTION: (state) => {
    state.selectedCashCard.remaining_balance = parseFloat(
      parseFloat(state.selectedCashCard.remaining_balance) -
        (parseFloat(state.totalAmount) -
          parseFloat(store.getters.getOfferPrice) -
          parseFloat(state.loyaltyAmount) -
          parseFloat(state.paid_amount) +
          parseFloat(store.getters.selectedAddonsTotalPrice))
    ).toFixed(2);
    state.selectedCashCard["paid_amount"] = parseFloat(
      parseFloat(state.totalAmount) -
        parseFloat(store.getters.getOfferPrice) -
        parseFloat(state.loyaltyAmount) -
        parseFloat(state.paid_amount) +
        parseFloat(store.getters.selectedAddonsTotalPrice)
    ).toFixed(2);
    state.usedCashCards.push(state.selectedCashCard);
    state.paid_amount = parseFloat(
      parseFloat(state.paid_amount) +
        parseFloat(state.totalAmount) -
        parseFloat(store.getters.getOfferPrice) -
        parseFloat(state.loyaltyAmount) -
        parseFloat(state.paid_amount) +
        parseFloat(store.getters.selectedAddonsTotalPrice)
    ).toFixed(2);
    state.paidflag = true;
    state.isCashCardTransaction = true;
    state.paymentModes.map((x) => {
      if (x.pm_id == 11) {
        x.amount = state.paid_amount;
      }
    });
    console.log("state.paid_amount :>> ", state.paid_amount);
    console.log(" state.usedCashCards :>> ", state.usedCashCards);
  },
  SET_PAID_AMOUNT: (state) => {
    state.paid_amount += state.selectedCashCard.remaining_balance;
    console.log("state.paid_amount :>> ", state.paid_amount);
    state.selectedCashCard["paid_amount"] =
      state.selectedCashCard.remaining_balance;
    state.usedCashCards.push(state.selectedCashCard);
    state.selectedCashCard.remaining_balance = 0;
    state.paidflag = true;
    state.isCashCardTransaction = true;
    state.paymentModes.map((x) => {
      if (x.pm_id == 11) {
        x.amount = state.paid_amount;
      }
    });
    console.log("state.paid_amount :>> ", state.paid_amount);
    console.log(" state.usedCashCards :>> ", state.usedCashCards);
  },
  SET_BALANCE_AFTER_DEDUCTION_FNB: (state) => {
    state.selectedCashCard.remaining_balance = parseFloat(
      parseFloat(state.selectedCashCard.remaining_balance) -
        (parseFloat(state.totalAmount) -
          parseFloat(store.getters.getOfferPrice) -
          parseFloat(state.loyaltyAmount) +
          parseFloat(store.getters.selectedFnBItemsTotalPrice) +
          parseFloat(store.getters.TotalAdditionalTaxes) -
          parseFloat(state.paid_amount) +
          parseFloat(store.getters.selectedAddonsTotalPrice) +
          parseFloat(store.getters.customizationTotalPrice) +
          parseFloat(store.getters.customizationTaxesPrice))
    ).toFixed(2);
    state.selectedCashCard["paid_amount"] = parseFloat(
      parseFloat(state.totalAmount) -
        parseFloat(store.getters.getOfferPrice) -
        parseFloat(state.loyaltyAmount) +
        parseFloat(store.getters.selectedFnBItemsTotalPrice) +
        parseFloat(store.getters.TotalAdditionalTaxes) -
        parseFloat(state.paid_amount) +
        parseFloat(store.getters.selectedAddonsTotalPrice) +
        parseFloat(store.getters.customizationTotalPrice) +
        parseFloat(store.getters.customizationTaxesPrice)
    ).toFixed(2);
    state.usedCashCards.push(state.selectedCashCard);
    state.paid_amount = parseFloat(
      parseFloat(state.paid_amount) +
        parseFloat(state.totalAmount) -
        parseFloat(store.getters.getOfferPrice) -
        parseFloat(state.loyaltyAmount) +
        parseFloat(store.getters.selectedFnBItemsTotalPrice) +
        parseFloat(store.getters.TotalAdditionalTaxes) -
        parseFloat(state.paid_amount) +
        parseFloat(store.getters.selectedAddonsTotalPrice) +
        parseFloat(store.getters.customizationTotalPrice) +
        parseFloat(store.getters.customizationTaxesPrice)
    ).toFixed(2);
    state.paidflag = true;
    state.isCashCardTransaction = true;
    state.paymentModes.map((x) => {
      if (x.pm_id == 11) {
        x.amount = state.paid_amount;
        console.log("x.amount :>> ", x.amount);
      }
    });
    console.log("state.paid_amount :>> ", state.paid_amount);
    console.log(" state.usedCashCards :>> ", state.usedCashCards);
  },
  GOT_RESERVATION_DETAILS: (state, payload) => {
    state.isReservationDetailsAvailable = true;
  },
  SET_RESERVATION_SEATS: (state, payload) => {
    state.totalAmount = 0;
    state.netAmount = 0;
    payload.map((x) => {
      state.netAmount = state.netAmount + x.rc_net_amount;
      state.totalAmount = state.totalAmount + x.rc_total_amount;
      state.seatsSelected.push(x);
    });
  },
  REMOVE_RESERVATION_DETAILS: (state, payload) => {
    state.isReservationDetailsAvailable = false;
    state.additionalCharges = [];
    state.totalNumberOfSeats = 0;
    state.selectedPaymentMode = {};
    state.isOldCustomer = false;
    state.customerDetails = {};
    state.customerAvailablePoints = 0;
    state.customerPointsToBeBurn = 0;
    state.BurnRatio = 0;
    state.earnPoints = 0;
    state.isSearch = false;
    state.isEdit = false;
    state.LoyaltyProgram = null;
    state.isLoyaltyApplied = false;
    state.isReservationDetailsAvailable = false;
    state.showDetails = {};
    state.seatsSelected = [];
    state.totalAmount = 0;
    state.netAmount = 0;
  },
  SET_PAYMENT_VALUE: (state, payload) => {
    state.paymentModes[payload.index].amount =
      payload.value &&
      payload.value.length > 0 &&
      payload.value.replace(/\s/g, "").length > 0
        ? parseFloat(payload.value)
        : 0;
  },
  SET_BOOKING_COMPLETE: (state) => {
    state.booking_complete = true;
  },
  SHOW_GENERATE_BUTTON: (state) => {
    state.showGenBtn = true;
  },
  SET_RESERVATION_ID_FOR_VALIDATE_POINTS: (state, payload) => {
    state.reservationIdForValidatePoints = payload;
  },
  SET_LOADER: (state, payload) => {
    state.isDataLoading = payload;
  },
  SET_SHOW_CASH_CARD_LOGS_MODAL: (state, payload) =>
    (state.showCashCardLogsModal = payload),
};

const actions = {
  async updateCashCardAmount({ dispatch, commit, state }, payload) {
    try {
      const { data } = await PosService.UpdateCashCardAmount(payload);
      if (data && data.status) {
        vue.toasted.show(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async getCustomerList({ dispatch, commit, state }, { ...payload }) {
    try {
      const { data } = await PosService.getCustomersList();
      if (data && data.status && data.Records.length > 0) {
        commit("SET_CUSTOMERS", data.Records);
      }
    } catch (error) {
      console.error(error);
    }
  },
  getCinemaDetails: ({ dispatch, commit, state }, payload) => {
    let body = {
      tableName: "ms_cinemas",
      cine_is_active: "Y",
      cine_id: payload,
    };
    MasterService.GetActiveList(body)
      .then((response) => {
        const { data } = response;
        if (data && data.status && data.Records.length > 0) {
          let mobile_code = data.Records[0].cine_mobile_code;
          commit("SET_CUST_MOB_CODE", mobile_code);
        } else {
          console.log("No cinema found");
        }
      })
      .catch((error) => {
        console.log("Catch Error:", error);
      });
  },
  setIsSplitUsingLoyaltyCashCard: async (
    { dispatch, commit, state },
    payload
  ) => commit("SET_IS_SPLIT_USING_LOYALTY_CASH_CARD", payload),
  onSetGroupExist: async ({ dispatch, commit, state }, { ...payload }) => {
    await commit("SET_GROUP_RATE_CARD", true);
  },
  onSetCompleteRateCard: async ({ dispatch, commit, state }, payload) => {
    console.log(payload, "fdsfdsfds");
    await commit("SET_COMPLETE_RATE_CARD", payload.status);
    await commit("SET_SEAT_GROUP_ALL", payload.seatGroup);
    // await commit("SET_TOTAL_AMOUNT_NEW", payload);
  },
  onSetCompleteNewTotalAmount: async ({ dispatch, commit, state }, payload) => {
    await commit("SET_TOTAL_BOX_OFFICE_AMOUNT", payload);
  },
  onCloseClearIDsforBoxOffice: async (
    { dispatch, commit, state },
    { ...payload }
  ) => {
    await commit("CLEAR_IDS_FOR_PRINT");
  },
  onResetDateStoreState: async (
    { dispatch, commit, state },
    { ...payload }
  ) => {
    await commit("ON_RESET_DATE_STORE_STATE");
  },
  SAVE_CURRENCY: (context, payload) => {
    context.commit("SET_CURRENCY", payload);
  },
  onChangeFlag: async ({ dispatch, commit, state }, payload) => {
    commit("SET_TEMPLATES_FLAG", payload);
  },
  onCancelSelectedSeatsHandler: async ({ dispatch, commit }) => {
    commit("ON_REMOVE_ALL_SELECTED_SEATS");
    dispatch("CLEAR_CARD_NUMBER", null);
  },
  GET_DATES: async ({ dispatch, commit }) => {
    await PosService.getAllDates()
      .then((response) => {
        if (response) {
          let { data } = response;
          if (data.status && data.Records.length > 0) {
            commit("SET_ALL_DATES", data);
            dispatch("SET_DATE", moment().format("DD-MM-YYYY"));
          } else {
            vue.toasted.show(data.message || "Please try later");
          }
        }
      })
      .catch((err) => {
        vue.toasted.show(err.message || "Please try later");
      });
  },
  GET_MOVIE_DETAILS: async ({ commit }, payload) => {
    // let loader = vue.$loading.show();
    // loader.hide();
    let { data } = await PosService.getMovieDetails(payload);
    if (data && data.status) {
      commit("SET_MOVIE_DETAILS", data);
    } else {
      vue.toasted.show(data.message || "Please try later");
    }
  },
  GET_ACTIVE_SEATS: async ({ commit }, payload) => {
    let loader = vue.$loading.show();
    let { data } = await PosService.getActiveScreens(payload);
    loader.hide();
    if (data && data.status) {
      data.Records = data.Records.filter((x) => {
        return x.screen_layout_active == "Y";
      });
      commit("SET_SCREENS", data);
    } else {
      vue.toasted.show(data.message || "Please try later");
    }
  },
  GET_SEATS_OCCUPANCY: async ({ commit }, payload) => {
    // console.log('payload :', payload);
    let loader = vue.$loading.show();
    let { data } = await PosService.getOccupancyStatus(payload);
    loader.hide();
    if (data && data.status) {
      commit("SET_SEAT_OCCUPANCY", data);
    } else {
      vue.toasted.show(data.message || "Please try later");
    }
  },
  SET_DATE: async (context, payload) => {
    let loader = vue.$loading.show();
    context.commit("SET_LOADER", true);
    try {
      let { data } = await PosService.getAllShows({
        selectedDate: payload,
        cinema_id: authenticationService.get("USINFO").cinema_id,
      });
      context.commit("SET_SELECTED_DATE", payload);
      context.commit("SET_ALL_SHOWS", data);
    } catch (error) {
      console.error(error);
    } finally {
      loader.hide();
      context.commit("SET_LOADER", false);
    }
    // context.commit('SET_TIME_ZONE', data.timeZone);

    context.commit("SET_FORMAT", "All Formats");
  },
  SAVE_SHOW_DETAILS: (context, payload) => {
    context.commit("SET_SHOW_DETAILS", payload);
  },
  GET_SEAT_LAYOUT: async (context, payload) => {
    let loader = vue.$loading.show();
    let data = {};
    let seats = await PosService.PosGetSeatLayout({
      screen_id: payload.screen_id,
      ss_id: payload.ss_id,
      md_id: payload.md_id,
    });

    let seatType = await PosService.getSeatType({
      screen_id: payload.screen_id,
      ss_id: payload.ss_id,
      md_id: payload.md_id,
    });

    data.seatType = seatType.data;
    // console.log('data :', seats);
    loader.hide();
    if (seats.data && seats.data.status) {
      data.seats = seats.data;

      context.commit("DEP_SET_SEAT_LAYOUT", data);
    } else {
      if (seats.data.hasOwnProperty("error")) {
        vue.toasted.show(seats.data.error || "Please try later");
      } else {
        vue.toasted.show(seats.data.message || "Please try later");
      }
    }
  },
  GET_FNB_PRICING: async ({ dispatch, commit, state }, payload) => {
    await store.dispatch("onResetFnBStoreState");
    await PosService.GetFnbItemPricing(payload)
      .then(async (response) => {
        const { data } = response;
        if (data.status && data.Records.length > 0) {
          let fnbObj = {
            ...data.Records[0],
            quantity: state.totalNumberOfSeats,
            item_is_compulsory: payload.item_is_compulsory || "N",
            is_package_ticket: "Y",
          };
          await store.dispatch("addFnbItemFromBoxOffice", fnbObj);
          await store.dispatch("fetchFnBAdditionalPrice", payload);
        } else {
          vue.toasted.show(data.message || "Please try later");
        }
      })
      .catch((err) => {
        vue.toasted.show(err || "Please try later");
      });
  },
  GET_Reserve_SEAT_LAYOUT: (context, payload) => {
    // context.commit("DEP_SET_SEAT_LAYOUT", payload);
    context.commit("SET_SEAT_LAYOUT", payload);
  },
  FILTER_FORMAT: (context, payload) => {
    if (payload != "All Formats") {
      context.commit("SET_FORMAT", payload);
    } else {
      context.commit("SET_FORMAT", "All Formats");
    }
    context.commit("SET_ALL_SHOWS", "filter");
  },
  SEAT_SELECTION: async ({ dispatch, commit, state }, payload) => {
    let value = authenticationService.get("BGORST");
    let seat_layout_id = [];
    //If RESERVE SEAT IMMEDIATELY FLAG IS TRUE IN GLOBAL OPTIONS
    if (value && value === "Y") {
      //Check if seat is reserved

      let singleSelectedSeats = state.seatsSelected.filter((x) => {
        return x.sl_id == payload.seat.sl_id;
      });
      if (singleSelectedSeats.length > 0) {
        let sl_id = singleSelectedSeats[0].sl_id || 0;
        let request_payload = {
          sl_id: sl_id,
          reservation_id:
            String(state.reservationId).length > 0
              ? state.reservationId
              : payload.reservation_id,
          schedule_show_id: payload.schedule_show_id,
        };
        PosService.releaseSeatIndividually(request_payload).then((response) => {
          const { data } = response;
          if (data && data.status) {
            vue.toasted.show(data.message || "!Something Went Wrong");
            commit("SELECTED_SEATS", payload);
          } else {
            vue.toasted.show(data.message || "!Something Went Wrong");
          }
        });
      } else {
        seat_layout_id.push(payload.seat.sl_id);
        let request_payload = {
          seat_layout_id: seat_layout_id,
          reservation_id:
            String(state.reservationId).length > 0
              ? state.reservationId
              : payload.reservation_id,
          schedule_show_id: payload.schedule_show_id,
        };
        PosService.PosReserveSeat(request_payload)
          .then((response) => {
            const { data } = response;
            if (data && data.status) {
              if (String(state.reservationId).length == 0) {
                commit("ON_RESERVE_SEATS", payload.reservation_id);
              }
              commit("SELECTED_SEATS", payload);
            } else if (data && !data.status)
              vue.toasted.show(data.message || "!Something Went Wrong");
          })
          .catch((error) => {
            vue.toasted.show(
              "Catch Error on insertBoxOfficeTransaction: ",
              error
            );
          });
      }
    } else {
      commit("SELECTED_SEATS", payload);
    }

    // let loader = vue.$loading.show();
    // PosService.PosReserveSeat({
    //     schedule_show_id: payload.scheduleShowId,
    //     seat_layout_id: payload.seat.sl_id,
    //     seat_reserve_type_id: payload.seat.seat_reserve_type_id,
    //     prev_seat_id: payload.seat.prev_seat_id
    // }).then((response) => {
    //     const {
    //         data
    //     } = response;
    //     loader.hide();
    //     if (data && data.status) {
    //         context.commit('SELECTED_SEATS', payload)
    //     } else if (data && !data.status) vue.toasted.show(data.message || '!Something Went Wrong');
    // }).catch((error) => {
    //     loader.hide();
    //     vue.toasted.show('Catch Error on insertBoxOfficeTransaction: ', error);
    // });
  },
  SEAT_SELECTION_TRUE: (context, payload) => {
    context.commit("SEAT_SELECTED_TRUE", payload);
  },
  SEAT_SELECTION_FALSE: (context, payload) => {
    context.commit("SEAT_SELECTED_FALSE", payload);
  },
  SelectNumberOfSeats: (context, payload) => {
    context.commit("NUMBER_OF_SEATS", payload);
  },
  ADD_SHOW_BOOKING: async (context, payload) => {
    let loader = vue.$loading.show();
    // console.log('payload :', payload);
    PosService.show_bookings(payload.show_booking)
      .then((response) => {
        const { data } = response;
        context.commit("SHOW_BOOKING_ID", data);
        return {
          showBookingId: data,
        };
      })
      .then(async (showBookingId) => {
        const { data } = await PosService.show_seat_bookings({
          schedule_show_id: payload.show_booking.schedule_show_id,
          showBookingId: showBookingId.showBookingId.Records,
          selectedSeats: payload.show_seat_booking,
        });
        if (data && data.status && data["ssb_id"] && data.ssb_id.length > 0) {
          context.dispatch("insertBoxOfficeTransaction", {
            ssb_id: data.ssb_id,
            showBookingId: showBookingId.showBookingId.Records,
            payload,
          });
        }
        return {
          seatData: data,
          showBookingId,
          ssb_id: data,
        };
      })
      .then(async ({ showBookingId, seatData, ssb_id }) => {
        const { data } = await PosService.updateBookingStatus({
          sb_id: showBookingId.showBookingId.Records,
          ssb_id: ssb_id.ssb_id,
        });
        return {
          seatData,
          showBookingId,
        };
      })
      .then(async ({ seatData, showBookingId }) => {
        const { data } = await PosService.getBookingDetails({
          sb_id: showBookingId.showBookingId.Records,
        });
        loader.hide();
        context.commit("SHOW_BOOKING_DETAILS", data);
      })
      .catch((error) => {
        loader.hide();
        console.log("error :", error);
      });
  },

  validateCustomerPoints: async (
    { dispatch, commit, state },
    { amount = null } = {}
  ) => {
    console.log("sdffdsfsdf");
    if (state.customerID) {
      console.log("iffff");

      let body = {
        customer_id: state.customerID,
        reservation_id:
          state.reservationId || state.reservationIdForValidatePoints,
        // cinema_id: state.showDetails.cine_id,
        fnbItems: store.getters.SelectedFnbItemList,
        isGroupRateCard: state.isGroupExist,
        totalTax:
          store.getters.selectedAddonsTotalPrice +
          store.getters.customizationTotalPrice,
        cashInput: amount ? amount : state.cashInput,
      };
      await PosService.ValidatePoints(body)
        .then((response) => {
          const { data } = response;
          console.log("data.status :", data.status);
          if (data.status) {
            commit("SET_LOYALTY_POINTS", data);
            vue.toasted.show(data.message);
            if (state.isSplitUsingLoyaltyCashCard) {
              dispatch("insertBoxOfficeTransaction", {
                reservationId: this.reservationId,
                isSplitPayment: true,
              });
              dispatch("setIsSplitUsingLoyaltyCashCard", false);
            }
          } else {
            commit("SET_LOYALTY_POINTS", data);
            vue.toasted.show(data.message);
          }
        })
        .catch((err) => {
          vue.toasted.show("Catch error in validating loyalty points");
        });
    }
  },
  VerifyCardDetails: async ({ dispatch, commit, state }, payload) => {
    if (
      payload.reservation_id != null &&
      payload.SelectedOffer &&
      payload.Card_Number != null
    ) {
      let Sendpayload = {
        reservation_id: payload.reservation_id,
        os_id: payload.SelectedOffer["os_id"],
        Card_Number: payload.Card_Number,
      };
      PosService.VerifyCardNumber(Sendpayload).then((response) => {
        const { data } = response;

        if (data && data.status) {
          commit("SET_VERIFIED_OFFER_STATUS", true);
          // store.dispatch("insertBoxOfficeTransaction", payload.reservation_id)
          // console.log('data VerifyCardNumber:>> ', data);
        } else {
          commit("SET_VERIFIED_OFFER_STATUS", false);
          // vue.toasted.show(data.message);
        }
      });
    } else {
      vue.toasted.show("Please provide proper card number");
    }
  },
  // Saif
  insertBoxOfficeTransaction: async ({ dispatch, commit, state }, payload) => {
    console.log("payload AABBCC:>> ", payload);
    let returnflag = true;
    if (state.usedCashCards && state.usedCashCards.length > 0) {
      let res = await dispatch("validateCashCardBalance");
      if (res.status == false) returnflag = false;
    }
    if (returnflag) {
      let seatNames = [];
      let body = {
        reservation_id: payload.reservationId ? payload.reservationId : payload,
        isSplitPayment:
          payload.isSplitPayment && payload.isSplitPayment == true
            ? true
            : false,
        unpaid_booking: state.isReservationDetailsAvailable ? true : false,
        cinema_id: state.showDetails.cine_id,
        approverId: state.ApproverId || null,
        payment_mode_id: state.selectedPaymentMode
          ? state.selectedPaymentMode.pm_id
          : 1,
        oldCustomer: state.isOldCustomer,
        customer_id: state.customerID,
        order_code: store.getters.getOrderId,
        payment_mode: state.selectedPaymentMode
          ? state.selectedPaymentMode
          : {},
        isLoyaltyApplied: state.isLoyaltyApplied,
        customerPointsToBeBurn: state.customerPointsToBeBurn,
        usedCashCards: state.isCashCardTransaction
          ? state.usedCashCards.map((obj) => {
              return { cash_card_id: obj.cash_card_id };
            })
          : [],
        ft_id: payload.ft_id ? payload.ft_id : null,
        offer: {
          t_offer_price: state.boxOfficeLoyaltyAmount,
          oc_discount_value: store.getters.getOfferPrice,
          is_on_net_amount: null,
          is_on_net_amount: null,
          is_offer_for: store.getters.getOfferFor,
          is_offer_applied:
            Object.keys(store.getters.getSelectedOffer).length > 0
              ? true
              : false,
          ...store.getters.getSelectedOffer,
        },
        isCashCardActive: state.isCashCardActive,
        CashCardDetails: state.isCashCardDetail,
        isGroupRateCard: state.isGroupExist,
      };
      if (payload.withFnb === true) {
        body["withFnb"] = true;
      }
      console.log("body :>> ", body);
      if (payload.isSplitPayment && payload.isSplitPayment == true) {
        body["splitPayments"] = state.paymentModes;
      }
      if (
        store.getters.selectedAddons &&
        store.getters.selectedAddons.length > 0
      ) {
        body["addOns"] = store.getters.selectedAddons;
      }
      if (store.getters.getOfferFor === "offersAndPromotions") {
        body.offer["is_on_net_amount"] =
          store.getters.getSelectedOffer["offer_applicable_on_net_amount"] ||
          "N";
        body.offer["is_on_gross_amount"] =
          store.getters.getSelectedOffer["offer_applicable_on_gross_amount"] ||
          "N";
      } else if (store.getters.getOfferFor === "vouchers") {
        body.offer["is_on_net_amount"] =
          store.getters.getSelectedOffer["voucher_redeem_on_net"] || "N";
        body.offer["is_on_gross_amount"] =
          store.getters.getSelectedOffer["voucher_redeem_on_gross"] || "N";
      } else if (state.isLoyaltyApplied && state.customerPointsToBeBurn > 0) {
        // body.offer["t_offer_price"] = state.loyaltyAmount;
        body.offer["t_offer_price"] = state.boxOfficeLoyaltyAmount;
        body.offer["oc_discount_value"] = state.boxOfficeLoyaltyAmount;
        body.offer["is_offer_applied"] = true;
        body.offer["offer_title"] = state.LoyaltyProgram;
        body.offer["is_offer_for"] = "offersAndPromotions";
        body.offer["is_on_net_amount"] = "N";
        body.offer["is_on_gross_amount"] = "Y";
      }
      let loader = vue.$loading.show();
      PosService.PosBookSeats(body)
        .then((response) => {
          const { data } = response;
          loader.hide();
          if (data && data.status) {
            commit("SET_BOOKING_COMPLETE");
            seatNames = state.seatsSelected.map((x) => {
              return x.sl_seat_name;
            });
            let customer_booking_payload = {
              cust_id: state.customerID,
              showBookingId: data.bookingID,
            };
            commit("SET_EARN_POINTS", data.earnPoints);
            // dispatch('updateCustomerBookings', customer_booking_payload)
            return {
              showBookingId: data.bookingID,
              ssb_id: data.ssb_id,
            };
          } else {
            if (data.shiftEnded) {
              vue.toasted.show(data.message);
              commit("SET_EMPTY_DATA");
              window.localStorage.clear();
              vue.cookie.delete("BRHSID");
              router.push({
                path: "pos-login",
              });
            } else {
              vue.toasted.show(data.message);
            }
          }
        })
        .then(async ({ showBookingId, ssb_id }) => {
          let ssb_ids = ssb_id.map((x) => {
            return x.ssb_id;
          });
          const { data } = await PosService.updateBookingStatus({
            sb_id: showBookingId,
            ssb_id: ssb_ids,
          });
          return {
            showBookingId,
          };
        })
        .then(async ({ showBookingId }) => {
          let loader = vue.$loading.show();
          try {
            const { data } = await PosService.PosBookingDetails({
              show_booking_id: showBookingId,
            });
            console.log("data SHOW_BOOKING_DETAILS:", data);
            commit("SET_BOOKING_ID", showBookingId);
            commit("SHOW_BOOKING_DETAILS", data);
            commit("SET_SBID_CINEMAID", data); //For send in api to get ejs for on Print button
            store.dispatch("onSetAndSendLog", {
              action: `Generate bill is clicked and ${seatNames} got booked with show booking id: ${showBookingId} `,
              page_name: "pos booking",
              api_name: "",
            });
          } catch (error) {
            console.error(error);
          } finally {
            loader.hide();
          }
        })
        .catch((error) => {
          loader.hide();
          console.log("error Catch err :", error);
        });
    } else {
      commit("SHOW_GENERATE_BUTTON");
      vue.toasted.show("Cash Card has Insufficient Balance, Pay Again!");
      commit("ON_RESET_CASH_CARD_DATA");
      dispatch("getCashCards", { cust_id: state.customerID });
    }
  },
  BOOKING_COMPLETE: async ({ dispatch, commit, state }, payload) => {
    await commit("SET_BOOKING_COMPLETE");
  },
  BurnPoints: async ({ dispatch, commit, state }, payload) => {
    MasterService.BurnPoints(payload)
      .then((response) => {
        const { data } = response;
        dispatch("EarnPoints", payload);
        if (data && data.status) {
        } else console.log(data.message || "Opps! Something went wrong");
      })
      .catch((error) => {
        vue.toasted.show("Catch Error on earnandburn: ", error);
      });
  },
  EarnPoints: async ({ dispatch, commit, state }, payload) => {
    MasterService.AddEarnPoints(payload)
      .then((response) => {
        const { data } = response;
        if (data && data.status) {
        } else vue.toasted.show(data.message || "Opps! Something went wrong");
      })
      .catch((error) => {
        vue.toasted.show("Catch Error on earnandburn: ", error);
      });
  },
  // Saif
  GET_ADDITIONAL_CHARGES: async (context, payload) => {
    const { data } = await PosService.getAdditionalCharges({
      selectedSeats: payload.selected_seats,
      showDetails: payload.showDetails,
      isGroupCardExist: state.isGroupExist,
      newSeatLayout: state.newSeatLayoutArrayState,
    });
    console.log("data additionalcharges :", data);
    context.commit("GET_ADITIONAL_CHARGES", data);
  },

  NEW_ADDITIONAL_CHARGES: async (context, payload) => {},

  FETCH_BOOKING_DETAILS: async (context, payload) => {
    // console.log('payload :', payload);
    let loader = vue.$loading.show();
    const { data } = await PosService.pickupTicket({
      booking_code: payload,
    });
    loader.hide();
    if (data.status) {
      let new_payload = {
        Additional_Charges: data.Records.Additional_Charges,
      };
      let booking_Details = {
        Records: data.Records.result,
      };
      context.commit("TICKET_DETAILS", data.Records.result[0]);
      context.commit("GET_ADITIONAL_CHARGES", new_payload);
      context.commit("SHOW_BOOKING_DETAILS", booking_Details);
      context.commit("SET_SBID_CINEMAID", booking_Details); //For send in api to get ejs for on Print button
    } else {
      vue.toasted.show(data.message);
    }
  },
  SET_CURRENT_TIME: (context, payload) => {
    context.commit("CURRENT_TIME");
  },
  GET_SHIFT_DETAILS: ({ commit }, payload) => {
    commit("SET_SHIFT_DETAILS", payload);
  },
  SET_CASH_INPUT: ({ commit, dispatch, state }, payload) => {
    commit("GET_CASH_INPUT", payload);
    if (!state.isLoyaltyApplied) {
      dispatch("validateCustomerPoints");
    }
  },
  SET_TOTAL_CASH_COLLECTED: (context, payload) => {
    context.commit("GET_TOTAL_CASH_COLLECTED", payload);
  },
  SET_TOTAL_CARD_AMOUNT: (context, payload) => {
    context.commit("GET_TOTAL_CARD_AMOUNT", payload);
  },
  SET_CURRENT_SCREEN: (context, payload) => {
    context.commit("CURRENT_SCREEN", payload);
  },
  MAKE_DATA_EMPTY: (context, payload) => {
    context.commit("SET_EMPTY_DATA");
  },
  MAKE_SEAT_BOOKING_DETAILS_EMPTY: async (context, payload) => {
    console.log("state.reservationId :", state.reservationId);
    if (String(state.reservationId).length > 0) {
      let request_payload = {
        reservation_id: state.reservationId,
      };
      await PosService.PosReleaseSeats(request_payload)
        .then((response) => {
          store.dispatch("onSetAndSendLog", {
            action: `Selected seats were released as user clicked back button.`,
            page_name: "pos booking",
            api_name: "",
          });
          const { data } = response;
          if (data.status) {
            vue.toasted.show(data.message);
          } else {
            vue.toasted.show(data.message);
          }
        })
        .catch((err) => {
          vue.toasted.show("Catch error in release seats", err);
        });
      await context.commit("SET_EMPTY_SEAT_BOOKING_DETAILS");
    }
  },
  SET_SCHEDULE_SHOW_ID: (context, payload) => {
    context.commit("GET_SCHEDULE_SHOW_ID", payload);
  },
  SetApproverId: (context, payload) => {
    context.commit("SET_APPROVER_ID", payload);
  },
  //For Offers
  SET_TOTAL_AMOUNT: (context, payload) => {
    context.commit("GET_TOTAL_AMOUNT", payload);
  },
  SET_OFFER: (context, payload) => {
    context.commit("GET_OFFER", payload);
  },
  seatHandler: async (context, payload) => {
    state.seatsSelected = payload;
    console.log(state.seatsSelected);
    // if (state.isMultiSelectAllowed) {
    //   console.log("fdsfdsfsdfsdfdsfsdf")
    //   await context.commit("SELECT_SEAT_HANDLDER", payload);
    // }
    // let newPayload = {
    //   seat: payload.seat,
    //   seatIndex: payload.seatIndex,
    //   rowIndex: payload.rowIndex,
    //   classIndex: payload.type
    // };
    await context.commit("SELECTED_SEATS", payload);
  },
  RESERVE_SEATS: async ({ dispatch, commit, state }, { ...payload }) => {
    // state.reservationIdForValidatePoints = payload.reservation_id;
    commit("SET_RESERVATION_ID_FOR_VALIDATE_POINTS", payload.reservationId);
    let seatNames = payload.seat_layout_id.map((x) => {
      return x.sl_seat_name;
    });
    let SeatTypeId = payload.seat_layout_id.map((x) => {
      return x.sst_id;
    });
    // let seatNames_SST_id = payload.seat_layout_id.map(x => {
    //   return x.sl_seat_name;
    // });
    // console.log('seatNames :', seatNames);
    let isVipOrBlockSeats =
      payload.seat_layout_id.filter((x) => {
        return x.seat_reserve_type_id == 5 || x.seat_reserve_type_id == 12;
      }).length > 0;
    console.log(" payload.seat_layout_id :>> ", isVipOrBlockSeats);
    let newSeatLayoutArray = [];
    let rateCardGroup = [];
    if (state.isGroupExist) {
      payload.seatGroup.map((seat_g) => {
        seat_g.rate_card_group.map((rg) => {
          let findIndex32 = rateCardGroup.findIndex((newrg) => {
            return newrg.tt_id == rg.tt_id;
          });

          if (findIndex32 >= 0) {
            rateCardGroup[findIndex32]["count"] =
              rateCardGroup[findIndex32]["count"] + rg.totalSeatSelected;
          } else {
            let objRateCard = {
              tt_id: rg.tt_id,
              tt_name: rg.tt_name.trim(),
              count: rg.totalSeatSelected,
            };
            rateCardGroup.push(objRateCard);
          }

          for (let i = 0; i < rg.totalSeatSelected; i++) {
            let obj = {
              rate_card_id: rg.rate_card_id,
              tt_id: rg.tt_id,
              rc_g_id: rg.rc_g_id,
              rcg_c_id: rg.rcg_c_id,
              tt_name: rg.tt_name,
              seat_type_id: seat_g.sst_id,
              status: false,
            };

            newSeatLayoutArray.push(obj);
          }
        });
      });

      await commit("SET_RESERVE_TYPE_GROUP", rateCardGroup);
      await commit("SET_NEW_SEAT_LAYOUT", newSeatLayoutArray);
      await dispatch("GET_ADDITIONAL_CHARGES", {
        selected_seats: payload.seat_layout_id,
        showDetails: payload.showDetails,
      });
    }

    let seats = payload.seat_layout_id.map((x) => {
      return x.sl_id;
    });

    if (seats.length > 0) {
      let loader = vue.$loading.show();
      await PosService.PosReserveSeat({
        seat_layout_id: seats,
        seat_type_id: SeatTypeId,
        newSeatLayout: newSeatLayoutArray,
        schedule_show_id: payload.schedule_show_id,
        reservation_id: payload.reservation_id,
        isVipOrBlockSeats: isVipOrBlockSeats,
        groupRateCard: state.isGroupExist,
      })
        .then((response) => {
          const { data } = response;
          loader.hide();
          if (data.status) {
            store.dispatch("onSetAndSendLog", {
              action: `Selected seats     ${seatNames}    were reserved.`,
              page_name: "pos booking",
              api_name: "",
            });
            commit("ON_RESERVE_SEATS", payload.reservation_id);
            dispatch("GET_PAYMENT_MODES", 1);
            // vue.toasted.show(data.message);
          } else {
            if (data.shiftEnded) {
              commit("SET_EMPTY_DATA");
              window.localStorage.clear();
              vue.cookie.delete("BRHSID");
              router.push({
                path: "pos-login",
              });
            } else {
              dispatch("GET_SEAT_LAYOUT", state.showDetails);
              commit("NUMBER_OF_SEATS", state.totalNumberOfSeats);
            }
            vue.toasted.show(data.message);
          }
        })
        .catch((err) => {
          loader.hide();
          vue.toasted.show("Catch error in reserve seat");
        });
      // await MasterService.ValidatePoints({
      //     seat_layout_id: seats,
      //     schedule_show_id: payload.schedule_show_id,
      //     reservation_id: payload.reservation_id,
      //     cinema_id: state.showDetails.cine_id,
      // }).then((response) => {
      //     const { data } = response;
      //     loader.hide();
      //     if (data.status) {

      //     } else {

      //     }
      // }).catch((err) => {
      //     loader.hide();
      //     vue.toasted.show('Catch error in reserve seat');
      // });
    } else {
      vue.toasted.show("Please select at least one seat");
    }
  },
  GET_PAYMENT_MODES: async (context, payload) => {
    console.log("payload:::::::::: :>> ", payload);
    // let request_payload = {
    //     tableName: "ms_payment_modes",
    //     pm_is_active: "Y"
    // };
    await PosService.GetValidPaymentModes(payload)
      .then((response) => {
        const { data } = response;
        if (data && data.status && data.Records.length > 0) {
          context.commit("SET_PAYMENT_MODES", data);
        } else {
          vue.toasted.show(data.message);
        }
      })
      .catch((error) => {
        vue.toasted.show(error);
      });
  },
  setPaymentMode: (context, payload) => {
    if (
      (payload && payload.pm_id && payload.pm_id == 12) ||
      payload.pm_id == 13
    ) {
      vmVue.$root.$emit("bv::show::modal", "pos-applicable-offers");
    }
    context.commit("SET_SELECTED_PAYMENT_MODE", payload);
  },
  BOOK_SEATS: async (context, payload) => {
    console.log("ReservationId :", payload);
    let loader = vue.$loading.show();
    await PosService.PosBookSeats({
      reservation_id: payload,
      payment_mode_id: state.selectedPaymentMode
        ? state.selectedPaymentMode.pm_id
        : 1,
    })
      .then((response) => {
        const { data } = response;
        console.log("data :", data);
        if (data.status) {
          context.commit("SHOW_BOOKING_ID", { Records: data.bookingID });
          if (data && data.status && data["ssb_id"] && data.ssb_id.length > 0) {
            context.dispatch("insertBoxOfficeTransaction", {
              ssb_id: data.ssb_id,
              showBookingId: data.bookingID,
              payload,
            });
          }
          return {
            showBookingId: data.bookingID,
            ssb_id: data.ssb_id,
          };
        } else {
          vue.toasted.show(data.message);
        }
      })
      .then(async ({ showBookingId, ssb_id }) => {
        let ssb_ids = ssb_id.map((x) => {
          return x.ssb_id;
        });
        const { data } = await PosService.updateBookingStatus({
          sb_id: showBookingId,
          ssb_id: ssb_ids,
        });
        return {
          showBookingId,
        };
      })
      .then(async ({ showBookingId }) => {
        let loader = vue.$loading.show();
        try {
          const { data } = await PosService.PosBookingDetails({
            show_booking_id: showBookingId,
          });
          context.commit("SET_BOOKING_ID", showBookingId);
          context.commit("SHOW_BOOKING_DETAILS", data);

          store.dispatch("onSetAndSendLog", {
            action: `Generate bill is clicked and ${this.seatNames} got booked with show booking id: ${showBookingId} `,
            page_name: "pos booking",
            api_name: "",
          });
        } catch (error) {
          console.error(error);
        } finally {
          loader.hide();
        }
      })
      .catch((err) => {
        vue.toasted.show("Catch error in reserve seat");
      });
    loader.hide();
  },
  GET_HARDWARE_NAME: (context, payload) => {
    PosService.GetHardwareName({ user_id: payload })
      .then((response) => {
        console.log("response :", response);
        const { data } = response;

        if (data && data.status) {
          context.commit("SET_HARDWARE_NAME", data.Records[0]);
        }
      })
      .catch((err) => {
        vue.toasted.show(err);
      });
  },
  setUserDeatils: ({ dispatch, commit, state }, payload) => {
    let request_payload = {
      ...payload,
    };
    PosService.UpdateCustomerBookingDetails(request_payload)
      .then(async (response) => {
        const { data } = response;
        if (data && data.status) {
          vmVue.$root.$emit("bv::hide::modal", "loyaltyMoadal");
          vmVue.$root.$emit("bv::hide::modal", "fnb-customer-details");
          commit("ON_RESET_LOYALTY_DATA");
          commit("ON_RESET_CASH_CARD_DATA");
          await commit("SET_CUSTOMER_DETAILS", data);
          await store.dispatch("validateCustomerPoints");
          await store.dispatch("getCashCards", data.Records[0]);
        } else {
          commit("SET_CUSTOMER_DETAILS", []);
          if (state.isSplitUsingLoyaltyCashCard) {
            vmVue.$root.$emit("bv::hide::modal", "loyaltyMoadal");
            vue.toasted.show(`Customer not found`);
          }
        }
      })
      .catch((err) => {
        vue.toasted.show(err);
      });
  },
  resetCustomerDetails: ({ commit }) => {
    commit("RESET_CUSTOMER_DETAILS");
  },
  getCashCards: ({ dispatch, commit, state }, payload) => {
    PosService.GetCashCards({ customer_id: payload.cust_id })
      .then((response) => {
        const { data } = response;
        if (data && data.status && data.Records.length > 0) {
          commit("SET_CASH_CARDS", data.Records);
        } else {
          //   vue.toasted.show(data.message)
        }
      })
      .catch((error) => {
        vue.toasted.show(error);
      });
  },
  resetCashCardData({ commit }) {
    commit("ON_RESET_CASH_CARD_DATA");
  },
  selectCashCard: ({ dispatch, commit, state }, payload) => {
    commit("SELECT_CASH_CARD", payload);
  },

  payCashCard({ dispatch, commit, state }) {
    commit("SET_PAY_BUTTON", true);
    PosService.CheckBalance(state.selectedCashCard.cash_card_id)
      .then(async (response) => {
        const { data } = response;
        commit("SET_PAY_BUTTON", false);
        if (data && data.status && data.Records.length > 0) {
          let balance = data.Records[0].remaining_balance;
          if (balance != state.selectedCashCard.remaining_balance) {
            commit("SET_BALANCE_AMOUNT", balance);
            vue.toasted.show("Balance Updated! Try Again");
          } else {
            if (
              parseFloat(state.selectedCashCard.remaining_balance) >=
              parseFloat(state.totalAmount) -
                parseFloat(store.getters.getOfferPrice) -
                parseFloat(state.loyaltyAmount) -
                parseFloat(state.paid_amount) +
                parseFloat(store.getters.selectedAddonsTotalPrice)
            ) {
              //   this.balance_after_deduction = this.selectedCashCard.remaining_balance - this.totalAmount
              commit("SET_BALANCE_AFTER_DEDUCTION");
              vue.toasted.show(
                "Payment Successful! Please proceed to generate bill"
              );
            } else {
              commit("SET_PAID_AMOUNT");
              vue.toasted.show(
                "Amount Deducted! Please Pay the remaining amount using other payment methods"
              );
            }
          }
        }
      })
      .catch((err) => {
        vue.toasted.show(err);
      });
  },
  payCashCardFnbBox({ dispatch, commit, state }) {
    commit("SET_PAY_BUTTON", true);
    PosService.CheckBalance(state.selectedCashCard.cash_card_id)
      .then(async (response) => {
        const { data } = response;
        commit("SET_PAY_BUTTON", false);
        if (data && data.status && data.Records.length > 0) {
          let balance = data.Records[0].remaining_balance;
          if (balance != state.selectedCashCard.remaining_balance) {
            commit("SET_BALANCE_AMOUNT", balance);
            vue.toasted.show("Balance Updated! Try Again");
          } else {
            if (
              parseFloat(state.selectedCashCard.remaining_balance) >=
              parseFloat(state.totalAmount) -
                parseFloat(store.getters.getOfferPrice) -
                parseFloat(state.loyaltyAmount) -
                parseFloat(state.paid_amount) +
                parseFloat(
                  store.getters.selectedFnBItemsTotalPrice +
                    store.getters.TotalAdditionalTaxes
                ) +
                parseFloat(
                  store.getters.selectedAddonsTotalPrice +
                    store.getters.customizationTotalPrice +
                    store.getters.customizationTaxesPrice
                )
            ) {
              //   this.balance_after_deduction = this.selectedCashCard.remaining_balance - this.totalAmount
              commit("SET_BALANCE_AFTER_DEDUCTION_FNB");
              vue.toasted.show(
                "Payment Successful! Please proceed to generate bill"
              );
            } else {
              commit("SET_PAID_AMOUNT");
              vue.toasted.show(
                "Amount Deducted! Please Pay the remaining amount using other payment methods"
              );
            }
          }
        }
      })
      .catch((err) => {
        vue.toasted.show(err);
      });
  },
  validateCashCardBalance({ commit }) {
    return new Promise((resolve, reject) => {
      PosService.ValidateBalance({ usedCashCards: state.usedCashCards })
        .then(async (response) => {
          const { data } = response;
          if (data && data.status) {
            return resolve({ status: true });
          } else {
            return resolve({ status: false });
          }
        })
        .catch((err) => {
          vue.toasted.show(err);
        });
    });
  },
  updateOnlyCustomerDetails: ({ dispatch, commit, state }, payload) => {
    let request_payload = {
      ...payload,
    };
    PosService.InsertOnlyCustomerDetails(request_payload)
      .then(async (response) => {
        const { data } = response;
        if (data && data.status) {
          vmVue.$root.$emit("bv::hide::modal", "loyaltyMoadal");
          vmVue.$root.$emit("bv::hide::modal", "fnb-customer-details");
          console.log("data :", data);
          commit("ON_RESET_LOYALTY_DATA");
          await commit("SET_NEW_CUSTOMER_DETAILS", data);
          await store.dispatch("validateCustomerPoints");

          vue.toasted.show(data.message);
        } else {
          vue.toasted.show(data.message);
        }
      })
      .catch((err) => {
        vue.toasted.show(err);
      });
  },
  updateCustomerData: ({ dispatch, commit, state }, payload) => {
    let request_payload = {
      ...payload,
    };
    PosService.updateCustomerData(request_payload)
      .then(async (response) => {
        const { data } = response;
        if (data && data.status) {
          commit("ON_RESET_LOYALTY_DATA");
          vmVue.$root.$emit("bv::hide::modal", "loyaltyMoadal");
        } else {
          vue.toasted.show(data.message);
        }
      })
      .catch((err) => {
        vue.toasted.show(err);
      });
  },
  updateCustomerBookings: ({ dispatch, commit, state }, payload) => {
    let request_payload = {
      ...payload,
      sb_id: payload.showBookingId || state.booking_id,
    };
    PosService.InsertCustomerBookingDetails(request_payload)
      .then((response) => {
        const { data } = response;
        if (data && data.status) {
          vue.toasted.show(data.message);
        } else {
          // vue.toasted.show(data.message);
        }
      })
      .catch((err) => {
        vue.toasted.show(err);
      });
  },
  sendSms: ({ dispatch, commit, state }, payload) => {
    let request_payload = {
      mobile_number: state.customer_mobile_number,
      country_code: state.cust_mob_code,
      // country_code: state.selectedCountryCode || "91",
      sms_text:
        "Ticket Booked Successfully with Ticket Number " +
        state.bookingDetails.sb_booking_code,
    };
    PosService.SendSMS(request_payload)
      .then((response) => {
        const { data } = response;
        if (data && data.status) {
          commit("CLEAR_ALL_INPUTS", payload);
          vue.toasted.show(data.message);
        } else {
          vue.toasted.show(data.message);
        }
      })
      .catch((err) => {
        vue.toasted.show(err);
      });
  },
  sliceNumber: ({ dispatch, commit, state }, payload) => {
    commit("SLICE_NUMBER", payload);
  },
  setTemplateDetails: ({ dispatch, commit, state }, payload) => {
    commit("SET_TEMPLATE_DETAILS", payload);
  },
  clearAllInput: ({ dispatch, commit, state }, payload) => {
    commit("CLEAR_ALL_INPUTS", payload);
    commit("RESET_CUSTOMER_DETAILS");
    commit("ON_RESET_LOYALTY_DATA");
  },
  resetPickUpTicketDetails: ({ dispatch, commit, state }, payload) => {
    commit("RESET_PICKUP_TICKET_DETAILS");
  },
  updateCustomerMobileNumber: ({ dispatch, commit, state }, payload) => {
    commit("SET_MOBILE_NUMBER", payload);
  },
  updateCountryMobCode: ({ dispatch, commit, state }, payload) => {
    commit("SET_CUST_MOB_CODE", payload);
  },
  updateCustomerDetails: ({ dispatch, commit, state }, payload) => {
    commit("UPDATE_CUSTOMER_DETAILS", payload);
  },
  onApplyLoyalty: ({ dispatch, commit, state }, payload) => {
    store.dispatch("onResetOffersAndPrice");
    commit("ON_APPLY_LOYALTY");
  },
  resetLoyaltyData: ({ dispatch, commit, state }, payload) => {
    commit("ON_RESET_LOYALTY_DATA");
  },
  onEditPhoneNumber: ({ dispatch, commit, state }, payload) => {
    commit("EDIT_NUMBER");
  },
  onChangeMultiSelectSwitch: async ({ dispatch, commit, state }, payload) => {
    if (
      String(state.reservationId).length > 0 &&
      state.seatsSelected.length > 0
    ) {
      await dispatch("MAKE_SEAT_BOOKING_DETAILS_EMPTY");
      await store.dispatch("onResetFnBStoreState");
    }
    await commit("CHANGE_SEAT_SELECTER", payload);
  },
  onChangeAddCardDetail: async ({ dispatch, commit, state }, payload) => {
    console.log(payload, "CashCard");
    await commit("CHANGE_CASH_CARD", payload);
  },
  _onChangeAddCardDetail: async ({ dispatch, commit, state }, payload) => {
    console.log(payload, "CashCard");
    const cashCardPayload = {
      cash_card_is_active: "Y",
      cash_card_number: payload.newCashCardValue,
      credited_by: 1,
      customer_id: payload.customer.cust_id,
      initial_amount: payload.cardAmount,
    };
    const { data } = await PosService.AddCashCard(cashCardPayload);
    if (data && data.status) {
      await commit("CHANGE_CASH_CARD", payload);
      vue.toasted.show(`Cash card added successfully!`);
    }
  },
  async getCashCardTransactionLogs({ dispatch, commit, state }, payload) {
    try {
      const { cash_card_id, perPage, currentPage } = payload;
      const { data } = await PosService.GetCashCardLogs({
        cash_card_id,
        perPage,
        currentPage,
      });
      if (data && data.status && data.Records.data.length > 0) {
        commit("SET_CASH_CARD_LOGS", data.Records.data);
      }
    } catch (error) {
      console.error(error);
    }
  },
  Remove_Reservation_details: async ({ dispatch, commit, state }, payload) => {
    commit("REMOVE_RESERVATION_DETAILS");
  },
  Submit_Reservation_Details: async ({ dispatch, commit, state }, payload) => {
    if (payload && String(payload).length > 0) {
      PosService.GetReservationDetails({
        reservationId: payload,
        isGroupRateCard: true,
      })
        .then(async (response) => {
          console.log("dsfsdfsdfsdfsdfsdfds", response);
          state.isGroupExist = true;
          state.isCompleteRateCard = true;
          const { data } = response;
          if (data.status && data.Records.length > 0) {
            await commit("ON_RESERVE_SEATS", payload);
            await commit("GOT_RESERVATION_DETAILS");
            await commit("SET_SHOW_DETAILS", data.Records[0]);
            await commit("SET_RESERVATION_SEATS", data.Records);
            let request_payload = {
              showDetails: data.Records[0],
              selected_seats: data.Records,
            };
            await dispatch("GET_ADDITIONAL_CHARGES", request_payload);
            await dispatch("GET_PAYMENT_MODES", 1);
          } else {
            vue.toasted.show(data.message);
          }
        })
        .catch((err) => {
          vue.toasted.show("Something went wrong " + err);
        });
    }
  },
  onChangePaymentValue: async ({ dispatch, commit, state }, payload) => {
    await commit("SET_PAYMENT_VALUE", payload);
  },
};

const getters = {
  getShowCashCardLogsModal: (state) => state.showCashCardLogsModal,
  getCashCardLogs: (state) => state.cashCardLogs,
  getCustomers: (state) => state.customers,
  DATES: (state) => state.dates,
  SELECTED_DATE: (state) => state.selectedDate,
  SHOW_TIMES: (state) => state.showTimes,
  SHOW_DATES: (state) => state.visibleShowsDates,
  SHOW_SCREENS: (state) => state.visibleShowsScreens,
  SHOW_LANGUAGE: (state) => state.visibleShowsLanguage,
  SEAT_LAYOUT_DATA: (state) => state.seatLayoutData,
  SELECTED_SEATS: (state) => state.seatsSelected,
  SCHEDULE_SHOW_ID: (state) => state.scheduleShowId,
  TOTAL_AMOUNT: (state) => state.totalAmount,
  SHOW_USER_INFO: (state) => state.userINFO,

  GET_CURRENCY: (state) => state.currency,
  SHOW_DETAILS: (state) => state.showDetails,
  SHOW_BOOKING_ID: (state) => state.showBookingId,
  BOOKING_DETAILS: (state) => state.bookingDetails,
  TICKET_DETAILS: (state) => state.ticketakDetails,
  enterTicketDetails: (state) => state.enterTicketDetails,
  showTicketInfo: (state) => state.showTicketInfo,
  CURRENT_DATE: (state) => state.currentDate,
  CURRENT_TIME: (state) => state.currentTime,
  ADDITIONAL_CHARGES: (state) => state.additionalCharges,
  MOVIE_DETAILS: (state) => state.movieDetails,
  SEATS_OCCUPANCY: (state) => state.seatOccupancy,
  ACTIVE_SCREENS: (state) => state.screens,
  CURRENT_SCREEN: (state) => state.currentScreen,
  NET_AMOUNT: (state) => state.netAmount,
  CASH_INPUT: (state) => state.cashInput,
  TOTAL_CASH_COLLECTED: (state) => state.totalCashCollected,
  TOTAL_CARD_AMOUNT: (state) => state.totalCardAmount,
  SHIFT_DETAILS: (state) => state.shiftDetails,
  OFFER_DETAILS: (state) => state.offerApplied,
  //
  totalNumberOfSeats: (state) => state.totalNumberOfSeats,
  getScreenPosNames: (state) => state.showDetails.screen_name,
  reservationId: (state) => state.reservationId,
  isVipSeatSelected: (state) => state.isVipSeatSelected,
  paymentModes: (state) => state.paymentModes,
  selectedPaymentMode: (state) => state.selectedPaymentMode,
  gethardwarename: (state) => state.HardwareName,
  getCustomerDetails: (state) => state.customerDetails,
  customer_mobile_number: (state) => state.customer_mobile_number,
  first_name: (state) => state.first_name,
  last_name: (state) => state.last_name,
  email_address: (state) => state.email_address,
  getIsOldCustomer: (state) => state.isOldCustomer,
  isSearch: (state) => state.isSearch,
  isEdit: (state) => state.isEdit,
  templateDetails: (state) => state.templateDetails,
  getAvailablePoints: (state) => state.customerAvailablePoints,
  getEarnPoints: (state) => state.earnPoints,
  getBurnPoints: (state) => state.customerPointsToBeBurn,
  getloyaltyAmount: (state) => state.loyaltyAmount,
  isLoyaltyApplied: (state) => state.isLoyaltyApplied,
  LoyaltyProgram: (state) => state.LoyaltyProgram,
  isMultiSelectAllowed: (state) => state.isMultiSelectAllowed,
  cust_id: (state) => state.customerID,
  isOfferValidStatus: (state) => state.isOfferValid,
  IDforBoxOfficeEjs: (state) => state.BoxOfficeEjsData,
  cashCards: (state) => state.cashCards,
  selectedCashCard: (state) => state.selectedCashCard,
  // balance_after_deduction: state => state.balance_after_deduction,
  paid_amount: (state) => state.paid_amount,
  paidflag: (state) => state.paidflag,
  isCashCardTransaction: (state) => state.isCashCardTransaction,
  usedCashCards: (state) => state.usedCashCards,
  //
  isReservationDetailsAvailable: (state) => state.isReservationDetailsAvailable,
  booking_complete: (state) => state.booking_complete,
  showGenBtn: (state) => state.showGenBtn,
  disablePayNow: (state) => state.disablePayNow,
  isDataLoading: (state) => state.isDataLoading,
  isCashCardActive: (state) => state.isCashCardActive,
  isCashCardDetail: (state) => state.isCashCardDetail,
  isCashCardDetail: (state) => state.isCashCardDetail,
  isCompleteRateCard: (state) => state.isCompleteRateCard,
  isGroupExist: (state) => state.isGroupExist,
  seatGroupAll: (state) => state.seatGroupAll,
  seatGroupByType: (state) => state.seatGroupByType,
  newSeatLayoutArrayState: (state) => state.newSeatLayoutArrayState,
  getIsSplitUsingLoyaltyCashCard: (state) => state.isSplitUsingLoyaltyCashCard,
  getBoxOfficeLoyaltyAmount: (state) => state.boxOfficeLoyaltyAmount,
  getFnbLoyaltyAmount: (state) => state.fnbLoyaltyAmount,
  getCustMobCode: (state) => state.cust_mob_code,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
