import moment from "moment";
import Vue from 'vue';
import ReportsService from '@/services/ReportsService';
import MasterService from '@/services/MasterService';
import { router } from '@/router';
import _ from "lodash";
import store from '@/store';
import { authenticationService } from "@/services/AuthenticationService";

function initialState() {
    return {
        distributorReportExcelFileName: null,
        distributorReportPDFFileName: null,
        distributorReportDropdownList: [],
        distributorReportsList: [],
        distributorReportsChargeList: [],
        distributorMoviesList: [],
        flag: {
            isCinema: false,
        },
        distributorObject: {
            fromDate: new Date(),
            toDate: new Date(),
            cinema_id: null,
            cinema_name: null,
            d_id: null,
            d_company_name: null,
            movie_id: null,
            movie_title: null,
            country_id: authenticationService.get("BRGLOPCO"),
        },
        paging: {},
        item: {}
    };
};
const state = initialState();

const mutations = {
    ON_RESET_DISTRIBUTOR_REPORT_STORE_STATE: (state, payload) => {
        // state = initialState();
        const s = initialState()
        Object.keys(s).forEach(key => {
            state[key] = s[key]
        })
    },
    SET_DISTRIBUTOR_EXCEL_FILE_NAME: (state, payload) => {
        state.distributorReportExcelFileName = payload;
    },
    SET_DISTRIBUTOR_PDF_FILE_NAME: (state, payload) => {
        state.distributorReportPDFFileName = payload;
    },
    SET_DISTRIBUTOR_LIST: (state, payload) => {
        state.distributorReportDropdownList = payload
    },
    SET_DISTRIBUTOR_REPORT_CHARGESLIST: (state, payload) => {
        state.distributorReportsChargeList = _.orderBy(payload, 'charge_type_id');
    },
    SET_DISTRIBUTOR_REPORT_LIST: (state, payload) => {
        state.distributorReportsList = payload;
    },
    SET_DISTRIBUTOR_MOVIES_LIST: (state, payload) => {
        state.distributorMoviesList = payload;
    },
    SET_DISTRIBUTOR_REPORT_DATES: (state, payload) => {
        payload['name'] ? state.distributorObject[payload.name] = payload['event'] ? payload.event : null : '';
    },
    SET_DISTRIBUTOR_REPORT_DROPDOWN: (state, payload) => {
        if (state.distributorReportsList.length > 0) {
            state.distributorReportsList = [];
        };
        switch (payload.name) {
            case 'isCinema':
                state.distributorObject.cinema_id = payload['selectedItem'] ? payload.selectedItem.cine_id : null;
                state.distributorObject.cinema_name = payload['selectedItem'] ? payload.selectedItem.cine_name : null;
                break;
            case 'isDistributor':
                state.distributorObject.d_id = payload['selectedItem'] ? payload.selectedItem.d_id : null;
                state.distributorObject.d_company_name = payload['selectedItem'] ? payload.selectedItem.d_company_name : null;

                break;
            case 'isMovie':
                state.distributorObject.movie_id = payload['selectedItem'] ? payload.selectedItem.movie_id : null;
                state.distributorObject.movie_title = payload['selectedItem'] ? payload.selectedItem.movie_title : null;
                break;
            default:
                console.log('FilmCollection :Sorry, we are enable to set value.');
                break;
        }
    },
    SET_DISTRIBUTOR_REPORT_FLAG: (state, payload) => {
        console.log('payload.name :', payload.name);
        switch (payload.name) {
            case 'isCinema':
                state.flag.isCinema = payload['value'] ? payload.value === 0 ? false : true : false;
                state.distributorObject.cinema_id = payload['value'] && payload.value === 0 ? null : payload.value;
                break;
            case 'isCountry':
                state.distributorObject.country_id = payload['value'] && payload.value === 0 ? null : payload.value;
                break;
            case 'isExcelDownload':
                state.distributorReportExcelFileName && state.distributorReportExcelFileName.length > 0 ? store.dispatch("globalRemoveFile", { fileName: state.distributorReportExcelFileName }) : '';
                state.distributorReportExcelFileName = payload.value;
                state.distributorReportPDFFileName && state.distributorReportPDFFileName.length > 0 ? store.dispatch("globalRemoveFile", { fileName: state.distributorReportPDFFileName }) : '';
                state.distributorReportPDFFileName = payload.value;

                break;
            default:
                console.log('Sorry, we are enable to set Flag.');
                break;

        }
    }
};

const actions = {
    onResetDistributorStoreState: async ({ dispatch, commit, state }, { ...payload }) => {
        await commit('ON_RESET_DISTRIBUTOR_REPORT_STORE_STATE');
    },
    fetchDistributorMovies({ dispatch, commit, state }, payload) {
        if (state.distributorObject.cinema_id && state.distributorObject.d_id) {
            commit('SET_DISTRIBUTOR_REPORT_DROPDOWN', { event: null, name: 'isMovie', selectedItem: null, index: null })
            commit('SET_DISTRIBUTOR_MOVIES_LIST', []);
            ReportsService.GetDistributorMovie(state.distributorObject)
                .then((response) => {
                    const { data } = response;
                    if (data && data.status && data.Records) {
                        commit('SET_DISTRIBUTOR_MOVIES_LIST', data.Records || []);
                    } else Vue.toasted.show(data.message || 'Opps! Something went wrong');
                }).catch((error) => {
                    Vue.toasted.show("Catch Error on ReportStore fetchDistributorMovies", error);
                });
        };
    },
    fetchDistributorReportList({ dispatch, commit, state }, payload) {
        state.distributorReportExcelFileName ? store.dispatch("globalRemoveFile", { fileName: state.distributorReportExcelFileName }) : '';
        state.distributorReportPDFFileName ? store.dispatch("globalRemoveFile", { fileName: state.distributorReportPDFFileName }) : '';

        commit('SET_DISTRIBUTOR_REPORT_LIST', []);
        commit('SET_DISTRIBUTOR_REPORT_CHARGESLIST', []);
        let fromDate = moment(new Date(state.distributorObject.fromDate)).format("YYYY-MM-DD");
        let toDate = moment(new Date(state.distributorObject.toDate)).format("YYYY-MM-DD");

        if (new Date(fromDate) >= new Date() && new Date(toDate) >= new Date()) {
            Vue.toasted.show("From date and To date should not be greater than the current date.");
        } else if (new Date(fromDate) >= new Date()) {
            Vue.toasted.show("From date should not be greater than the current date.");
        } else if (new Date(toDate) >= new Date()) {
            Vue.toasted.show("To date should not be greater than the current date.");
        } else if (new Date(fromDate) > new Date(toDate)) {
            Vue.toasted.show("From date should not be greater than To date.");
        } else if (!state.distributorObject.cinema_id) {
            Vue.toasted.show("Please Select any Cinema.");
        } else if (!state.distributorObject.d_id) {
            Vue.toasted.show("Please Select any Distributor.");
        } else if (!state.distributorObject.movie_id) {
            Vue.toasted.show("Please Select any Movie.");
        } else {
            let loader = Vue.$loading.show();
            ReportsService.GetDistributorReportList(state.distributorObject)
                // ReportsService.GetFilmWiseCollectionReport(state.distributorObject)
                .then(async (response) => {
                    const { data } = response;
                    if (data && data.status && data.Records) {
                        commit('SET_DISTRIBUTOR_REPORT_LIST', data.Records || []);
                        data['FinalTotalCharges'] && data.FinalTotalCharges ? commit('SET_DISTRIBUTOR_REPORT_CHARGESLIST', data['FinalTotalCharges'] || []) : ''
                        await commit('SET_DISTRIBUTOR_EXCEL_FILE_NAME', data['ExcelName'] || null);
                        await commit('SET_DISTRIBUTOR_PDF_FILE_NAME', data['PDFName'] || null);

                    } else Vue.toasted.show(data.message || 'Opps! Something went wrong');
                }).catch((error) => {
                    Vue.toasted.show("Catch Error on ReportStore fetchDistributorReportList", error);
                }).finally(() => {
                    setTimeout(() => loader.hide(), 1000);
                });
        }
    },
    fetchReportDistributorsList({ dispatch, commit, state }) {
        commit('SET_DISTRIBUTOR_LIST', []);
        let body = {
            tableName: "ms_distributors",
            d_is_active: "Y",
        };
        MasterService.GetActiveList(body)
            .then(response => {
                const { data } = response;
                if (data && data.status && data.Records.length > 0) commit('SET_DISTRIBUTOR_LIST', data.Records || []);
                else Vue.toasted.show(data.message || 'Opps! Something went wrong');
            })
            .catch(error => Vue.toasted.show("Catch Error on MasterStore fetchDistributorsList", error));
    },
    // On Handlers
    onDistributorReportDateHandler: async ({ dispatch, commit, state }, payload) => {
        commit('SET_DISTRIBUTOR_REPORT_DATES', payload)
        await commit('SET_DISTRIBUTOR_REPORT_FLAG', { name: 'isExcelDownload', value: null });

    },
    onDistributorReportDropdownHandler: async ({ dispatch, commit, state }, payload) => {
        await commit('SET_DISTRIBUTOR_REPORT_DROPDOWN', payload)
        await commit('SET_DISTRIBUTOR_REPORT_FLAG', { name: 'isExcelDownload', value: null });
        if (payload['name'] && payload.name === 'isDistributor' || payload['name'] && payload.name === 'isCinema' && state.distributorObject.cinema_id && state.distributorObject.d_id) {
            await dispatch('fetchDistributorMovies');
        };
    },
    onDistributorReportFlagHandler: async ({ dispatch, commit, state }, payload) => {
        commit('SET_DISTRIBUTOR_REPORT_FLAG', payload)
    }
}

const getters = {
    getDistributorReportFromDate: state => state.distributorObject.fromDate,
    getDistributorReportToDate: state => state.distributorObject.toDate,
    getDistributorReportSelectedCinema: state => state.distributorObject.cinema_name,
    getDistributorReportSelectedDistributor: state => state.distributorObject.d_company_name,
    getDistributorReportSelectedMovie: state => state.distributorObject.movie_title,
    getDistributorReportsIsCinema: state => state.flag.isCinema,
    getDistributorReportList: state => state.distributorReportsList,
    getDistributorReportDropdownList: state => state.distributorReportDropdownList,

    getDistributorReportMoviesList: state => state.distributorMoviesList,
    getDistributorReportChargeList: state => state.distributorReportsChargeList,
    // Get File name
    getDistributorExcelFileName: state => state.distributorReportExcelFileName,
    // PDF
    getDistributorPDFFileName: state => state.distributorReportPDFFileName,
};

export default {
    state,
    mutations,
    actions,
    getters
}
