import Vue from 'vue'
import moment from 'moment';

Vue.filter('time', function (value, is24HrFormat = false) {
  if (value) {
    const date = new Date(Date.parse(value));
    let hours = date.getHours();
    const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
    if (!is24HrFormat) {
      const time = hours > 12 ? 'AM' : 'PM';
      hours = hours % 12 || 12;
      return hours + ':' + min + ' ' + time
    }
    return hours + ':' + min
  }
})


////////////////////////
Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
});

Vue.filter('uppercase', function (value) {
  if (!value) return value || ''
  return value.toUpperCase();
});

Vue.filter('activeParse', function (value) {
  if (!value) return value || ''
  return value === "Y" ? 'Yes' : value === "N" ? 'No' : '';
});


Vue.filter('dateparse', function (value) {
  if (!value) return '-'
  else if (value === 'Invalid date') return '-'
  else if (moment(value, 'DD-MM-YYYY HH:mm', true).isValid()) return value ? moment(value, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY') : '-';
  else return value ? moment(value).format('DD-MM-YYYY') === 'Invalid date' ? '-' : moment(value).format('DD-MM-YYYY') : '-';
});
Vue.filter('dayparse', function (value) {
  if (!value) return '-'
  else return value ? moment(value, 'DD-MM-YYYY').calendar(null, {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastWeek: '[last] dddd',
    nextWeek: 'dddd',
    sameElse: 'dddd'
  }) : '-';
});

Vue.filter('fulldateparse', function (value) {
  if (!value) return '-'
  else return value ? moment(value).format('DD-MM-YYYY h:mm a') : '-';
});

Vue.filter('timeparse', function (value) {
  if (!value) return '-'
  else return value ? moment(new Date(value)).format('LT') : '-';
});

Vue.filter('temptimeparse', function () {});

Vue.filter('getDay', function (value) {
  if (!value) return '-'
  else return value ? moment(new Date(value)).format('dddd') : '-'
});

Vue.filter('getMonth', function (value) {
  if (!value) return '-';
  else return value ? moment(new Date(value)).format('MMMM') : '-'
});

Vue.filter('getDayDigit', function (value) {
  if (!value) return '-'
  else return value ? moment(new Date(value)).format('Do') : '-'
});

Vue.filter('dayonly3', function (value) {
  if (!value) return '-'
  else return value ? moment(new Date(value)).format('ddd') : '-'
});

Vue.filter('timeparseAmPm', function (value) {
  // console.log('value :', value);
  if (!value) return '-'
  else return value ? moment(`${moment(new Date()).format('DD-MM-YYYY')} ${value}`, 'DD-MM-YYYY HH:mm').format('h:mm A') : '-'
});

// Vue.filter('datetimeparseAmPm', function (value) {
//     console.log('value :', value);
//     if (!value) return '-'
//     else return value ? moment(`${moment(new Date()).format('DD-MM-YYYY')} ${value}`, 'DD-MM-YYYY HH:mm').format('h:mm A') : '-'
// });

Vue.filter('fulldatemonparse', function (value) {
  if (!value) return '-'
  else return value ? moment(value).format('DD-MMM-YYYY h:mm:ss A') : '-';
});

Vue.filter('fulldaydateparse', function (value) {
  if (!value) return '-'
  else return value ? moment(new Date(value)).format('MMMM Do YYYY, h:mm a') : '-'
});

Vue.filter('RoundOff', function (value) {
  if (!value) return '0'
  else return value ? Math.round(value * 100) / 100 : '-'
})
