export const Role = {
  SuperAdmin: "Super Admin",
  HoManager: "HO Manager",
  CinemaManager: "Cinema Manager",
  PosOperator: "POS Operator",
  // PosOperator: 'POS Operator',
  PosFnBOperator: "FnB POS Operator",
  Distributor: "Distributor",
  BoxOfficeFnbOperator: "Box Office Fnb",
};
export const CLIENT_NAME = {
  CINEMA_AKIL: "Cinema Akil",
  EMPIRE: "empire",
  STUESSIHOF: "stuessihof",
  XANADU: "xanadu",
  PETRO: "petro",
  IRAQ: "Iraq",
  LEBANON: "lebanon",
  BILDHAUS: "bildhaus",
  STAR: "starcinemas",
  QFX: "qfx",
  CARNIVAL: "carnival",
};

export const CLIENT_NAMES_OBJ = {
  empire_combo: ["empire", "petro", "Iraq", "star", "lebanon"],
  empire_iraq: ["empire", "Iraq"],
  empire_iraq_lebanon: ["empire", "Iraq", "lebanon", "bildhaus"],
  iraq: ["Iraq"],
  empire: ["empire"],
  xanadu: ["xanadu"],
  petro: ["petro"],
  stuessihof: ["stuessihof"],
  lebanon: ["lebanon"],
  bildhaus: ["bildhaus"],
  carnival: ["carnival"],
};
