<template>
  <div id="app">
    <!-- ADD A "BLUR" CLASS TO "MAIN" TAG WHENEVER LOADER IS TRUE -->
    <!-- ADD A "BLUR" CLASS TO "MAIN" TAG WHENEVER MODAL IS TRUE -->
    <!-- <Modal /> -->
    <router-view />
  </div>
</template>
<script>
// import Loader from "@/components/Partials/loader.vue";
// import Modal from "@/components/Partials/globalModal.vue";
export default {
  name: "App",
  components: {
    // Loader,
    // Modal
  },
  head: {
    title() {
      return `Testing`;
    },
    meta: [
      {
        name: "description",
        content() {
          return `Here is the description`;
        }
      }
    ]
  },
  beforeMount() {},
  methods: {}
};
</script>
