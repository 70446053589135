import moment from "moment";
import Vue from 'vue';
import ReportsService from '@/services/ReportsService';
import { router } from '@/router';
import store from '@/store'

function initialState() {
    return {
        dailyReportsList: [],
        dailyConcessionReportsList: [],
        dailyExcelFileName: null,
        dailyPDFFileName: null,
        flag: {
            isCinema: false,
            isExcelDownload: false,
        },
        dailyReportsObject: {
            fromDate: new Date(),
            toDate: new Date(),
            cinema_id: null,
            user_id: null,
            pm_id: null,

            // Show Selected Name Only
            cinema_name: "",
            username: "",
            pm_payment_mode: "",
            // THIS IS FOR FUTURE
            // offers_voucher_id: null,
            // offer_voucher_type: ""
        },
        paging: {},
        item: {}
    }
};

const state = initialState();

const mutations = {
    ON_RESET_DAILY_SALES_REPORT_STORE_STATE: (state, payload) => {
        // state = initialState();
        const s = initialState()
        Object.keys(s).forEach(key => {
            state[key] = s[key]
        })
    },
    SET_DAILY_REPORT_LIST: (state, payload) => {
        state.dailyReportsList = payload;
    },
    SET_DAILY_CONCESSION_REPORT_LIST: (state, payload) => {
        state.dailyConcessionReportsList = payload;
    },
    SET_DAILY_EXCEL_FILE_NAME: (state, payload) => {
        state.dailyExcelFileName = payload;
    },
    SET_DAILY_PDF_FILE_NAME: (state, payload) => {
        state.dailyPDFFileName = payload;
    },
    SET_DAILY_REPORT_DATES: (state, payload) => {
        payload['name'] ? state.dailyReportsObject[payload.name] = payload['event'] ? payload.event : null : '';
    },
    SET_DAILY_REPORT_DROPDOWN: (state, payload) => {
        if (state.dailyReportsList.length > 0) {
            state.dailyReportsList = [];
            state.dailyConcessionReportsList = [];
        }
        switch (payload.name) {
            case 'Cinema':
                state.dailyReportsObject.cinema_id = payload['selectedItem'] ? payload.selectedItem.cine_id : null;
                state.dailyReportsObject.cinema_name = payload['selectedItem'] ? payload.selectedItem.cine_name : null;
                break;

            case 'PosUser':
                state.dailyReportsObject.user_id = payload['selectedItem'] ? payload.selectedItem.user_id : null;
                state.dailyReportsObject.username = payload['selectedItem'] ? !payload.selectedItem.user_id ? 'All' : `${payload.selectedItem.first_name} ${payload.selectedItem.middle_name} ${payload.selectedItem.last_name}` : null;
                break;
            case 'PaymentMode':
                state.dailyReportsObject.pm_id = payload['selectedItem'] ? payload.selectedItem.pm_id : null;
                state.dailyReportsObject.pm_payment_mode = payload['selectedItem'] ? payload.selectedItem.pm_payment_mode : null;
                break;
            // case 'OfferVoucher':
            //     state.dailyReportsObject.offers_voucher_id = payload['selectedItem'] ? payload.selectedItem.offers_voucher_id : null;
            //     state.dailyReportsObject.offer_voucher_type = payload['selectedItem'] ? payload.selectedItem.offer_voucher_type : null;
            //     break;
            default:
                console.log('Sorry, we are enable to set value.');
        }
    },
    SET_DAILY_REPORT_FLAG: (state, payload) => {
        switch (payload.name) {
            case 'isCinema':
                state.flag.isCinema = payload['value'] ? payload.value === 0 ? false : true : false;
                state.dailyReportsObject.cinema_id = payload['value'] && payload.value === 0 ? null : payload.value;
                break;
            case 'isExcelDownload':
                if (state.dailyExcelFileName && state.dailyExcelFileName.length > 0) {
                    store.dispatch("globalRemoveFile", { fileName: state.dailyExcelFileName });
                };
                if (state.dailyPDFFileName && state.dailyPDFFileName.length > 0) {
                    store.dispatch("globalRemoveFile", { fileName: state.dailyPDFFileName });
                };
                state.dailyPDFFileName = null;
                state.dailyExcelFileName = null;
                break;
            default:
                console.log('Sorry, we are enable to set Flag.');
        }
    }
}

const actions = {
    onResetDailyReportStoreState: async ({ dispatch, commit, state }, { ...payload }) => {
        await commit('ON_RESET_DAILY_SALES_REPORT_STORE_STATE');
    },
    fetchDailyReportList({ dispatch, commit, state }, payload) {
        state.dailyExcelFileName ? store.dispatch("globalRemoveFile", { fileName: state.dailyExcelFileName }) : '';
        state.dailyPDFFileName ? store.dispatch("globalRemoveFile", { fileName: state.dailyPDFFileName }) : '';
        // console.log('object :', state.dailyReportsObject.fromDate, state.dailyReportsObject.toDate);
        // console.log('1 :', state.dailyReportsObject.fromDate >= new Date() && state.dailyReportsObject.toDate >= new Date());
        // console.log('2 :', state.dailyReportsObject.fromDate >= new Date());
        // console.log('3 :', state.dailyReportsObject.toDate >= new Date());
        // console.log('4 :', new Date(state.dailyReportsObject.fromDate) > new Date(state.dailyReportsObject.toDate));
        // console.log('4 :', new Date(state.dailyReportsObject.fromDate), new Date(state.dailyReportsObject.toDate));
        let fromDate = moment(new Date(state.dailyReportsObject.fromDate)).format("YYYY-MM-DD");
        let toDate = moment(new Date(state.dailyReportsObject.toDate)).format("YYYY-MM-DD");

        // console.log('object :', new Date(fromDate) > new Date(toDate));
        // console.log('object 2 :', new Date(fromDate), new Date(toDate));

        if (new Date(fromDate) >= new Date() && new Date(toDate) >= new Date()) {
            Vue.toasted.show("From date and To date should not be greater than the current date.");
        } else if (new Date(fromDate) >= new Date()) {
            Vue.toasted.show("From date should not be greater than the current date.");
        } else if (new Date(toDate) >= new Date()) {
            Vue.toasted.show("To date should not be greater than the current date.");
        } else if (new Date(fromDate) > new Date(toDate)) {
            Vue.toasted.show("From date should not be greater than To date.");
        } else if (!state.dailyReportsObject.cinema_id) {
            Vue.toasted.show("Please Select any Cinema.");
        }
        // NOT CHECKING STRICTLY
        // else if (!state.dailyReportsObject.user_id) {
        //     Vue.toasted.show("Please Select any user.");
        // } else if (!state.dailyReportsObject.pm_id) {
        //     Vue.toasted.show("Please Select any Payment Mode.");
        // }
        // THIS IS FOR FUTURE
        // else if (!state.dailyReportsObject.offers_voucher_id) {
        //     Vue.toasted.show("Please Select any Offer Voucher.");
        // }
        else {
            let loader = Vue.$loading.show()

            commit('SET_DAILY_REPORT_LIST', []);
            commit('SET_DAILY_CONCESSION_REPORT_LIST', []);
            commit('SET_DAILY_PDF_FILE_NAME', null);
            commit('SET_DAILY_EXCEL_FILE_NAME', null);


            ReportsService.GetDailyReportList(state.dailyReportsObject)
                .then(async (response) => {
                    const { data } = response;
                    if (data && data.status) {
                        if (data.Records.length > 0) {
                            await commit('SET_DAILY_REPORT_LIST', data.Records || []);
                        };
                        if (data.ConcessionFinalResults.length > 0) {
                            await commit('SET_DAILY_CONCESSION_REPORT_LIST', data.ConcessionFinalResults || [])
                        }
                        await commit('SET_DAILY_EXCEL_FILE_NAME', data.Excel || null);
                        await commit('SET_DAILY_PDF_FILE_NAME', data.PDFName || null);
                    } else Vue.toasted.show(data.message || 'Opps! Something went wrong');
                }).catch((error) => {
                    Vue.toasted.show("Catch Error on ReportStore fetchDailyReportList", error);
                }).finally(() => {
                    setTimeout(() => loader.hide(), 1000);
                });;
        }
    },

    // On Handlers
    onDailyReportDateHandler: async ({ dispatch, commit, state }, payload) => {
        await commit('SET_DAILY_REPORT_DATES', payload);
        await commit('SET_DAILY_REPORT_FLAG', { name: 'isExcelDownload', value: false });
    },
    onDailyReportDropdownHandler: async ({ dispatch, commit, state }, payload) => {
        await commit('SET_DAILY_REPORT_DROPDOWN', payload);
        await commit('SET_DAILY_REPORT_FLAG', { name: 'isExcelDownload', value: false });
    },
    onDailyReportFlagHandler({ dispatch, commit, state }, payload) {
        commit('SET_DAILY_REPORT_FLAG', payload);
    },
}

const getters = {
    // THIS IS FOR FUTURE
    // getDailyReportSelectedOfferVoucher: state => state.dailyReportsObject.offer_voucher_type,

    // Selected Dropdown
    getDailyReportSelectedCinema: state => state.dailyReportsObject.cinema_name,
    getDailyReportSelectedPosUser: state => state.dailyReportsObject.username,
    getDailyReportSelectedPaymentMode: state => state.dailyReportsObject.pm_payment_mode,

    // Selected Date
    getDailyReportFromDate: state => state.dailyReportsObject.fromDate,
    getDailyReportToDate: state => state.dailyReportsObject.toDate,

    // Flags
    getDailyReportsIsCinema: state => state.flag.isCinema,
    getDailyReportsIsExcelDownload: state => state.flag.isExcelDownload,

    // get File name
    getDailyExcelFileName: state => state.dailyExcelFileName,
    getDailyPDFFileName: state => state.dailyPDFFileName,

    // Rendered List
    getDailyReportList: state => state.dailyReportsList,
    getDailyConcessionReportList: state => state.dailyConcessionReportsList,
}

export default {
    state,
    mutations,
    actions,
    getters
}
