import moment from "moment";
import Vue from 'vue';
import ReportsService from '@/services/ReportsService';
import { router } from '@/router';

const state = {
    aggregatorsReportsList: [],
    flag: {
        isCinema: false,
    },
    aggregatorsObject: {
        fromDate: new Date(),
        toDate: new Date(),
        cinema_id: null,
        cinema_name: "Select Cinema",
        d_id: null,
        d_company_name: "Select Distributor",
        movie_id: null,
        movie_title: "Select Movie"
    },
    paging: {},
    item: {}
}

const mutations = {
    // SET_AGGREGATORS_REPORT_LIST: (state, payload) => {
    //     state.aggregatorsReportsList = payload;
    // },
    SET_AGGREGATORS_REPORT_DATES: (state, payload) => {
        payload['name'] ? state.aggregatorsObject[payload.name] = payload['event'] ? payload.event : null : '';
    },
    SET_AGGREGATORS_REPORT_DROPDOWN: (state, payload) => {
        switch (payload.name) {
            case 'Cinema':
                state.aggregatorsObject.cinema_id = payload['selectedItem'] ? payload.selectedItem.cine_id : null;
                state.aggregatorsObject.cinema_name = payload['selectedItem'] ? payload.selectedItem.cine_name : null;
                break;
            default: console.log('FilmCollection :Sorry, we are enable to set value.');
        }
    },
    SET_AGGREGATORS_REPORT_FLAG: (state, payload) => {
        switch (payload.name) {
            case 'isCinema':
                state.flag.isCinema = payload['value'] ? payload.value === 0 ? false : true : false;
                state.aggregatorsObject.cinema_id = payload['value'] && payload.value === 0 ? null : payload.value;
                break;
            default:
                console.log('Sorry, we are enable to set Flag.');
        }
    }
}

const actions = {
    fetchAggregatorsReportList({ dispatch, commit, state }, payload) {
        let fromDate = moment(new Date(state.aggregatorsObject.fromDate)).format("YYYY-MM-DD");
        let toDate = moment(new Date(state.aggregatorsObject.toDate)).format("YYYY-MM-DD");

        if (new Date(fromDate) >= new Date() && new Date(toDate) >= new Date()) {
            Vue.toasted.show("From date and To date should not be greater than the current date.");
        } else if (new Date(fromDate) >= new Date()) {
            Vue.toasted.show("From date should not be greater than the current date.");
        } else if (new Date(toDate) >= new Date()) {
            Vue.toasted.show("To date should not be greater than the current date.");
        } else if (new Date(fromDate) > new Date(toDate)) {
            Vue.toasted.show("From date should not be greater than To date.");
            // } else if (!state.aggregatorsObject.cinema_id) {
            //     Vue.toasted.show("Please Select any Cinema.");
            // } else if (!state.aggregatorsObject.d_id) {
            //     Vue.toasted.show("Please Select any Distributor.");
            // } else if (!state.aggregatorsObject.movie_id) {
            //     Vue.toasted.show("Please Select any Movie.");
        } else {
            let loader = Vue.$loading.show();

            ReportsService.GetAggregatorsReportList({})
                .then((response) => {
                    const { data } = response;
                    console.log('data :', data);
                    if (data && data.status && data.Records) {
                        // commit('SET_AGGREGATORS_REPORT_LIST', data.Records || [])
                    } else Vue.toasted.show(data.message || 'Opps! Something went wrong');
                }).catch((error) => {
                    Vue.toasted.show("Catch Error on ReportStore fetchAggregatorsReportList", error);
                }).finally(() => {
                    setTimeout(() => loader.hide(), 1000);
                });
        }
    },

    // On Handlers
    onAggregatorsReportDateHandler({ dispatch, commit, state }, payload) {
        commit('SET_AGGREGATORS_REPORT_DATES', payload)
    },
    onAggregatorsReportDropdownHandler({ dispatch, commit, state }, payload) {
        commit('SET_AGGREGATORS_REPORT_DROPDOWN', payload)
    },
    onAggregatorsReportFlagHandler({ dispatch, commit, state }, payload) {
        commit('SET_AGGREGATORS_REPORT_FLAG', payload)
    }
}

const getters = {
    getAggregatorsReportFromDate: state => state.aggregatorsObject.fromDate,
    getAggregatorsReportToDate: state => state.aggregatorsObject.toDate,
    getAggregatorsReportSelectedCinema: state => state.aggregatorsObject.cinema_name,
    getAggregatorsReportsIsCinema: state => state.flag.isCinema,
    getAggregatorsReportList: state => state.aggregatorsReportsList,

};

export default {
    state,
    mutations,
    actions,
    getters
}
