export default {
  release: 'Release Seat',
  seatorder: 'Order',
  SeatType: 'Seat Type',
  generatebill: 'Generate Bill',
  noofpos: 'No. of POS',
  kiosk: 'No. of Kiosk',
  noofsignage: 'No. of Signage',
  fnbcounter: 'F & B Counter',
  department_name: 'Department',
  employeecode: 'Employee code',
  select_user_Department: 'Please Select Department',
  select_user_Cinema: 'Please Select Cinema',
  select_user_Designation: 'Please Select Designation',
  select_user_Roles: 'Please Select Role',
  user_select_Country: 'Please Select Country',
  user_select_State: 'Please Select State',
  selectgender: "Please Select Gender",
  selectmachine: "Select Machine",
  confirm: "Confirm",
  cinemacode: "Cinema Code",
  mobilecode: "Mobile Code",
  resend: "Resend",
  confirmpassword: "Confirm Password",
  fnbitem: "F&B Item",
  itemcostprice: "Item Cost Price",
  NoRecords: "No Records Found",
  designation: 'Designation | Designations',
  add: 'Add',
  orgcinema: 'No of Cinema',
  proceedtocheckout: 'Proceed to Checkout',
  listofall: 'List of all',
  incinematic: 'In Brij',
  viewing: 'Viewing',
  of: 'Of',
  admin: 'Admin',
  status: 'Status | Statuses',
  edit: 'Edit',
  view: 'View',
  rawmaterials: 'Raw Material | Raw Materials',
  active: 'Active',
  list: 'List | Lists',
  blankfieldmsg: 'This field cannot be blank',
  titleempty: 'Title cannot be empty',
  update: 'Update',
  reset: 'Reset',
  code: 'Code',
  isocode: 'ISO Code',
  statecode: 'State Code',
  cancel: 'Cancel',
  details: 'Detail | Details',
  createdby: 'Created By',
  createdat: 'Created At',
  updatedat: 'Updated At',
  updatedby: 'Updated By',
  modifiedby: 'Modified By',
  modifiedat: 'Modified At',
  stateprovince: 'State/Province',
  save: 'Save',
  saveaddmore: 'Save & Add more',
  home: 'Home',
  master: 'Master | Masters',
  bookingdiscount: 'Booking Discount | Booking Discounts',
  movie: 'Movie | Movies',
  schedule: 'Schedule | Schedules',
  aggregator: 'Aggregator | Aggregators',
  recipe: 'Recipe | Recipes',
  bookingsource: 'Booking Source | Booking Sources',
  cinema: 'Cinema | Cinemas',
  setting: 'Setting | Settings',
  backup: 'Backup | Backups',
  charge: 'Charge | Charges',
  type: 'Type | Types',
  country: 'Country',
  countrycode: 'Country Code',
  city: 'City | Cities',
  crew: 'Crew | Crews',
  currency: 'Currency | Currencies',
  denomination: 'Denomination | Denominations',
  distributor: 'Distributor | Distributors',
  feature: 'Feature | Features',
  fb: 'F&B | F&B',
  fnb: 'Food & Beverage | Food & Beverages',
  price: 'Price | Prices',
  configuration: 'Configuration | Configurations',
  food: 'Food | Food',
  category: 'Category | Categories',
  gender: 'Gender | Gender',
  genre: 'Genre | Genre',
  hardware: 'Hardware | Hardwares',
  language: 'Language | Languages',
  marital: 'Marital | Marital',
  maritalstatus: 'Marital Status | Marital Status',
  module: 'Module | Modules',
  format: 'Format | Formats',
  payment: 'Payment | Payments',
  mode: 'Mode | Modes',
  ticket: 'Ticket | Tickets',
  refund: 'Refund | Refunds',
  reason: 'Reason | Reasons',
  role: 'Role | Roles',
  seat: 'Seat | Seats',
  reservation: 'Reservation | Reservations',
  shift: 'Shift | Shifts',
  signage: 'Signage | Signages',
  state: 'State | States',
  unitofmeasurement: 'Unit of Measurement | Unit of Measurements',
  vendor: 'Vendor | Vendors',
  weekday: 'Weekday | Weekdays',
  ho: 'HO | HO',
  headoffice: 'Head Office | Head Office',
  productioncompany: 'Production Company | Production Companies',
  name: 'Name',
  sistercompany: 'Sister Company',
  contactno: 'Contact Number',
  emailid: 'Email Id',
  tinnumber: 'TIN Number',
  noof: 'No Of',
  employee: 'Employee',
  taxnumber: 'Tax number',
  taxtype: 'Tax Type',
  taxtypephld: 'Select Movie Format',
  address: 'Address',
  more: 'More',
  imagesettingname: 'Image Setting Title',
  imagesetting: 'Image Setting | Image Settings',
  height: 'Height',
  width: 'Width',
  ratio: 'Ratio',

  // manage: 'manage',
  // manager: 'manager',


  // NEWLY ADDED BY JAY
  trailer: 'Trailer | Trailers',
  poster: 'Poster | Posters',

  // NEWLY ADDED BY AKASH
  releasedate: 'Release Date',
  runtime: 'Run Time',
  nameofperson: 'Name Of the person',
  order: 'Order',
  distribution: 'Distribution',
  content: 'Content',
  moviesearch: 'Movie Search',
  production: 'Production',
  rate: 'Rate',
  description: 'Description',
  contract_start_date: 'Contract Start Date',
  contract_end_date: 'Contract End Date',
  apiid: 'API ID',
  authentcationcode: 'Authentication Code',
  authentcation_code_tooltip: 'Authentication Code',
  username: 'Username',
  password: 'Password',
  ingredient: 'Ingredients',
  quantity: 'Quantity',
  selectusercategory: 'Select User Category',
  discount: 'Discount',
  registration: 'Registration No',
  license: 'License',
  contact: 'Contact',
  pincode: 'Pincode',
  latitude: 'Latitude',
  longitude: 'Longitude',
  screen: 'Screen | Screens',
  operationtime: 'Operation Time',
  operationstarttime: 'Operation Start Time',
  operationendtime: 'Operation End Time',
  cinemaconessionlevel: 'Cinema Concession Level',
  language: 'Language',
  onlinevisibility: 'Online Visibility',
  sellinboxoffice: 'Sell in BoxOffice',
  cleaningtime: 'Cleaning Time',
  cleaningstarttime: 'Cleaning Start Time',
  // operationstarttime:'Operation Start Time',
  operationendtime: "Operation End Time",
  intervaltime: 'Interval Time',
  cutofftime: 'Cut Off Time',
  advertisementtime: 'Advertisement Time',
  seatreleasetime: 'Seat Release Time',
  english: 'English',
  arabic: 'Arabic',
  spanish: 'Spanish',
  currencycode: 'Currency Code',
  isfirstdayoftheweek: 'Is First Day Of The Week',
  weekdayisoff: 'Is Week Off',
  parent: 'Parent',
  workinghour: 'Working Hours',
  starttime: 'Start Time',
  endtime: 'End Time',
  applicableto: 'Applicable to',
  categoryname: 'Category Name',
  backuplocationbrowse: 'Backup Location Browse',
  provideavalidname: 'Provide a valid pathname',
  daily: 'Daily',
  select: 'Select',
  selection: 'Selección',
  howlongbackuptokeep: 'How Long Backup To Keep',
  selectdateandtime: 'Select Date & Time',
  monthly: 'Monthly',
  yearly: 'Yearly',
  fortnight: 'Fortnight',
  weekly: 'Weekly',
  backuprestore: 'Backup Restore',
  rating: 'Ratings',
  selected: 'Selected',
  plot: 'Plot',
  trailerlink: 'Trailer Link',
  imageurl: 'Image URL',
  value: 'Value',
  fromdate: 'From Date',
  todate: 'To Date',
  time: 'Time',
  gold: 'Gold',
  silver: 'Silver',
  tax: 'Tax',
  taxamount: 'Tax Amount',
  receipename: 'Select Recipe',
  invalidentry: 'Invalid Entry',
  hardwareuniquecode: 'Hardware Unique Code',
  noteremarks: 'Notes / Remarks',
  connectionport: 'Connection Port',
  authenticationcode: 'Authentication Code',
  key: 'Key',
  tooltip: 'Tooltip',
  starttime: 'Start Time',
  endtime: 'End Time',
  sun: 'Sun',
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thu',
  fri: 'Fri',
  sat: 'Sat',
  user: 'User | Users',
  shiftstartdate: 'Shift Start Date',
  shiftenddate: 'Shift End Date',
  sharetype: 'Share Type',
  cinemaname: 'Cinema Name',
  backuptype: 'Backup Type',
  backuptime: 'Backup Time',
  report: 'Report',
  middlename: 'Middle Name',
  lastname: 'Last Name',
  dob: 'Date Of Birth',
  doj: 'Date Of Joining',
  zipcode: 'Zip Code',
  phone: 'Phone',
  mobiles: 'Mobile',
  employeeid: 'Employee ID',
  employeetype: 'Employment Type',
  no: 'No.',
  access: 'Access',
  forgot: 'Forgot',
  signin: 'Sign In',
  change: 'Change',
  old: 'Old',
  enternew: 'Enter New',
  confirmnew: 'Confirm New',
  update: 'Update',
  forgot: 'Forgot',
  email: 'Email',
  alert: 'Alert',
  alertthreshold: 'Alert threshold (quantity)',
  vendormapping: 'Mapeo de proveedores',
  generatepurchaseorder: 'Generate Purchase Order',
  pricelabel: 'Price Label',
  amount: 'Amount',
  allbooking: 'All Booking',
  searchbooking: 'Search Booking',
  search: 'Search',
  datetime: 'Date & Time',
  booking: 'Booking',
  confirmationid: 'Confirmation ID',
  paymentsummary: 'Payment Summary',
  vendormapping: 'Vendor Mapping',
  submit: 'Submit',
  platinum: 'Platinum',
  startdate: 'Start Date',
  enddate: 'End Date',
  showenddate: 'Show End Date',
  ratecard: 'Rate Card',
  inclusivoftax: 'Inclusive Of Tax',
  calculatesplit: 'Calculate Split',
  clientlogo: 'Client-logo',
  organisationlogo: 'Organisation-logo',
  oganization: 'Organization',
  setupdate: 'Setup Date',
  launchdate: 'Launch Date',
  isPercentage: 'Is Percentage',
  isAddition: 'Is Addition',
  isTaxable: 'Is Taxable',
  grossAmount: 'Gross amount',
  netAmount: 'Net amount',
  share: 'Share',
  shareValue: 'Share Value',
  sharelabel: 'Share Label',
  fixedValueToTheatre: 'Fixed Value To Theatre',
  isPercentageValue: 'Is Percentage Value',
  contract: 'Contract',
  fixedValueShare: 'Fixed Value Share',
  percentgeOf: 'Percentage Of',
  duration: 'Duration',
  floatAmount: 'Float amount',
  enable: 'Enable',
  date: 'Date',
  imagesettingname: 'Image setting name',
  quantityAlert: 'Quantity Alert',
  stockAlert: 'Stock Alert',
  chargesettingname: 'Charge Setting',
  ratedCardName: 'Rated Card Name',
  operation: 'Operation',
  showtime: 'Show Time',
  refundReasons: 'Refund Reasons',
  selectConcessionLevel: 'Select Concession Level',
  purchase: 'Purchase',
  items: 'Items',
  totalamount: 'Total Amount',
  purchaseorderdate: 'Purchase Order Date',
  purchaseorderlock: 'Purchase Order Lock',
  isdefaultvendor: 'Is Default Vendor',
  deparmentcode: 'Department Code',
  isperishable: 'Is perishable',
  rm_rte: 'Ready To Eat',
  recco: 'Recco',
  rawmaterial: 'Raw Material',
  foodcategoryname: 'Food Category Name',
  parentfoodcategoryname: 'Parent Food Category',
  foodorder: 'Order in Pos',
  profile: 'Profile',
  isparent: 'Is Parent',
  expiry: 'Expiry',
  days: 'Days',
  recipeiscombo: 'Recipe is combo',
  fnbitems: 'F&B item',
  recipename: 'Recipe Name',
  itemname: 'Item Name',
  itemcode: 'Item Code',
  foodcategory: 'Food Category',
  uploadfoodimage: 'Upload Food Image',
  isveg: 'Is Veg',
  usersshift: 'User Shift',
  start: 'Start',
  end: 'End',
  in: 'In',
  date: 'Date',
  concessionstock: 'Concession Stock',
  concession: 'Concession',
  fnbPricing: 'F&B Pricing',
  fnbWastage: 'F&B Wastage',
  accountableuser: 'Account Able User',
  approvedby: 'Approved By',
  fineamount: 'Fine Amount',
  netamount: 'Net Amount',
  grossamount: 'Gross Amount',
  concessionLevel: 'Concession Level',
  orderNo: 'Order No',
  stockinQuantity: 'Stock in Quantity',
  cash: 'Cash',
  given: 'Given',
  all: 'All',
  release: 'Release',
  running: 'Running',
  action: 'Action',
  adventure: 'Adventure',
  scifi: 'Sci-fi',
  castCrew: 'Cast & Crew',
  synopsis: 'Synopsis',
  creditDebit: 'Credit & Debit Card',
  cashlessPayment: 'Cashless Payment',
  splitPayments: 'Split Payments',
  couponPromotion: 'Coupon Promotion',
  voucher: 'Vouchers',
  loyaltyCard: 'Loyalty Card',
  payBy: 'Pay By',
  number: 'Number',
  idNumber: 'ID Number',
  additionalChanges: 'Additional Charges',
  debitNcreditCard: 'Debit Card / Credit Card',
  occupancy: 'Occupancy',
  close: 'Close',
  book: 'Book',
  pickup: 'Pickup',
  damage: 'Damage',
  roll: 'Roll',
  new: 'New',
  checkout: 'Checkout',
  back: 'Back',
  admits: 'Admits',
  unpaid: 'Unpaid',
  available: 'Available',
  done: 'Done',
  print: 'Print',
  reprint: 'Reprint',
  image: 'Image',
  enter: 'Enter',
  proceed: 'Proceed',
  addDirectlyToStore: 'Add Directly To Store',
  unit: 'Unit',
  subtotal: 'Subtotal',
  shipping: 'Shipping',
  iscountable: 'Is Countable',
  uploaduserimage: 'Upload User Image',
  collection: 'Collection',
  film: 'Film',
  wise: 'Wise',
  sale: 'Sales',
  ratings: 'Ratings',
  uom: 'UOM',
  transferred: 'Transfer | Transferred',
  in: 'In',
  love: 'Love',
  recliner: 'Recliner',
  wheelchair: 'Wheel Chair',
  taken: 'Taken',
  your: 'Your',
  full: 'Full',
  exit: 'Exit',
  screen_title: 'All Eyes This Way',
  skip: 'Skip',
  total: 'Total',
  vipSeat: 'VIP Seat',
  regularSeat: 'Regular Seat',
  noSeat: 'No Seat',
  sofa: 'Sofa',
  loveSeat: 'Love Seat',
  reports: 'Reports',
  rolemanagement: 'Role Management',
  departmentid: 'Department ID',
  type: 'Type',
  dol: 'Date of Leaving',
  Renunciation: 'Renunciation',
  rol: 'Reason for Leaving',
  verificationtype: 'Verification Type',
  uploadbinnumbers: 'Upload Bin Number File',
  uploadbanklogo: 'Upload Bank Logo',
  bank: 'Bank',
  weekdaymsg: 'please select atleast one day',
  applicablemsg: 'please select atleast one applicable type checkbox',
  redeematmsg: 'please select atleast one reedem at checkbox',
  yes: 'YES',
  no: 'NO',
  institution: 'Institution',
  offertypes: 'Offer Types',
  offer: 'Offer',
  customercode: 'Customer Code',
  customer: 'Customer',
  marketingtype: 'Marketing Type',
  cardtype: 'Card Type',
  bankmsg: "Please select Bank ",
  mobileno: "Contact Number",
  marketTypemsg: "Please Select Marketing Type",
  weekends: 'Weekends',
  binNumbers: 'Bin Numbers',
  upload: 'Upload',
  customer: 'Customer | Customers',
  anniversary: 'Anniversary',
  verified: 'Verified',
  customerisactive: 'Customer is active',
  icon: 'Icon',
  concession: 'Concession',
  promotionsandoffer: 'Promotions & Offers',
  title: 'Title',
  redemptionOutlet: 'Redemption Outlet',
  canavailonline: 'Can avail Online',
  availatboxoffice: 'Avail at Box Office',
  availatconcession: 'Avail at Concession',
  offerconfiguration: 'Offer Configuration',
  applicableforparticularmovie: 'Applicable for particular movie?',
  applicableforspecificdaysdatetime: 'Applicable for specific Days, Date, Time',
  applicableforselectedcinemasnclasses: 'Applicable for selected Cinemas & Classes?',
  buyngetfreeticket: 'Buy & Get Free Ticket',
  buy: 'Buy',
  get: '& get',
  ofticketsfree: 'of tickets free',
  minimumticketpurchase: 'Minimum ticket purchase',
  maximumfreeticketpertransaction: 'Maximum free ticket per transaction',
  cancombinewithotheroffers: 'Can combine with other offers',
  discounts: 'Discounts',
  maxdiscountpertransaction: 'Max discount per transaction',
  cancombinewithotheroption: 'Can combine with other option',
  buyandgetcombo: 'Buy and get Combo',
  offerapplicableat: 'Offer applicable at',
  customercategoryoffer: 'Customer Category Offer',
  student: 'Student',
  corporate: 'Corporate',
  validateby: 'Validate By',
  offerprice: 'Offer Price',
  specialInstruction: 'Special Instruction',
  specialinstructonregardingoffer: 'Special Instructon Regarding Offer',
  promocode: 'Promo Code',
  send: 'Send',
  applicablefor: 'applicable for',
  transactions: 'Transactions',
  show: 'Show',
  vouchersissuedbypartnername: 'Vouchers issued by Partner Name',
  noofvoucherstobegenerated: 'No of Vouchers to be generated',
  foramount: 'for amount',
  redemptionvalidfrom: 'Redemption valid from',
  termsandconditions: 'Terms & Conditions',
  generate: 'Generate',
  loyalty: 'Loyalty',
  program: 'Program',
  level: 'Level',
  minimumpointsforredemption: 'Minimum points for redemption',
  maximumpointstoberedeemedpertransaction: 'Maximum points to be redeemed per transaction',
  requirementtoenroll: 'Requirement to enroll',
  age: 'Age',
  proof: 'Proof',
  eligibilitytoenroll: 'Eligibility to enroll',
  earnpoints: 'Earn Points',
  pointsfromamount: 'points from amount',
  spent: 'Spent',
  burnpoints: 'Burn Points',
  earnpointsonlyonnondiscountedamount: 'Earn Points only on non discounted amount',
  eligiblewithotheroffers: 'Eligible with other offers',
  eligibleforfreetickets: 'Eligible for free tickets',
  ticketsper: 'Tickets per',
  showpriority: 'Show Priority',
  prepaidcard: 'Prepaid Card',
  cardname: 'Card Name',
  cardserialnostart: 'Card Serial No Start',
  cardserialnoends: 'Card Serial No Ends',
  cardvalidityfromdate: 'Card Validity from Date',
  cardexpirydate: 'Card Expiry Date',
  loadamount: 'Cantidad de carga',
  getextra: 'get extra',
  maxloadamount: 'max load amount',
  maxextraamount: 'max extra amount',
  freemovieoffer: 'Free Movie Offer',
  watch: 'Watch',
  free: 'Free',
  spendamount: 'Spend amount',
  for: 'for',
  moviesandget: 'movies and get',
  moviesfree: 'Movies free',
  inmins: 'in mins',
  useallloyaltyofferswiththiscard: 'Use all Loyalty offers with this card',
  loyaltycustomer: 'Loyalty Customer',
  selectloyaltyprogram: 'Select Loyalty Program',
  initialloadamt: 'Initial Load Amt',
  balance: 'Balance',
  joinedprogramon: 'Joined Program on',
  expirydateifany: 'Expiry Date (If Any)',
  noofticketpurchased: 'No Of Ticket Purchased',
  noffamountsaved: 'No Of Amount Saved',
  storemanagement: 'Store management',
  documentvarification: 'Document Verification',
  document: 'Document',
  toverify: 'To verify',
  unitconverter: 'Unit Converter',

  // bookingusercategorys: 'booking user category',

  // //Menu items
  // cinemasettings: 'Cinema Settings',
  // backupsettings: 'Backup Settings',
  // chargetypes: 'Charge Types',
  // fnbconfig: 'F&B Configurations',
  // fnbprice: 'F&B Price',
  // foodcategories: 'Food Categories',
  // martialstatus: 'Marital Status',
  // movieformat: 'Movie Format',
  // paymentmode: 'Payment Mode',
  // ticketpriceconfiguration: 'Ticket Price Configuration',
  // ticketprice: 'Ticket Price',
  // refundreason: 'Refund Reasons',
  // seatreservationtype: 'Seat Reservation Type',
  // moviecategory: 'Movie Category',
  // signagetype: 'Signage Type',
  // //Master dashboard descriptions  
  // movie_dash_msg: 'List of all movies & their description in Brij.',
  // schedule_dash_msg: 'All movie schedules for each screen in a cinema.',
  // aggregator_dash_msg: 'Third party applications who use Brij for ticket booking and F&B order modules.',
  // recipe_dash_msg: 'A list of ingredients with required quantity to prepare a dish & sell it.',
  // bookingsource_dash_msg: 'Tickets can be booked online or at POS or at KIOSK or third party apps these are Booking Sources',
  // designation_dash_msg: 'All users designation in Brij. Eg: Accounts Manager, Cinema Manager etc.',
  // charge_dash_msg: 'The additions or deductions that is applicable on the ticket amount like Tax, extra fee etc',
  // rawmaterial_dash_msg: 'List of all raw materials for Food and Beverages.',
  // country_dash_msg: 'List of all countries in Brij',
  // city_dash_msg: 'List of all cities in a particular state/province added to Brij',
  // crew_dash_msg: 'A group of people who work on a movie eg: Director, Producer, Actor, Actress etc',
  // currency_dash_msg: 'List of all currencies in Brij',
  // denomination_dash_msg: 'The currency denomination available for the currency selected in Brij.',
  // distributor_dash_msg: 'A distributor is responsible for the marketing of a film & they get a share of revenue in tickets sold',
  // feature_dash_msg: 'All CRUD features in a module like add, edit, view, update etc.',
  // fb_price_config_dash_msg: 'Min & Max price is set for each item to be sold in a cinema.',
  // fnb_price_dash_msg: 'Calculating the selling price for an item in a cinema.',
  // food_category_dash_msg: 'It is a collection of food items that share similar property like Beverage, hot beverage, cold beverage etc',
  // movie_category_dash_msg: 'The movie classified as when it is screened like Now showing, Next change, Coming soon etc',
  // gender_dash_msg: 'Gender list in Brij',
  // genre_dash_msg: 'A style or category of movie. Eg: Drama, Thriller, Romantic etc',
  // hardware_dash_msg: 'Machines and other equipment used in cinema for Tickets and F&B. Eg: POS, KIOSK, Printer etc',
  // language_dash_msg: 'List of all languages in the system used for content or movie',
  // marital_dash_msg: 'List of marital status in Brij',
  // module_dash_msg: 'The modules in Brij like movie, scheduling, recipe etc',
  // format_dash_msg: 'Technical definition for a movie. Eg: 2D, 3D etc',
  // payment_dash_msg: 'Different modes to purchase a ticket or pay bill. Eg: Cash, Credit Card, Wallet etc',
  // ticket_price_config_dash_msg: 'Min & Max price is set for seats based on seat type in all screens',
  // ticket_dash_msg: 'The selling price for a ticket derived considering all facts like movie type, show time, show date etc',
  // refund_dash_msg: 'List of reasons to cancel a ticket',
  // production_dash_msg: 'List of all production company for movies',
  // role_dash_msg: 'Access roles for users in Brij. Role defines which user can access what feature in Brij',
  // seat_dash_msg: 'Seat reservations like house seats, Permanent Blocked Seats etc',
  // status_dash_msg: 'The ticket & order status in F&B. Eg: Ticket Booked, Ticket cancelled, F&B Order placed etc',
  // shift_dash_msg: 'Shift is multiple period of time set in a day during which different groups of people perform their duties',
  // signage_dash_msg: 'Signage is a display to communicate show of movie in a cinema',
  // state_dash_msg: 'List of all states/Province in a country',
  // unitofmeasurement_dash_msg: 'Unit of measurement for raw materials. Eg: Kilogram, Litre etc',
  // vendor_dash_msg: 'A company or a person offering raw material for sale to cinema',
  // weekday_dash_msg: 'All days in a week. Let you set the first day of the week',

  manage: 'Manage',
  manager: 'Manager',
  //Menu items
  cinemasettings: 'Cinema Settings',
  backupsettings: 'Backup Settings',
  chargetypes: 'Charge Types',
  fnbconfig: 'F&B Configurations',
  fnbprice: 'F&B Price',
  foodcategories: 'Food Categories',
  martialstatus: 'Marital Status',
  movieformat: 'Movie Format',
  paymentmode: 'Payment Mode',
  ticketpriceconfiguration: 'Ticket Price Configuration',
  ticketprice: 'Ticket Price',
  refundreason: 'Refund Reasons',
  seatreservationtype: 'Seat Reservation Type',
  moviecategory: 'Movie Category',
  signagetype: 'Signage Type',
  rawmaterialsCategories: 'Raw Material Categories',
  rawmaterialcategories: 'Raw Material Categories',

  //Master dashboard descriptions  
  movie_dash_msg: 'List of all movies & their description in Brij.',
  schedule_dash_msg: 'All movie schedules for each screen in a cinema.',
  aggregator_dash_msg: 'Third party applications who use Brij for ticket booking and F&B order modules.',
  recipe_dash_msg: 'A list of ingredients with required quantity to prepare a dish & sell it.',
  bookingsource_dash_msg: 'Tickets can be booked online or at POS or at KIOSK or third party apps these are Booking Sources',
  designation_dash_msg: 'All users designation in Brij. Eg: Accounts Manager, Cinema Manager etc.',
  charge_dash_msg: 'The additions or deductions that is applicable on the ticket amount like Tax, extra fee etc',
  rawmaterial_dash_msg: 'List of all raw materials for Food and Beverages.',
  country_dash_msg: 'List of all countries in Brij',
  city_dash_msg: 'List of all cities in a particular state/province added to Brij',
  crew_dash_msg: 'A group of people who work on a movie eg: Director, Producer, Actor, Actress etc',
  currency_dash_msg: 'List of all currencies in Brij',
  denomination_dash_msg: 'The currency denomination available for the currency selected in Brij.',
  distributor_dash_msg: 'A distributor is responsible for the marketing of a film & they get a share of revenue in tickets sold',
  feature_dash_msg: 'All CRUD features in a module like add, edit, view, update etc.',
  fb_price_config_dash_msg: 'Min & Max price is set for each item to be sold in a cinema.',
  fnb_price_dash_msg: 'Calculating the selling price for an item in a cinema.',
  food_category_dash_msg: 'It is a collection of food items that share similar property like Beverage, hot beverage, cold beverage etc',
  movie_category_dash_msg: 'The movie classified as when it is screened like Now showing, Next change, Coming soon etc',
  gender_dash_msg: 'Gender list in Brij',
  genre_dash_msg: 'A style or category of movie. Eg: Drama, Thriller, Romantic etc',
  hardware_dash_msg: 'Machines and other equipment used in cinema for Tickets and F&B. Eg: POS, KIOSK, Printer etc',
  language_dash_msg: 'List of all languages in the system used for content or movie',
  marital_dash_msg: 'List of marital status in Brij',
  module_dash_msg: 'The modules in Brij like movie, scheduling, recipe etc',
  format_dash_msg: 'Technical definition for a movie. Eg: 2D, 3D etc',
  payment_dash_msg: 'Different modes to purchase a ticket or pay bill. Eg: Cash, Credit Card, Wallet etc',
  ticket_price_config_dash_msg: 'Min & Max price is set for seats based on seat type in all screens',
  ticket_dash_msg: 'The selling price for a ticket derived considering all facts like movie type, show time, show date etc',
  refund_dash_msg: 'List of reasons to cancel a ticket',
  production_dash_msg: 'List of all production company for movies',
  role_dash_msg: 'Access roles for users in Brij. Role defines which user can access what feature in Brij',
  seat_dash_msg: 'Seat reservations like house seats, Permanent Blocked Seats etc',
  status_dash_msg: 'The ticket & order status in F&B. Eg: Ticket Booked, Ticket cancelled, F&B Order placed etc',
  shift_dash_msg: 'Shift is multiple period of time set in a day during which different groups of people perform their duties',
  signage_dash_msg: 'Signage is a display board to show the information about movie or Food and Beverages in Cinema',
  state_dash_msg: 'List of all states/Province in a country',
  unitofmeasurement_dash_msg: 'Unit of measurement for raw materials. Eg: Kilogram, Litre etc',
  vendor_dash_msg: 'A company or a person offering raw material for sale to cinema',
  weekday_dash_msg: 'All days in a week. Let you set the first day of the week',
  cinemasettings_dash_msg: 'List of crew in movies eg: Director, Actors etc.',
  chargetypes_dash_msg: 'List of charge types for Brij. Eg: VAT, Entertainment Tax etc.',
  martialstatus_dash_msg: 'manage marital status',
  refunds_dash_msg: 'Ticket cancellation or Order Cancellation reasons which leads to refund.',
  managefearture_msg: 'Manage all Designation in your',
  cinemauser: 'Manage All User',
  cinemasscreens: 'Administrar todas las pantallas',
  delete: 'Delete',
  cinemahardware_dash_msg: 'Manage all Hardware',
  cinemalist_dash_msg: 'Manage cinemas',
  roleaccess_dash_msg: 'Manage All your Roles Acces',
  imagesetting_dash_msg: 'Pre defined image size that will be uploaded in the system',
  org_dash_msg: 'Organization details',
  department_msg: 'List of all Departments in Brij',
  promotionsandoffer_dashmsg: 'Manage all Promotions & Offers',


  // TOOLTIP  NEW ADDED BY AKASH
  crew_tooltip: 'Name of crew eg: Actor, Director',
  genre_tooltip: 'A particular type or variety that a movie belongs to eg: Thriller, Romantic etc',
  country_tooltip: 'Name of the country',
  country_code_tooltip: 'Short form for country name',
  country_iso_tooltip: 'International Organization for Standardization (ISO) defined code for the name of countries',
  city_tooltip: 'Name of the city',
  distributor_name_tooltip: 'Company Name of the distributor',
  sistercompany_tooltip: 'Sister Company name if exists',
  contactno_tooltip: 'Contact number of the distributor for communication',
  emailid_tooltip: 'Email ID of distributor for communication',
  tinnumber_tooltip: 'Company registered number',
  taxnumber_tooltip: 'Tax registration number of distributor',
  distributor_address: 'When the raw material reaches a particular quantity the admin has to be alerted of the same so that he can place order the raw material',
  distributor_cityname: 'City name of the distributor',
  language_tooltip: 'Language for content or movie language.',
  movie_format_tooltip: 'The visual dimension of movie',
  adddesignations_tooltip: 'Name of designation eg: Cinema Manager, Accountant',
  searchmovie_tooltip: 'Search Movie',
  aggregator_tooltip: 'Name of the aggregator',
  contract_start_date_tooltip: 'The date on which contract was enabled in Brij',
  contract_end_date_tooltip: 'The date when the contract will get over',
  apiid_tooltip: 'The unique id assigned to aggregator to access Brij api',
  authentcation_code_tooltip: 'The authentication code to provide authentication to access Brij api',
  username_tooltip: 'The username to access Brij api',
  aggregator_password_tooltip: 'The password to access Brij api',
  recipe_name_tooltip: 'Name of the recipe that will be sold at concession',
  ingredient_tooltip: 'Ingredients/raw materials required to prepare recipe.',
  recipe_quantity_tooltip: 'Quantity of raw material required to prepare recipe.',
  recipe_uom_tooltip: 'Unit of measurement of raw material required to prepare recipe.',
  bookingdiscount_noof_tooltip: 'The source from where the ticket is booked eg: Online, KIOSK etc.',
  bookingdiscount_discount_tooltip: 'The source from where the ticket is booked eg: Online, KIOSK etc',
  chargetypes_tooltip: 'Name of the charge type eg: Value Added Tax, Service Tax, Entertainment Tax',
  chargetype_code_tooltip: 'Short form of Charge Type if any eg: VAT, ST, Entertainment Tax',
  currency_tooltip: 'Name of the currency',
  currencycode_tooltip: 'The three-letter alphabetic ISO currency codes that represent the various currencies used throughout the world',
  currency_country_tooltip: 'Country to which the currency belong to',
  isweekof_tooltip: 'Title cannot be empty',
  vendorname_tooltip: 'Name of the vendor',
  vendorsaddress_tooltip: 'When the raw material reaches a particular quantity the admin has to be alerted of the same so that he can place order the raw material',
  status_tooltip: 'Status name for each stage of booking',
  statuscode_tooltip: 'Short form for Status name',
  shiftname_tooltop: 'Shift name eg: Morning Shift',
  shift_workinghour_tooltip: 'No of working hours in a shift',
  shift_starttime_tooltip: 'Start time of the shift',
  shift_endtime_tooltip: 'End time of the shift',
  seatreservationtype_tooltip: 'Name of different seat types',
  role_tooltip: 'Name of the Role',
  productioncompany_tooltip: 'Name of the production company of movie',
  paymentmode_tooltip: 'Mode of payment to buy ticket or order Food & Beverages eg: Cash, Credit Card, Wallet etc',
  hardware_tooltip: 'The machines installed in cinema to run a cinema eg: POS, KIOSK, Printer etc',
  foodcategories_tooltip: 'Food category name eg: Snacks, Beverages etc. These categories can have sub category also.',
  feature_tooltip: 'Each module has different features like Add User, Edit User, View User Details etc.',
  backuplocationbrowse_tooltip: 'Select the folder where you want to store all backups for Brij',
  denominationvalue_tooltip: 'The value of denomination eg: 100, 200 etc',
  denominationcurreny_tooltip: 'The currency for which denomination will be saved',
  denominationcountry_tooltip: 'Select country name for denominations',
  tpcfromdate_tooltip: 'Date from which the price will be applicable',
  tpctodate: 'Price will be applicable till \'To Date\'',
  hardware_selectname_tooltip: 'concession level in cinema',
  hardware_selecthardware_tooltip: 'Tooltip',
  hardware_username_tooltip: 'Tooltip',
  hardware_password_tooltip: 'Tooltip',
  hardware_uniquecode_tooltip: 'Tooltip',
  hardware_name_tooltip: 'Tooltip',
  hardware_connectionport_tooltip: 'Tooltip',
  hardware_authenticationcode_tooltip: 'Tooltip',
  hardware_selectkey_tooltip: 'Tooltip',
  booking_source_tooltip: 'Tooltip',
  bookingusercategory_tooltip: 'Category of user for whom seat is booked. Eg: Students, Senior Citizen, Armed Force personel etc',
  bookingusercategory: 'Booking User Category | Booking User Categories',
  bookingusercategory_dash_msg: 'The user category for whom the ticket is booked eg: Students, Senior Citizens, Armed Force Personel etc.',
  chargetypecode: 'Charge Type Code | Charge Type Code',
  imagesettingname_tooltip: 'Name the image setting with briefing',
  rating_dash_msg: '',
  rating_tooltip: 'Rate a movies suirability for certain audiences based on its content Eg: PG 13+, PG 18 etc',
  iscinemalevel: 'Cinema Role',
  boxoffice: 'Box Office',
  organization: 'Organization | Organizations',
  logourl: 'Logo URL',
  setupdate: 'Setup Date',
  launchdate: 'Launch Date',
  trial: 'Trial Version',
  location: 'Location',
  screenname: 'Screen Name',
  noofscreens: 'No of Screens',
  routes: 'Routes/Path',
  noofrows: 'No of Rows',
  noofcols: 'No of Cols',
  noofseats: 'No of Seats',
  seatlayout: 'Seat Layout',
  seatreservetype: 'Seat Reserve Type',
  seattype: 'Seat Type',
  seatname: 'Seat Name',
  rownumber: 'Row Number',
  columnnumber: 'Column Number',
  ratecard: 'Rate Card',
  inclusivoftax: 'Inclusive of Tax?',
  overandabove: 'Is over and above',
  sharewithdistributor: 'Is Shareable with Distributor',
  taxable: 'Is Taxable',
  chargevalue: 'Charge Value',
  isdeduction: 'Is Deduction',
  booking_source_tooltip: 'Ticket booking source eg: POS, KIOSK, Online etc',
  addDistributor_tooltip: 'Name of the Distribution company',
  addDistributorsiscomp_tooltip: 'Name of sister company if exists',
  addDistributorcontactno_tooltip: 'Mobile or Landline number to contact',
  addDistributoremailId_tooltip: 'Email id of for communication',
  addDistributorTin_tooltip: 'Tin registered number',
  addDistributorTax_tooltip: 'Tax information number',
  distributorCountry_tooltip: 'The country where distributor is located',
  distributorState_tooltip: 'The state or province where the distributor is located',
  distributorCity_tooltip: 'The city where the distributor is located',
  movieFormatname: 'Video formality for movie Eg: 2D, 3D etc',
  refundReasons_tooltip: 'Reason why a refund needs to be initiated Eg: show cancelled',
  moviecategory_tooltip: 'The movie show status, whether it is coming soon or now showing etc.',
  state_tooltip: 'Name of the state or province',
  statecode_tooltip: 'Short form or code ( if exists ) for the state or province',
  unitofmeasurement_tooltip: 'Definite magnitude of a quantity eg: kilogram',
  uom_parent_tooltip: 'The higher magnitude of a quantity. it is not mandatory',
  uom_description: 'Explanation for the unit of measurement',
  movietitle_tooltip: 'Name of the movie',
  movieSelectCountry: 'The production country of the movie',
  movieproductioncompany_tooltip: 'The company or companies who produced the movie',
  movieselectgenre_tooltip: 'Motion picture category based on similarities either in the narrative elements or in emotional response eg: comedy documentry',
  movieselectlanguage_tooltip: 'The spoken language of the movie',
  movietrailer_tooltip: 'The link for the trailer of the movie',
  movieuti_tooltip: 'The image to be shown to represent movie size 197X295, max 1mb',
  movieselectdistributor_tooltip: 'The company which distributes the movie in given area',
  movieselectcountry_tooltip: 'The country where the distributor is distributing the movie',
  movieselectstates_tooltip: 'The states where the distributor is distributing the movie',
  moviecity_tooltip: 'The city where the distributor is distributing the movie',
  moviefromdate_tooltip: 'Distribution start date',
  movietodate_tooltip: 'Distribution end date',
  movieselectcountry_tooltip: 'Country based details select country and enter other details accordingly',
  movieReleaseDate_tooltip: 'The date of releasing the movie in selected country',
  movieRuntime_tooltip: 'The total time of the movie in mins',
  movieContentTitle_tooltip: 'The movie title is selected language',
  movieDescription_tooltip: 'The movie discription for the selected language',
  movieSelectCrew_tooltip: 'People who are involved on making a movie eg: producer, director',
  movieNameOfThePerson_tooltip: 'Name of the crew selected',
  movieOrder_tooltip: 'Order in which the crew member should appear',
  cinemaSettingsName_tooltip: 'Name of the cinema with location name to be precise',
  cinemaSettingsRegistration_tooltip: 'The government registration number to start a cinema/unit',
  cinemaSettingsLicenseNo_tooltip: 'The license number of the cinema/unit',
  cinemaSettingsEmail_tooltip: 'Email address for communication',
  cinemaSettingContactNo_tooltip: 'The contact number for communication',
  cinemaSettingsAddress_tooltip: 'The location of the cinema/unit',
  cinemaSettingsCities_tooltip: 'The city where the cinema / unit is located',
  cinemaSettingStates_tooltip: 'The state or province where the cinema/unit is located',
  cinemaSettingsCountry_tooltip: 'The country where the cinema/unit is located',
  cinemaSettingsPincode_tooltip: 'The pincode of the location where the cinema / unit is located',
  cinemaSettingsLatitude_tooltip: 'The geographic co-ordinate that specifies the north-south position of earth',
  cinemaSettingsLongitude_tooltip: 'The geographic co-ordinate that specifies the east-west position of earth',
  cinemaSettingsNoofScreens_tooltip: 'The number of screens in cinema',
  cinemaSettingsOperation_tooltip: 'The start time of operation in cinema',
  cinemaconcessionlevel_tooltip: "The Cinema Concession Level.",
  cinemaSettingsCleaningTime_tooltip: 'The cleaning time in mins for the cinema after each movie',
  cinemaSettingsCutofTime_tooltip: 'The Time in mins after start of the movie after which booking is not allowed',
  cinemaSettingsAdvertisementTime_tooltip: 'The extra time before the start of the movie advertisements / Trailer',
  cinemaSettingsSeatReleaseTime_tooltip: 'The time in mins before which the reserved seat needs to be released for booking',
  backupSettingCinemaCountry_tooltip: 'Select cinema for backup of data',
  backupSettingBackupLocationBrowse_tooltip: 'Path for backup storage',
  backupSettingDaily_tooltip: 'Daily which time backup to be taken',
  backupSettingWeekly_tooltip: 'On which day of the week backup should be taken',
  backupSettingFortnight_tooltip: 'Two dates in a month with 15 days gap when backup to be taken',
  backupSettingMonthly_tooltip: 'Any date of the month when backup to be taken',
  backupSettingBackupToKeep_tooltip: 'Selected the backup folder to be restored',
  citiesCityName_tooltip: 'Name of the city',
  citiesLatitude_tooltip: 'Point 11',
  citiesLongitude_tooltip: 'Point 12',
  citiesCountry_tooltip: 'Name of the country where city belongs to',
  citiesState_tooltip: 'Name of the state where state belongs to',
  imageSettingHeight_tooltip: 'Height of the image',
  imageSettingWidth_tooltip: 'Width of the image',
  imageSettingRatio_tooltip: 'Ratio of image',
  organizationName_tooltip: 'Name of the organization',
  organizationLogourl_tooltip: 'Browse logo url size and dimention',
  organizationAddress_tooltip: 'The address of head office of the organization',
  organizationPincode_tooltip: 'Postal code or pincode of the organization',
  organizationCountry_tooltip: 'Country where the organization is located',
  organizationState_tooltip: 'Name of the state or province',
  organizationCity_tooltip: 'City where the organization is located',
  organizationContactNumber_tooltip: 'Contact number for communication',
  organizationSetupDate_tooltip: 'Date when the application (BRIJ) was setup',
  organizationLaunchDate_tooltip: 'Date when the software was launched',
  signage_type: 'The display screen where the information of movie schedules is displayed',
  weekdays_tooltip: 'All weekdays only set the start day of the week',
  vendorsContactNumber_tooltip: 'Contact number of vendor of communication',
  vendorsEmailId_toolip: 'The email id for communication',
  vendorsTinNumber: 'Tax identification number if exists or GST number',
  vendorsAddress_tooltip: 'The address of the vendor',
  vendorsCountry_tooltip: 'The country of vendor',
  vendorStates_tooltip: 'State / Province of the vendor',
  vendorCity_tooltip: 'City where the vendor is located',
  select_start_date: 'select start date',
  select_end_date: 'select end date',
  cinemas_code: 'Enter cinema code',
  cinema_Organization: 'Select organization',
  us_start_date: 'Start date for a shift',
  us_end_date: 'End date for a shift',
  cinemas_code: 'Enter cinema code',
  cinema_Organization: 'Select organization',
  us_start_date: 'Start date for a shift',
  us_end_date: 'End date for a shift',
  deptname: 'Enter a Department Name',
  gendername: 'Enter a Gender',
  maritalname: 'Enter Marital Status',

  cinemas_CinemaSettings: 'Name of cinema or unit. A cinema is a multiplex at a particular location',
  cinema_Location: 'Google location of the cinema',
  cinema_Address: 'Enter cinema address',
  cinema_Pincode: 'Postal code or zip code of the location where cinema is located',
  cinema_ContactNumber: 'Contact number of cinema manager for any communication',
  cinema_EmailId: 'Email id for any electronic communication',
  cinema_NoOfScreens: 'No. of screens a cinema has',
  cinema_latitude: 'Latitude of the cinema location',
  cinema_longitude: 'Longitude of the cinema location',
  cinema_Cutoftime: 'The time in minutes after the start of the show when looking will be closed',
  cinema_license: 'The government registered license number for cinema',
  cinema_RegistrationNo: 'The government registered registration number for cinema',
  cinema_OperationStarttime: 'The time when operation start at cinema',
  cinema_OperationEndtime: 'The end time when operation start at cinema',
  screen_ScreenName: 'Name of the screens in cinema Eg: Screen 1',
  screen_NoOfSeats: 'Total no. of seats in a particular screens',
  screen_NoofCols: 'No of seating columns in a screen to create a grid for seat layout',
  screen_NoofRows: 'No. of seating rows in a screen. to create a grid for seat layout',
  screen_Cinema: 'List of cinema. Select a cinema to which the screen belongs to',
  screen_Format: 'The video format supported by the screen Eg: 2D, 3D, 4D etc.',
  seatType_SelectCinemas: 'Select Cinema/Unit name to add seat type',
  seatType_SelectScreen: 'Select screen to add seat type',
  seatType_SeatsTypes: 'Enter seat type name eg: Gold, Silver etc',
  chargeSettings_selectCinema: 'Select cinema for charge settings',
  chargeSettings_ChargeSettingsName: 'Refrence name for charge setting',
  chargeSettings_ChargeTypes: 'Different charge types for applicable for ticket',
  chargeSettings_FromDate: 'Charges applicable from which date',
  chargeSettings_ToDate: 'Charge applicable till which date. Non mandatory',
  chargeSetting_chargeValue: 'The value of the change Eg: 8%, $100 etc.',
  rateCard_Cinema: 'Select cinema for rate card',
  rateCard_ChargeSettingName: 'Select reference name for charge settings',
  rateCard_RateCardName: 'Name of the rate card',
  rateCard_Amount: 'The amount for which charges will be calculated',
  ticketPriceConfiguration_cinemas: 'Select cinema to configure ticket price',
  ticketPriceConfiguration_Movie: 'Select movie for which the ticket price has to be done',
  ticketPriceConfiguration_Screens: 'Select screen on which movie will be screened',
  ticketPriceConfiguration_FromDate: 'The ticket price applicable from which date',
  ticketPriceConfiguration_ToDate: 'Ticket price applicable till which date',
  user_NameTooltip: 'Name of the employee',
  user_MiddleName: 'Middle name of the employee',
  user_LastName: 'Last name of the employee',
  user_Gender: 'The gender of the employee',
  user_DateOfBirth: 'Date of birth of the employee',
  user_DateOfJoining: 'Date of joining',
  user_Address: 'Residencial address of the employee',
  user_Country: 'Country where employee resides',
  user_State: 'State where the employee is from',
  user_Cities: 'City of employee residence',
  user_ZipCode: 'ZipCode / Postal code or PIN code of the location',
  user_EmailId: 'Email id of employee for communication',
  user_Phone: 'Contact no. of employee',
  user_mobile: 'Contact no. of employee',
  user_Username: 'Username to login to the system',
  user_Password: 'Password for login to the system',
  user_Roles: 'Access role for employee in brij',
  user_Designation: 'Designation of employee',
  user_Cinema: 'Cinema to which employee belongs to',
  user_Employee: 'Unique employee code of employee id in organization',
  floatAmount_User: 'Select the employee or POS user to whom float amount is given',
  floatAmount_Shift: 'Select the shift of the employee for the current float amount',
  floatAmount_PosOperation: 'Select the POS machine assigned to the employee',
  floatAmount_Amount: 'Enter the total amount given to the employee as float amount',
  refund_Cinema: 'Select the cinema name',
  refund_Screens: 'Select Screens',
  refund_Movie: 'Select movie',
  refund_showTime: 'Select show time',
  refund_RefundReason: 'Refund reason which refund is to be done',
  hardwareSettings_SelectCinema: 'Select the cinema where hardware is setup',
  hardwareSettings_Username: 'Username for the system if any',
  hardwareSettings_Password: 'Password for the system if any',
  hardwareSettings_SelectedHardwareType: 'Select the type of hardware',
  hardwareSettings_HardwareUniqueCode: 'Enter the unique code / Mac address / CPU ID of hardware',
  hardwareSettings_HardwareName: 'Name of the hardware',
  hardwareSettings_ConnectionPort: 'Connection port of the hardware',
  hardwareSettings_installBy: 'Who installed the hardware',
  rawmaterial_tooltip: 'The basic material from which F&B recipes are made',
  rawmaterial_Code: 'Unique Code',
  quantityalert_tooltip: 'The threshold limit at which the system will notify manager to order raw material',
  description_tooltip: 'Briefing the raw material',
  rawMaterial_unitofmeasurement: 'Definite magnitude of a quantity defined & adopted by convention',
  vendor_TinNumber: 'Tax information number of vendor',
  vendor_TaxNumber: 'Tax number or PAN number of vendor',
  vendor_TaxType: 'Type of tax',
  vendor_address: 'Address of vendor',
  vendor_location: 'Google map location of vendor',
  vendorMapping_selectCinema: 'Cinema name to link vendor who supplies raw material',
  vendorMapping_selectVendor: 'Name of the vendor who supplies raw material',
  vendorMapping_selectRawMaterial: 'Raw material supplied by the selected vendor',
  vendorMapping_Quantity: 'Quantity of raw material to set the cost',
  vendorMapping_SelectUnitOfMeasurement: 'UOM for quantity selected to set cost',
  vendorMapping_rate: 'Cost at which raw material will be supplied',
  includesegg: 'Includes Egg',
  cinemaCurrency_tooltip: 'Currency used for transactions in cinema',
  cinemaCountry_tooltip: 'The Country where cinema is located',
  cinemaState_tooltip: 'State where the cinema is located',
  cinemaCity_tooltip: 'City where the cinema is located',
  seatTypeSeatno_tooltip: 'Total number of seats in cinema',
  movieselectcinema_tooltip: 'Select cinema where distributor is having share for the movie',
  purchaseOrder_selectCinema: 'Select cinema for which PO is created',
  purchaseOrder_selectVendor: 'Select the vendor who will provide raw materials',
  purchaseOrder_RawMaterials: 'The raw materials used in cinema for concession or office',
  purchaseOrder_Quantity: 'The amount of raw material required',
  purchaseOrder_selectUOM: 'The unit of measurement for raw material',
  purchaseOrder_TotalAmount: 'Gross Amount',
  purchaseOrder_PurchaseOrderDate: 'Date of purchase order',
  recipe_code_tooltip: 'Unique code for recipe',
  fnbitems_tooltip: 'Name of the recipe',
  fnbitems_itemCode_tooltip: 'Unique code for an item',
  fnbitems_foodcategory_tooltip: 'The category to which the item belongs to',
  foodorder_tooltip: 'The order in which categories will appear in Pos',
  itemcostprice_fnb: 'Select a F&B item',
  itemcostprice_costPrice: 'Cost price for the item',
  ratecard_cinema: 'Select cinema to which the rate card os linked',
  ratecard_chargesettingname: 'Name of the charge setting',
  ratecard_ratecardname: 'Name the rate card to identify early',
  ratecard_Amount: 'Price for a seat',
  fnbpricing_Cinemaname: 'Select cinema to add F&B price',
  fnbpricing_Fnbitem: 'The F&B item to be sold',
  fnbpricing_ratecard: 'The rate card defines the amount for the F&B item',
  fnbpricing_netAmount: 'Net amount for the item',
  fnbpricing_totalamount: 'Gross amount for the item',
  fnbpricing_fromdate: 'The date from which the amount in valid',
  fnbpricing_todate: 'The data to which the amount is valid',
  fnbwastage_selectcinema: 'Select cinema to recard wastage',
  fnbwastage_selectconcession: 'Select concession level',
  fnbwastage_rawmaterials: 'Select the raw materials',
  remark: "Remark",
  fnbwastage_selectunitofmeasurements: 'Select unit of measurements',
  fnbwastage_selectquantity: 'Select quantity',
  fnbwastage_accountableuser: 'Wastage done by the user',
  fnbwastage_approvedby: 'The manager who needs to approved',
  fnbwastage_reasons: 'Reason why the wastage happend',
  fnbwastage_fireamount: 'The amount that needs to be paid by the user',
  fnbwastage_fromdate: 'Date of wastage recorded',


  // MENU

  exhibitor_management: "Exhibitor Management",
  user_management: 'User Management',
  department: 'Department',
  seatType: 'Seat Type',
  seatLayout: 'Seat Layout',
  chargesettings: 'Charge Settings',
  floatAmount: 'Float Amount',
  hardwareSettings: 'Hardware Settings',
  shareType: 'Share Type',
  cinemaMasters: 'Cinema Masters',
  vendorMapping: 'Vendor Mapping',
  purchaseOrder: 'Purchase Order',
  foodCategories: 'Food Categories',
  fbItems: 'F&B items',
  store: 'Store',
  stock: 'Stock',
  concessionStock: 'Concession Stock',
  rateCard: 'Rate Card',
  fbPricing: 'F&B Pricing',
  wastemanagement: 'Waste Management',
  roleAccess: 'Role Access',
  foodNbev: 'Food & Beverages',

  //store
  storeManagement: 'Store Management',
  storemanagement: 'Store Management',
  sm_store_name: 'Store Name',
  sm_store_slug: 'Store Slug',
  sm_order: 'Order',
  sm_store_name_tooltip: 'Store Name to be visible in system',
  sm_store_slug_tooltip: 'Identifier for a particular Store used for technical settings',
  sm_order_tooltip: 'The order in which multiple store level will be displayed'
}
