const state = {
    numberOfCinemas: "",
    numberOfScreens: "",
    cinemas: [],
    concessionLevelValue: 1,
}

const mutations = {
    SET_NO_OF_CINEMAS: (state, payload) => {
        state.numberOfCinemas = payload
    },
    SET_NO_OF_SCREENS: (state, payload) => {
        state.numberOfScreens = payload
    },
    SET_CINEMA_ARRAY: (state, payload) => {
        state.cinemas = payload
    },
    SET_CONCESSION_LEVEL: (state, payload) => {
        state.concessionLevelValue = payload
    }
}

const actions = {
    setNumberOfCinemas({ commit }, payload) {
        commit('SET_NO_OF_CINEMAS', payload)
    },
    setNumberOfScreens({ commit }, payload) {
        commit('SET_NO_OF_SCREENS', payload)
    },
    setCinemaArray({ commit }, payload) {
        commit('SET_CINEMA_ARRAY', payload)
    },
    setConcessionLevelValue({ commit }, payload) {
        commit('SET_CONCESSION_LEVEL', payload)
    }
}

const getters = {
    NO_OF_CINEMAS: state => state.numberOfCinemas,
    NO_OF_SCREENS: state => state.numberOfScreens,
    CINEMAS: state => state.cinemas,
    CONCESSION_LEVELS: state => state.concessionLevelValue,
}

export default {
    state,
    mutations,
    actions,
    getters
}
