import axios from 'axios';
import Vue from 'vue';
import { BASEURL } from "@/config/index";
// import router from '../router';

export default () => {
  const instance = axios.create({
    baseURL: BASEURL
  })
  let JWT = window.localStorage.getItem("JWT_TOKEN");
  if (JWT) {
    instance.defaults.headers.common['Authorization'] = JWT
  }
  // instance.defaults.headers.common['Authorization'] = Vue.cookie.get('STRHTID')
  // instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
  instance.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
  }, function (error) {
    // Do something with response error
    if (error.response.status == 403) {
      // vmVue.$router.push({
      //   path: "/access-denied"
      // });
      // Vue.toasted.show("Your authorization token is invalid or expired");
    } else if (error.response.status == 401) {
      localStorage.clear()
      Vue.cookie.delete('BRHSID');
      vmVue.$router.push({
        path: '/' // '/go-to-login'
      });
    } else {
      return Promise.reject(error);
    }
  });
  return instance;
}
