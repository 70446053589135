import moment from "moment";
import Vue from 'vue';
import PosFnBService from '@/services/Pos_Fnb_Service';
import PosService from '@/services/PosService';
import MasterService from '@/services/MasterService';
import {
    authenticationService
} from "@/services/AuthenticationService";
import {
    router
} from '@/router';
import store from '@/store';


function initialState() {
    return {
        // Order Details
        phoneNumber: null,
        printText: 'Print',
        // Order Details End

        // Cinema Details
        CinemaDetails: [],
        // Cinema Details End

        // 
        OrderId: null,
        userDetails: null,
        stockDeduction: null,
        stockDeductionConcession: null,
        // 

        // 
        ChangeAmount: 0,
        cashdata: [],
        cashSelectedList: [],
        cashInputValue: 0,
        // 
        FnBItemsModule: true,
        showFnBOrderDetails: false,
        fnbItemList: [],
        fnbCategories: [],
        fnbMoreCategories: [],
        fnbSubCategories: [],

        // Selected
        selectedFnbItemList: [],
        selectedFnBItemsTotalPrice: 0,
        //
        fnbPayload: {
            cinema_id: null,
            // Require on condition
            search: '',
            isVeg: 'N',
            isEgg: 'N',
            category: null,
            subCategory: null,
            isCombo: false,
        },
        currency: {
            curr_code: null,
            curr_name: null,
            curr_id: null,
        },

        // FnB item (Tax) Additional setting Price.
        additionalTaxList: [],
        totalAdditionalTaxes: 0,

        // Final Checkout
        paymentTypes: {
            cashPayments: true,
            creditNDebit: false,
            cashless: false,
            splitPayments: false,
            couponNPromotions: false,
            vouchers: false,
            loyaltyCard: false
        },




        paging: {},


        // Only For Object Use:
        itemAdditionObject: {
            charge_type_id: null,
            ct_name: null,
            ct_id: null,
            ct_code: null,
            fnb_price_id: null,
            fpas_charge_value: null,
            fpas_is_percentage: null,
            price: null,
            taxPrice: null,
        },
        BoxofficeCalculator: false
    }
};
const state = initialState();


// const state = {
//     // Order Details
//     phoneNumber: null,
//     printText: 'Print',
//     // Order Details End

//     // Cinema Details
//     CinemaDetails: [],
//     // Cinema Details End

//     // 
//     OrderId: null,
//     userDetails: null,
//     stockDeduction: null,
//     stockDeductionConcession: null,
//     // 

//     // 
//     ChangeAmount: 0,
//     cashdata: [],
//     cashSelectedList: [],
//     cashInputValue: 0,
//     // 
//     FnBItemsModule: true,
//     showFnBOrderDetails: false,
//     fnbItemList: [],
//     fnbCategories: [],
//     fnbMoreCategories: [],
//     fnbSubCategories: [],

//     // Selected
//     selectedFnbItemList: [],
//     selectedFnBItemsTotalPrice: 0,
//     //
//     fnbPayload: {
//         cinema_id: null,
//         // Require on condition
//         search: '',
//         isVeg: 'N',
//         isEgg: 'N',
//         category: null,
//         subCategory: null,
//         isCombo: false,
//     },
//     currency: {
//         curr_code: null,
//         curr_name: null,
//         curr_id: null,
//     },

//     // FnB item (Tax) Additional setting Price.
//     additionalTaxList: [],
//     totalAdditionalTaxes: 0,

//     // Final Checkout
//     paymentTypes: {
//         cashPayments: true,
//         creditNDebit: false,
//         cashless: false,
//         splitPayments: false,
//         couponNPromotions: false,
//         vouchers: false,
//         loyaltyCard: false
//     },




//     paging: {},


//     // Only For Object Use:
//     itemAdditionObject: {
//         charge_type_id: null,
//         ct_name: null,
//         ct_id: null,
//         ct_code: null,
//         fnb_price_id: null,
//         fpas_charge_value: null,
//         fpas_is_percentage: null,
//         price: null,
//         taxPrice: null,
//     }
// }

const mutations = {
    CHANGE_TEXT: (state, payload) => {
        state.printText = "Reprint"
    },
    SET_CASH_CALCULATOR: (state, payload) => {
        state.cashdata = [];
        payload.map(x => {
            x["count"] = 0;
            x["value"] = parseInt(x.denomination_value);
            state.cashdata.push(x)
            console.log('x :', x);
            // console.log('state.cashdata :', state.cashdata);
        });
    },
    SET_CHANGE_AMOUT: (state, payload) => {
        state.ChangeAmount = 0;
        // state.ChangeAmount = state.cashInputValue;
        console.log('state.cashSelectedList :', state.cashSelectedList);
        state.cashSelectedList.map(x => {
            state.ChangeAmount += (parseInt(x.value) * parseInt(x.count));
            state.cashInputValue = state.ChangeAmount;
            // console.log('state.cashInputValue SET CHANGE AMOUT :', state.cashInputValue);
        });
    },
    SET_INPUT_AMOUNT: (state, payload) => {
        // console.log('payload :', payload);
        state.cashInputValue = parseFloat(payload);
        // console.log('state.cashInputValue SET INPUT AMOUT:', state.cashInputValue);
    },
    SET_SELECTED_CASH: (state, {
        Cash,
        ...payload
    }) => {
        let listIndex = state.cashSelectedList.findIndex(x => x.denomination_value == Cash.denomination_value);
        if (listIndex > -1) {
            state.cashSelectedList[listIndex].count++
        } else if (listIndex < 0) {
            state.cashSelectedList.push({
                ...Cash,
                count: 1
            })
        }
    },
    SWTICH_FNB_MODULE: (state, {
        name,
        ...payload
    }) => {
        state[name] = !state[name];
    },
    // Tax Cal
    SET_FNB_ADDITIONAL_TAX: (state, {
        listIndex,
        additionalTaxes
    }) => {
        state.selectedFnbItemList[listIndex]['additionalTaxes'] = additionalTaxes
    },
    // Tax Cal End
    // SET CINEMA
    SET_CINEMA_DETAILS: (state, payload) => {
        state.CinemaDetails = payload
    },
    // END
    SET_FNB_ITEMS: (state, payload) => {
        state.fnbItemList = payload.Records;
    },
    SET_FNB_CINEMA: (state, payload) => {
        state.fnbPayload.cinema_id = payload.cinemaId;
        state.userDetails = payload.userDetails;
        state.stockDeduction = payload.stockDeduction;
        state.stockDeductionConcession = payload.stockDeductionConcession;
    },
    SET_CURRENCY_DETAILS: (state, payload) => {
        state.currency = {
            ...state.currency,
            ...payload
        }
    },
    SET_FNB_CATEGORIES: (state, payload) => {
        for (let i = 0; i < payload.Categories.length; i++) {
            const element = payload.Categories[i];
            if (state.fnbCategories.length < 4) {
                state.fnbCategories.push(element)
            } else {
                state.fnbMoreCategories.push(element)
            };
        }
    },
    SET_FNB_SUB_CATEGORIES: (state, payload) => {
        state.fnbSubCategories = payload.Categories;
    },
    SET_FNB_SELECTED_SUB_CATEGORY: (state, payload) => {
        state.fnbPayload.subCategory = payload
    },
    SET_SEARCH_VALUES: (state, payload) => {
        state.fnbPayload.search = payload;
    },
    SET_CATEGORY_VALUES: (state, payload) => {
        state.fnbPayload.category = payload;
    },
    ADD_ON_FNB_ITEMS: (state, payload) => {
        console.log('payload :', payload);
        let obj = {
            quantity: 1,
            ...payload
        }
        let listIndex = state.selectedFnbItemList.findIndex(x => x.fc_id == payload.fc_id && x.fc_parent_id === payload.fc_parent_id);
        if (listIndex > -1) {
            state.selectedFnbItemList[listIndex].quantity++
        } else if (listIndex < 0) {
            state.selectedFnbItemList.push(obj)
        }
    },
    REMOVE_ON_FNB_ITEMS: (state, payload) => {
        if (payload.hasOwnProperty('index') && String(payload.index).length > 0) state.selectedFnbItemList.splice(payload.index, 1);
    },
    INCREASE_FNB_ITEM: (state, payload) => {
        console.log('payload :', payload);
        if (payload.hasOwnProperty('index') && String(payload.index).length > 0) state.selectedFnbItemList[payload.index].quantity++;
    },
    DECREASE_FNB_ITEM: (state, payload) => {
        if (payload.hasOwnProperty('index') && String(payload.index).length > 0 && state.selectedFnbItemList[payload.index].quantity > 0) state.selectedFnbItemList[payload.index].quantity--;
        if (payload.hasOwnProperty('index') && String(payload.index).length > 0 && state.selectedFnbItemList[payload.index].quantity === 0) state.selectedFnbItemList.splice(payload.index, 1);
    },
    CALCULATE_FNB_ITEMS_PRICE: async(state, payload) => {
        state.selectedFnBItemsTotalPrice = 0;
        state.totalAdditionalTaxes = 0;
        if (state.selectedFnbItemList.length === 0) state.additionalTaxList = [];
        let Promises = state.selectedFnbItemList.map((x, index) => {
            state.selectedFnBItemsTotalPrice += (x.fp_total_amount * x.quantity);
            if (x.hasOwnProperty('additionalTaxes') && x.additionalTaxes.length > 0) {
                x.additionalTaxes.map((tax) => {
                    let listIndex = state.additionalTaxList.findIndex(z => z.ct_id == tax.ct_id);
                    state.totalAdditionalTaxes += tax.fpas_charge_value * x.quantity;
                    if (listIndex > -1) {
                        x.fc_id === state.additionalTaxList[listIndex].fc_id ? state.additionalTaxList[listIndex].totalTax = tax.fpas_charge_value * x.quantity : state.additionalTaxList[listIndex].totalTax += tax.fpas_charge_value * x.quantity;
                    } else if (listIndex < 0) {
                        state.additionalTaxList.push({
                            fc_id: x.fc_id,
                            quantity: x.quantity,
                            totalTax: tax.fpas_charge_value,
                            ...tax
                        })
                    };
                    return tax;
                })
            };
            return x
        });
        // await Promise.all(Promises);
        // state.additionalTaxList.map(({ totalTax }) => { state.totalAdditionalTaxes += totalTax });
    },
    SHOW_FNB_DETAILS: (state, payload) => {
        const h = global.vmVue.$createElement
            // Using HTML string
            // const titleVNode = h('div', { class: ['text-center w-100'], domProps: { innerHTML: ''  } })
            // More complex structure
        const messageVNode = h('div', {
            class: ['foobar']
        }, [
            // h('h5', { class: ['text-center border-bottom pb-2'], domProps: { innerHTML: payload.recipe_name } }),
            h('p', {
                class: ['text-center']
            }, [payload.recipe_description])
        ]);
        // We must pass the generated VNodes as arrays
        global.vmVue.$bvModal.msgBoxOk([messageVNode], {
            size: 'sm',
            // title: [titleVNode],
            title: payload.recipe_name,
            // okTitle: 'Cancel',
            buttonSize: 'sm',
            hideHeaderClose: true,
            centered: true,
        })
    },
    IS_VEG_HANDLER: (state, payload) => {
        state.fnbPayload.isVeg = payload
    },
    IS_EGG_HANDLER: (state, payload) => {
        // console.log('Payload : ', payload);
        state.fnbPayload.isEgg = payload
        console.log('state.fnbPayload.isEgg : ', state.fnbPayload.isEgg);
    },
    RESET_FNB: (state, payload) => {
        state.FnBItemsModule = true;
        state.showFnBOrderDetails = false;
        state.printText = 'Print';

        state.fnbSubCategories = [];
        // state.fnbItemList = []
        state.selectedFnbItemList = [];
        state.additionalTaxList = [];
        state.totalAdditionalTaxes = 0;

        // 
        state.OrderId = null;
        state.phoneNumber = null;
        //

        state.selectedFnBItemsTotalPrice = 0;
        let obj = state.fnbPayload;
        state.fnbPayload = {
            ...obj,
            search: '',
            isVeg: 'N',
            isEgg: 'N',
            category: null,
            subCategory: null,
            isCombo: false,
        };
    },
    RESET_SELECTED_CASH: (state, payload) => {
        state.ChangeAmount = 0;
        state.cashInputValue = 0;
        state.cashSelectedList = [];
        if (!state.FnBItemsModule && state.selectedFnbItemList.length == 0) state.FnBItemsModule = !state.FnBItemsModule;

    },

    // 
    CHANGE_PAYMENT_MODE: (state, payload) => {
        Object.keys(state.paymentTypes).map(x => {
            if (x == payload) {
                state.paymentTypes[x] = !state.paymentTypes[x];
            } else {
                state.paymentTypes[x] = false;
            }
        });
    },
    ON_PHONE_NUMBER_HANDLER: (state, payload) => {
        state.phoneNumber = payload
    },

    // 
    ON_SET_TRANSACTION_ID: (state, payload) => {
        state.OrderId = payload.orderCode
    },
    ON_RESET_FNB_STORE_STATE: (state, payload) => {
        // state = initialState();
        const s = initialState()
        Object.keys(s).forEach(key => {
            state[key] = s[key]
        })
    },
    SET_BoxOfficeCalCulator_Flag: (state, payload) => {
        state.BoxofficeCalculator = payload
    }
}

const actions = {
    OnPOSLogin: ({
        commit
    }, payload) => {
        commit('SET_BoxOfficeCalCulator_Flag', payload);
    },
    onResetFnBStoreState: async({
        dispatch,
        commit,
        state
    }, {
        ...payload
    }) => {
        await commit('ON_RESET_FNB_STORE_STATE');
    },
    onSwtichModule: async({
        dispatch,
        commit,
        state
    }, payload) => {
        if (payload.name === 'showFnBOrderDetails' && state[payload.name]) await dispatch('onResetFnBHandler', payload);
        else await commit('SWTICH_FNB_MODULE', payload);
    },
    fetchCurrencyCode: ({
        dispatch,
        commit,
        state
    }, {
        curr_id
    }) => {
        let payload = {
            tableName: "ms_currencies",
            curr_is_active: "Y",
            curr_id
        };
        MasterService.GetActiveList(payload)
            .then(response => {
                const {
                    data
                } = response;
                if (data && data.status && data.Records.length > 0) {
                    console.log('data.Records[0] :', data.Records[0]);
                    commit('SET_CURRENCY_DETAILS', data.Records[0] || {})
                } else {
                    // Show Error Message:
                    console.log("data.message :", data.message);
                }
            })
            .catch(error => {
                // Catch Error:
                console.log("Catch Error on getActiveScreenByCinemaId :", error);
            });
    },
    fetchCinemaDetails: ({
        dispatch,
        commit,
        state
    }, payload) => {
        let body = {
            tableName: "ms_cinemas",
            cine_is_active: "Y",
            cine_id: payload
        };
        MasterService.GetActiveList(body)
            .then(response => {
                const {
                    data
                } = response;
                if (data && data.status && data.Records.length > 0) {
                    commit('SET_CINEMA_DETAILS', data.Records)
                } else {
                    // Show Error Message:
                    Vue.toasted.show(data.message || 'Opps! Something went wrong');
                }
            })
            .catch(error => {
                // Catch Error:
                console.log("Catch Error on Cinema List in FnB Store :", error);
                Vue.toasted.show("Oops! Something went wrong. kindly contact your administrator");
            });
    },
    fetchFnBCategories: ({
        dispatch,
        commit,
        state
    }, payload) => {
        PosFnBService.GetFnBCategories()
            .then((response) => {
                const {
                    data
                } = response;
                if (data && data.status && data.Records) {
                    commit('SET_FNB_CATEGORIES', {
                        Categories: data.Records || []
                    })
                } else {
                    Vue.toasted.show(data.message || 'Opps! Something went wrong');
                }
            }).catch((error) => {
                Vue.toasted.show("Catch Error on FnBStore fetchFnBItems", error);
            });

    },
    fetchFnBSubCategories: ({
        dispatch,
        commit,
        state
    }, {
        parentid,
        ...payload
    }) => {
        if (parentid !== null) {

            PosFnBService.GetFnBSubCategories({
                    parentid
                })
                .then((response) => {
                    const {
                        data
                    } = response;
                    if (data && data.status && data.Records) {
                        // console.log('data :', data);
                        commit('SET_FNB_SUB_CATEGORIES', {
                            Categories: data.Records || []
                        })
                    } else {
                        Vue.toasted.show(data.message || 'Opps! Something went wrong');
                    }
                }).catch((error) => {
                    Vue.toasted.show("Catch Error on FnBStore fetchFnBItems", error);
                });
        } else {
            commit('SET_FNB_SUB_CATEGORIES', {
                Categories: []
            })
        }
    },
    fetchFnBItems: ({
        dispatch,
        commit,
        state
    }, payload) => {
        let body = {
            ...state.fnbPayload,
            category: state.fnbPayload.subCategory && state.fnbPayload.subCategory.length > 0 ? state.fnbPayload.subCategory.toString() : state.fnbPayload.category,
            isVeg: state.fnbPayload.isVeg === 'N' ? '' : state.fnbPayload.isVeg,
            isEgg: state.fnbPayload.isVeg === 'N' ? '' : state.fnbPayload.isVeg === 'Y' && state.fnbPayload.isEgg === 'N' ? '' : state.fnbPayload.isEgg,
        };
        // console.log('FnBList body :', body);

        PosFnBService.GetFnBList(body)
            .then((response) => {
                const {
                    data
                } = response;
                if (data && data.status && data.Records) {
                    commit('SET_FNB_ITEMS', data.Records || [])
                    console.log('SET_FNB_ITEMS', data.Records);
                } else {
                    Vue.toasted.show(data.message || 'Opps! Something went wrong');
                }
            }).catch((error) => {
                Vue.toasted.show("Catch Error on FnBStore fetchFnBItems", error);
            });


    },
    fetchFnBAdditionalPrice: ({
        dispatch,
        commit,
        state
    }, payload) => {
        console.log('payload 123:', payload);
        PosFnBService.GetFnBAdditionalCharges({
                fp_id: payload.fp_id
            })
            .then(async(response) => {
                const {
                    data
                } = response;
                if (data && data.status && data.Records) {
                    let listIndex = state.selectedFnbItemList.findIndex(x => x.fc_id == payload.fc_id && x.fc_parent_id === payload.fc_parent_id);
                    await commit('SET_FNB_ADDITIONAL_TAX', {
                        listIndex,
                        additionalTaxes: data.Records
                    })
                    await commit('CALCULATE_FNB_ITEMS_PRICE', payload)
                } else {
                    Vue.toasted.show(data.message || 'Opps! Something went wrong');
                }
            }).catch((error) => {
                Vue.toasted.show("Catch Error on FnBStore fetchFnBAdditionalPrice", error);
            });
    },
    setOnFnBPayloads: async({
        dispatch,
        commit,
        state
    }, {
        event,
        ...payload
    }) => {
        if (payload.name === 'search') {
            commit('SET_SEARCH_VALUES', event.target.value);
        } else if (payload.name === 'category') {
            await commit('SET_CATEGORY_VALUES', payload.fc_id);
            await dispatch('fetchFnBItems')
            await dispatch('fetchFnBSubCategories', {
                parentid: payload.fc_id
            })
        } else if (payload.name === 'clearsearch') {
            await commit('SET_SEARCH_VALUES', '');
            await dispatch('fetchFnBItems')
        } else if (payload.name === 'isVeg') {
            // console.log('IsVeg Payload :', payload.name);
            await commit('IS_VEG_HANDLER', event)
            await dispatch('fetchFnBItems')
        } else if (payload.name === 'isEgg') {
            // console.log('IsEgg Payload :', payload.name);
            await commit('IS_EGG_HANDLER', event)
            await dispatch('fetchFnBItems')
        } else if (payload.name === 'subCategory') {
            await commit('SET_FNB_SELECTED_SUB_CATEGORY', event)
        }
    },
    onAddFnBList: async({
        dispatch,
        commit,
        state
    }, {
        ...payload
    }) => {
        await commit('ADD_ON_FNB_ITEMS', payload);
        await dispatch('fetchFnBAdditionalPrice', payload)
            // .then(async () => {
            //     await commit('CALCULATE_FNB_ITEMS_PRICE', payload)
            // })
    },
    onRemoveFnBList: async({
        dispatch,
        commit,
        state
    }, {
        ...payload
    }) => {
        await commit('REMOVE_ON_FNB_ITEMS', payload)
        await commit('CALCULATE_FNB_ITEMS_PRICE', payload)
        await commit('RESET_SELECTED_CASH', payload)
    },
    onIncreaseFnBItem: async({
        dispatch,
        commit,
        state
    }, {
        ...payload
    }) => {
        console.log('payload :', payload);
        await commit('INCREASE_FNB_ITEM', payload)
        await commit('CALCULATE_FNB_ITEMS_PRICE', payload)
    },
    onDecreaseFnBItem: async({
        dispatch,
        commit,
        state
    }, {
        ...payload
    }) => {
        await commit('DECREASE_FNB_ITEM', payload);
        await commit('CALCULATE_FNB_ITEMS_PRICE', payload);
        await commit('RESET_SELECTED_CASH', payload)
    },
    SetCinemaId: async({
        dispatch,
        commit,
        state
    }, payload) => {
        await commit('SET_FNB_CINEMA', payload);
    },
    showFnBDetails: async({
        dispatch,
        commit,
        state
    }, payload) => {
        await commit('SHOW_FNB_DETAILS', payload)
    },
    onIsVegChange: async({
        dispatch,
        commit,
        state
    }, {
        event,
        ...payload
    }) => {
        await commit('IS_VEG_HANDLER', event)
        await dispatch('fetchFnBItems')
    },
    onResetFnBHandler: async({
        dispatch,
        commit,
        state
    }, {
        ...payload
    }) => {
        await commit('RESET_FNB', payload);
        await commit('RESET_SELECTED_CASH', payload)
        await dispatch('fetchFnBItems');
    },
    onBackFnBHandler: async({
        dispatch,
        commit,
        state
    }, {
        ...payload
    }) => {
        if (state.BoxofficeCalculator) {
            let userInfo = authenticationService.get("USINFO");
            userInfo["role_name"] = "POS Operator";

            authenticationService.set("USINFO", userInfo);

            router.replace({
                path: '/pos-booking'
            });
            // store.dispatch('onChangeFlag', {
            //     // login: false,
            //     movieDetail: false,
            //     seatLayout: true,
            //     checkout: false
            // })
        }
        // await store.dispatch('MAKE_DATA_EMPTY');
        await dispatch('onResetFnBHandler');

        store.dispatch('MAKE_SEAT_BOOKING_DETAILS_EMPTY');
        // store.dispatch('onCancelSelectedSeatsHandler');
        // store.dispatch('ON_REMOVE_ALL_SELECTED_SEATS');
    },
    onResetFnBCashAmout: async({
        dispatch,
        commit,
        state
    }, {
        ...payload
    }) => {
        await commit('RESET_SELECTED_CASH', payload)
    },
    // onCheckout Final

    fetchCalculatorCash: ({
        dispatch,
        commit,
        state
    }, {
        ...payload
    }) => {
        PosService.getCashDenominations()
            .then(result => {
                const {
                    data
                } = result;
                if (data && data.status && data.Records.length > 0) {
                    commit('SET_CASH_CALCULATOR', data.Records)
                } else {
                    Vue.toasted.show(data.message || 'Please try later');
                }
            })
            .catch(error => {
                Vue.toasted.show(error || "Catch Error on fetchCalculatorCash store FnB");
            });
    },
    addCashHandler: async({
        dispatch,
        commit,
        state
    }, {
        event,
        ...payload
    }) => {
        await commit('SET_SELECTED_CASH', payload);
        await commit('SET_CHANGE_AMOUT', payload)
    },
    changeAmount: ({
        dispatch,
        commit,
        state
    }, {
        ...payload
    }) => {
        // changeAmount
    },
    cashInputChange: async({
        dispatch,
        commit,
        state
    }, payload) => {
        await commit('SET_INPUT_AMOUNT', payload.target.value);
        await commit('SET_CHANGE_AMOUT', payload)
    },
    // On Order Submit
    onSelectPaymentType: async({
        dispatch,
        commit,
        state
    }, payload) => {
        commit('CHANGE_PAYMENT_MODE', payload)
    },
    onBoxOfficeAndFnBOrderSubmit: async({
        dispatch,
        commit,
        state
    }, payload) => {
        let body = {
            // ...state.fnbPayload,
            fnbItemList: state.selectedFnbItemList,
            cinema_id: state.fnbPayload.cinema_id,
            currency_id: state.currency.curr_id,
            ft_net_amount: state.selectedFnBItemsTotalPrice,
            ft_total_amount: state.selectedFnBItemsTotalPrice + state.totalAdditionalTaxes,
            ft_order_status: 3, // 3 is for payment in success, 18 is for payment in Progress
            payment_mode_id: 1, // set id for payment mode // 1 is for cash
            stockDeduction: state.stockDeduction,
            stockDeductionConcession: state.stockDeductionConcession,
        };
        PosFnBService.OnFinalOrderSubmit(body)
            .then(async(response) => {
                const {
                    data
                } = response;
                if (data && data.status) {
                    commit('ON_SET_TRANSACTION_ID', data.Records);
                    // dispatch('GoToRoute', { path: '/pos-fnb-order-details' });
                    dispatch('onSwtichModule', {
                        name: 'showFnBOrderDetails'
                    });
                } else {
                    Vue.toasted.show(data.message || 'Opps! Something went wrong');
                }
            }).catch((error) => {
                Vue.toasted.show("Catch Error on FnBStore fetchFnBAdditionalPrice", error);
            });
    },
    onOrderSubmit: async({
        dispatch,
        commit,
        state
    }, payload) => {
        let body = {
            // ...state.fnbPayload,
            fnbItemList: state.selectedFnbItemList,
            cinema_id: state.fnbPayload.cinema_id,
            currency_id: state.currency.curr_id,
            ft_net_amount: state.selectedFnBItemsTotalPrice,
            ft_total_amount: state.selectedFnBItemsTotalPrice + state.totalAdditionalTaxes,
            ft_order_status: 3, // 3 is for payment in success, 18 is for payment in Progress
            payment_mode_id: 1, // set id for payment mode // 1 is for cash
            stockDeduction: state.stockDeduction,
            stockDeductionConcession: state.stockDeductionConcession,
        };
        PosFnBService.OnFinalOrderSubmit(body)
            .then(async(response) => {
                const {
                    data
                } = response;
                if (data && data.status) {
                    commit('ON_SET_TRANSACTION_ID', data.Records);
                    // dispatch('GoToRoute', { path: '/pos-fnb-order-details' });
                    dispatch('onSwtichModule', {
                        name: 'showFnBOrderDetails'
                    });
                } else {
                    Vue.toasted.show(data.message || 'Opps! Something went wrong');
                }
            }).catch((error) => {
                Vue.toasted.show("Catch Error on FnBStore fetchFnBAdditionalPrice", error);
            });
    },
    GoToRoute: async({
        dispatch,
        commit,
        state
    }, {
        ...payload
    }) => {
        if (payload && payload.path === 'printCall' && state.CinemaDetails.length > 0) {
            let rawHTML = ` 
            <div class="ticket-wrapper">
            <div class="ticket-section">
              <table style="margin: 0 auto">
                <tbody>
                  <tr>
                    <td>
                    <div>${state.CinemaDetails[0].cine_name}</div>
                    
                      <div>${state.CinemaDetails[0].cine_address}</div>
                    <div>${state.CinemaDetails[0].cine_phone}</div>
                      <div>Served by: ${state.userDetails ? state.userDetails : ''} ${moment().format("MM-DD-YYYY HH:mm:ss")}</div>
                      <div>Order ID: ${state.OrderId}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
      
              <table style="margin: 0 auto; width: 100%;s">
                <tbody>
                  <tr>
                    <td style="text-align: left;">
                      <span style="border-bottom: 1px dashed; padding-bottom: 10px;">Item</span>
                    </td>
                    <td>
                      <span style="border-bottom: 1px dashed; padding-bottom: 10px;">Qty</span>
                    </td>
                    <td>
                      <span style="border-bottom: 1px dashed; padding-bottom: 10px;">Price</span>
                    </td>
                  </tr>`;

            let Promises = state.selectedFnbItemList.map((x) => {
                rawHTML += `<tr>
                    <td style="padding-top: 15px; text-align: left;">${x.recipe_name}</td>
                    <td style="padding-top: 15px;">${x.quantity}</td>
                    <td style="padding-top: 15px;">${x.fp_total_amount}</td>
                    </tr>`;
            });
            await Promise.all(Promises);

            rawHTML += `</tbody>
              </table>
              <table style="margin: 0 auto; width: 100%; margin-top: 8px;">
                <tbody>
                  <tr>
                    <td style="width: 50%; text-align: left;">Sub Total</td>
                    <td style="width: 50%; text-align: right; padding-right: 4px;">${state.currency.curr_code.toUpperCase()}&nbsp;${state.selectedFnBItemsTotalPrice}</td>
                  </tr>`
            let PromisesTaxes = state.additionalTaxList.map((x) => {
                rawHTML += ` <tr>
                    <td style="width: 50%; text-align: left;">${x.ct_name.toUpperCase()}</td>
                    <td style="width: 50%; text-align: right; padding-right: 4px;">${state.currency.curr_code.toUpperCase()}&nbsp;${x.totalTax}</td>
                </tr>`;
            });
            await Promise.all(PromisesTaxes);
            rawHTML += `<tr>
                    <td style="width: 50%; text-align: left; font-weight: bold;">Total</td>
                    <td
                      style="width: 50%; text-align: right; padding-right: 4px; font-weight: bold;"
                    >${state.currency.curr_code.toUpperCase()}&nbsp;${state.selectedFnBItemsTotalPrice + state.totalAdditionalTaxes}</td>
                  </tr>
                  <tr>
                    <td style="width: 50%; text-align: left;">Cash</td>
                    <td style="width: 50%; text-align: right; padding-right: 4px;">${state.cashInputValue}</td>
                  </tr>
                  <tr>
                    <td style="width: 50%; text-align: left;">Change</td>
                    <td style="width: 50%; text-align: right; padding-right: 4px;">${state.ChangeAmount > (state.selectedFnBItemsTotalPrice + state.totalAdditionalTaxes) ? state.ChangeAmount - (state.selectedFnBItemsTotalPrice + state.totalAdditionalTaxes) : 0}</td>
                  </tr>
                </tbody>
              </table>
              <table style="margin-top: 8px;">
                <tbody>
                  <tr>
                    <td style="text-align: left;">
                      <div>We love to LISTEN!! Please let us know how we did today by taking our 1 minute survey at binarynumbers.io</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>`;
            printJS({
                type: "raw-html",
                printable: rawHTML,
                style: `.ticket-section {
                    text-align: center;
                    height: calc(100% - 52.913385826px);
                    padding: 8px;
                  }
                  .ticket-wrapper {
                    width: 302.36220472px;
                    height: 100%;
                    border: 1px solid #000000;
                  }
                  td {
                    padding: 0px;
                  }`
            });

            commit('CHANGE_TEXT', payload)

            // router.push({
            //     path: '/pos-fnb-print-ticket'
            // });
        } else {
            router.push({
                path
            });
        }
    },

    // On 
    OnPhoneNumberSubmitHandler: async({
        dispatch,
        commit,
        state
    }, payload) => {
        // Call API or Save Number
        if (payload) {
            PosFnBService.OnSaveFnBPhoneNumber({
                    ft_mobile_no: payload.phonenumber,
                    ft_id: state.ft_id
                })
                .then((response) => {
                    const {
                        data
                    } = response;
                    data.message ? Vue.toasted.show(data.message || 'Opps! Something went wrong') : '';
                    if (data && data.status) {}
                }).catch((error) => {
                    console.log('error :', error);
                    Vue.toasted.show("Oops! Something went wrong. kindly contact your administrator");
                });
        } else {
            Vue.toasted.show("Phone Number is empty");
        }
    },
    onPosFnbPrintTicket: async({
            dispatch,
            commit,
            state
        }, payload) => {
            console.log('print :');
        }
        // On
};


const getters = {
    fnbItems: state => state.fnbItemList,
    fnbCategories: state => state.fnbCategories,
    fnbSubSubCategories: state => state.fnbSubCategories,
    fnbMoreCategories: state => state.fnbMoreCategories,
    getFnBCurrency: state => state.currency,
    getPaging: state => state.paging,
    SelectedFnbItemList: state => state.selectedFnbItemList,
    Cashdata: state => state.cashdata,
    ChangeAmount: state => state.ChangeAmount,
    AdditionalTaxList: state => state.additionalTaxList,
    isPaymentTypeCash: state => state.paymentTypes,
    isBoxofficeflag: state => state.BoxofficeCalculator
}

export default {
    state,
    mutations,
    actions,
    getters
}