import moment from "moment";
import Vue from 'vue';
import ReportsService from '@/services/ReportsService';
import { router } from '@/router';
import _ from "lodash";
import store from '@/store';

function initialState() {
    return {
        filmCollectionReportsList: [],
        filmCollectionReportChargeList: [],
        filmCollectionsExcelFileName: null,
        filmCollectionsPDFName: null,
        filmCollectionsMoviesList: [],

        flag: {
            isCinema: false,
        },
        filmCollectionObject: {
            fromDate: new Date(),
            toDate: new Date(),
            cinema_id: null,
            cinema_name: null,
            d_id: null,
            d_company_name: null,
            movie_id: null,
            movie_title: null,
            country_id: null,
        },
        paging: {},
        item: {}
    }
};

const state = initialState();

const mutations = {
    ON_RESET_FILM_COLLECTION_REPORT_STORE_STATE: (state, payload) => {
        // state = initialState();
        const s = initialState()
        Object.keys(s).forEach(key => {
            state[key] = s[key]
        })
    },
    SET_FILM_COLLECTION_LIST: (state, payload) => {
        state.filmCollectionsMoviesList = payload;
    },
    SET_FILM_COLLECTION_REPORT_LIST: (state, payload) => {
        state.filmCollectionReportsList = payload;
    },
    SET_FILM_COLLECTION_EXCEL_FILE_NAME: (state, payload) => {
        state.filmCollectionsExcelFileName = payload;
    },
    SET_FILM_COLLECTION_PDF_FILE_NAME: (state, payload) => {
        state.filmCollectionsPDFName = payload;
    },
    SET_FILM_COLLECTION_REPORT_CHARGESLIST: (state, payload) => {
        state.filmCollectionReportChargeList = _.orderBy(payload, 'charge_type_id');
    },
    SET_FILM_COLLECTION_REPORT_DATES: (state, payload) => {
        payload['name'] ? state.filmCollectionObject[payload.name] = payload['event'] ? payload.event : null : '';
    },
    SET_FILM_COLLECTION_REPORT_DROPDOWN: (state, payload) => {
        if (state.filmCollectionReportsList.length > 0) {
            state.filmCollectionReportsList = [];
            state.filmCollectionReportChargeList = [];
        };
        switch (payload.name) {
            case 'Cinema':
                state.filmCollectionObject.cinema_id = payload['selectedItem'] ? payload.selectedItem.cine_id : null;
                state.filmCollectionObject.cinema_name = payload['selectedItem'] ? payload.selectedItem.cine_name : null;
                break;
            case 'Distributor':
                state.filmCollectionObject.d_id = payload['selectedItem'] ? payload.selectedItem.d_id : null;
                state.filmCollectionObject.d_company_name = payload['selectedItem'] ? payload.selectedItem.d_company_name : null;

                state.filmCollectionObject.movie_id = null;
                state.filmCollectionObject.movie_title = null;
                break
            case 'MasterMovie':
                state.filmCollectionObject.movie_id = payload['selectedItem'] ? payload.selectedItem.movie_id : null;
                state.filmCollectionObject.movie_title = payload['selectedItem'] ? payload.selectedItem.movie_title : null;

                state.filmCollectionObject.d_id = null;
                state.filmCollectionObject.d_company_name = null;

                break
            default: console.log('FilmCollection :Sorry, we are enable to set value.');
        }
    },
    SET_FILM_COLLECTION_REPORT_FLAG: (state, payload) => {
        switch (payload.name) {
            case 'isCinema':
                state.flag.isCinema = payload['value'] ? payload.value === 0 ? false : true : false;
                state.filmCollectionObject.cinema_id = payload['value'] && payload.value === 0 ? null : payload.value;
                break;
            case 'isCountry':
                state.filmCollectionObject.country_id = payload['value'] && payload.value === 0 ? null : payload.value;
                break;
            case 'isExcelDownload':
                if (state.filmCollectionsExcelFileName && state.filmCollectionsExcelFileName.length > 0) {
                    store.dispatch("globalRemoveFile", { fileName: state.filmCollectionsExcelFileName });
                };
                if (state.filmCollectionsPDFName && state.filmCollectionsPDFName.length > 0) {
                    store.dispatch("globalRemoveFile", { fileName: state.filmCollectionsPDFName });
                };
                state.filmCollectionsPDFName = payload.value;
                state.filmCollectionsExcelFileName = payload.value;
                break;
            default:
                console.log('Sorry, we are enable to set Flag.');
        }
    }
}

const actions = {
    onResetFilmCollectionReportStoreState: async ({ dispatch, commit, state }, { ...payload }) => {
        await commit('ON_RESET_FILM_COLLECTION_REPORT_STORE_STATE');
    },
    fetchFilmCollectionReportMovies({ dispatch, commit, state }, payload) {
        if (state.filmCollectionObject.cinema_id && state.filmCollectionObject.d_id) {
            commit('SET_FILM_COLLECTION_LIST', []);
            ReportsService.GetDistributorMovie(state.filmCollectionObject)
                .then((response) => {
                    const { data } = response;
                    if (data && data.status && data.Records) {
                        commit('SET_FILM_COLLECTION_LIST', data.Records || []);
                    } else Vue.toasted.show(data.message || 'Opps! Something went wrong');
                }).catch((error) => {
                    Vue.toasted.show("Catch Error on ReportStore fetchDistributorMovies", error);
                });
        };
    },
    fetchFilmCollectionReportList({ dispatch, commit, state }, payload) {
        state.filmCollectionsExcelFileName ? store.dispatch("globalRemoveFile", { fileName: state.filmCollectionsExcelFileName }) : '';
        state.filmCollectionsPDFName ? store.dispatch("globalRemoveFile", { fileName: state.filmCollectionsPDFName }) : '';
        // console.log('object :', state.filmCollectionObject.fromDate, state.filmCollectionObject.toDate);
        // console.log('1 :', state.filmCollectionObject.fromDate >= new Date() && state.filmCollectionObject.toDate >= new Date());
        // console.log('2 :', state.filmCollectionObject.fromDate >= new Date());
        // console.log('3 :', state.filmCollectionObject.toDate >= new Date());
        // console.log('4 :', new Date(state.filmCollectionObject.from) > new Date(state.filmCollectionObject.toDate));
        commit('SET_FILM_COLLECTION_REPORT_LIST', []);
        commit('SET_FILM_COLLECTION_REPORT_CHARGESLIST', []);
        let fromDate = moment(new Date(state.filmCollectionObject.fromDate)).format("YYYY-MM-DD");
        let toDate = moment(new Date(state.filmCollectionObject.toDate)).format("YYYY-MM-DD");


        if (new Date(fromDate) >= new Date() && new Date(toDate) >= new Date()) {
            Vue.toasted.show("From date and To date should not be greater than the current date.");
        } else if (new Date(fromDate) >= new Date()) {
            Vue.toasted.show("From date should not be greater than the current date.");
        } else if (new Date(toDate) >= new Date()) {
            Vue.toasted.show("To date should not be greater than the current date.");
        } else if (new Date(fromDate) > new Date(toDate)) {
            Vue.toasted.show("From date should not be greater than To date.");
        } else if (!state.filmCollectionObject.cinema_id) {
            Vue.toasted.show("Please Select any Cinema.");
        }
        // else if (!state.filmCollectionObject.d_id && !state.filmCollectionObject.movie_id) {
        //     // !state.filmCollectionObject.d_id ? Vue.toasted.show("Please Select any Distributor.") : !state.filmCollectionObject.movie_id ? Vue.toasted.show("Please Select any Movie.") : '';
        //     Vue.toasted.show("Please Select any Distributor or Movie.");
        // } 
        else {
            let loader = Vue.$loading.show();

            ReportsService.GetFilmWiseCollectionReport(state.filmCollectionObject)
                .then(async (response) => {
                    const { data } = response;
                    if (data && data.status && data.Records) {
                        data['FinalTotalCharges'] && data.FinalTotalCharges ? commit('SET_FILM_COLLECTION_REPORT_CHARGESLIST', data['FinalTotalCharges'] || []) : '';
                        commit('SET_FILM_COLLECTION_REPORT_LIST', data.Records || []);
                        await commit('SET_FILM_COLLECTION_EXCEL_FILE_NAME', data['ExcelName'] || null);
                        console.log('data[] :', data['PDFName']);
                        await commit('SET_FILM_COLLECTION_PDF_FILE_NAME', data['PDFName'] || null)

                    } else Vue.toasted.show(data.message || 'Opps! Something went wrong');
                }).catch((error) => {
                    Vue.toasted.show("Catch Error on ReportStore fetchFilmCollectionReportList", error);
                }).finally(() => {
                    setTimeout(() => loader.hide(), 1000);
                });
        }
    },

    // On Handlers
    onFilmCollectionReportDateHandler: async ({ dispatch, commit, state }, payload) => {
        await commit('SET_FILM_COLLECTION_REPORT_DATES', payload);
        await commit('SET_FILM_COLLECTION_REPORT_FLAG', { name: 'isExcelDownload', value: null });

    },
    onFilmCollectionReportDropdownHandler: async ({ dispatch, commit, state }, payload) => {
        await commit('SET_FILM_COLLECTION_REPORT_DROPDOWN', payload);
        await commit('SET_FILM_COLLECTION_REPORT_FLAG', { name: 'isExcelDownload', value: null });
        // if (payload['name'] && payload.name === 'Distributor' || payload['name'] && payload.name === 'Cinema' && state.filmCollectionObject.cinema_id && state.filmCollectionObject.d_id) {
        //     await dispatch('fetchFilmCollectionReportMovies');
        // };
    },
    onFilmCollectionReportFlagHandler({ dispatch, commit, state }, payload) {
        commit('SET_FILM_COLLECTION_REPORT_FLAG', payload)
    }
}

const getters = {
    getFilmCollectionReportList: state => state.filmCollectionReportsList,
    getFilmCollectionReportChargeList: state => state.filmCollectionReportChargeList,
    getFilmCollectionReportFromDate: state => state.filmCollectionObject.fromDate,
    getFilmCollectionReportToDate: state => state.filmCollectionObject.toDate,
    getFilmCollectionReportSelectedDistributor: state => state.filmCollectionObject.d_company_name,
    getFilmCollectionReportSelectedCinema: state => state.filmCollectionObject.cinema_name,
    getFilmCollectionReportsIsCinema: state => state.flag.isCinema,
    getFilmCollectionReportSelectedMovie: state => state.filmCollectionObject.movie_title,

    getFilmCollectionReportMoviesList: state => state.filmCollectionsMoviesList,


    // Get File name
    getFilmCollectionExcelFileName: state => state.filmCollectionsExcelFileName,
    getFilmCollectionPDFFileName: state => state.filmCollectionsPDFName,


};

export default {
    state,
    mutations,
    actions,
    getters
}
