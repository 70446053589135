import moment from "moment";
import Vue from 'vue';
import ReportsService from '@/services/ReportsService';
import { router } from '@/router';
import _ from "lodash";

import store from '@/store';

function initialState() {
    return {
        SummaryReportsList: [],
        SummaryPDFFileName: null,
        Summaries: {
            TotalAdmits: 0,
            TotalTransactions: 0,
            TotalSeatsInCinema: 0,
            TotalScreens: 0,
            TotalNoofShows: 0,
            AvgShows: 0,
            TotalRevenue: 0,
            AverageShowsPerScreen: 0,
            AverageShowsPerDayPerScreen: 0,
            TotalCapacity: 0,
        },
        BoxOfficeObject: {
            TotalBoxOfficeRevenue: 0,
            NetRevenue: 0,
            TotalTransaction: 0,
            TotalTax: 0,
            AdditionalCharges: 0,
            ATP: 0,
            NetATP: 0,
            OccupancyRate: 0,
        },
        Concessions: {
            GrossConcessionRevenue: 0,
            TaxConcessionRevenue: 0,
            NetConcessionRevenue: 0,
            GrossSpendPerHead: 0,
            NumberOfConcessionsTransactions: 0,
            AdmissionStrikeRate: 0,
            AverageValuePerTransaction: 0,
            TopFiveItemsSold: [],
            QuantityOfItemsSold: 0,
            CostOfGoods: 0
        },
        ConcessionsProfit: {
            ProfitAtStandardCost: 0,
            AverageProfit: 0,
            AverageProfitPerItem: 0,
            ProfitPerHead: 0,
            PricePerTransaction: 0,
        },
        flag: {
            isCinema: false,
        },
        summaryObject: {
            fromDate: new Date(),
            toDate: new Date(),
            cinema_id: null,
            cinema_name: null,
        },
        paging: {},
        item: {}
    }
};

const state = initialState();

const mutations = {
    ON_RESET_SUMMARIES_REPORT_STORE_STATE: (state, payload) => {
        // state = initialState();
        const s = initialState()
        Object.keys(s).forEach(key => {
            state[key] = s[key]
        })
    },
    SET_SUMMARIES_OBJECT: (state, payload) => {
        // Object.assign(state.Summaries, payload)
        Object.keys(state.Summaries).map((x) => {
            state.Summaries[x] = payload[x] || 0
        })
    },
    SET_SUMMARIES_BOX_OFFICE_OBJECT: (state, payload) => {
        // Object.assign(state.BoxOfficeObject, payload)
        Object.keys(state.BoxOfficeObject).map((x) => {
            state.BoxOfficeObject[x] = payload[x] || 0
        })
    },
    SET_SUMMARIES_CONCESSIONS: (state, payload) => {
        // Object.assign(state.Concessions, payload)
        Object.keys(state.Concessions).map((x) => {
            state.Concessions[x] = payload[x] || 0
        });
    },
    SET_SUMMARIES_CONCESSIONS_PROFIT: (state, payload) => {
        // Object.assign(state.ConcessionsProfit, payload)
        Object.keys(state.ConcessionsProfit).map((x) => {
            state.ConcessionsProfit[x] = payload[x] || 0
        });
    },
    SET_SUMMARIES_PDF_FILE_NAME: (state, payload) => {
        state.SummaryPDFFileName = payload;
    },
    SET_SUMMARY_REPORT_LIST: (state, payload) => {
        state.SummaryReportsList = payload;
    },
    SET_SUMMARY_REPORT_DATES: (state, payload) => {
        payload['name'] ? state.summaryObject[payload.name] = payload['event'] ? payload.event : null : '';
    },
    SET_SUMMARY_REPORT_DROPDOWN: (state, payload) => {
        switch (payload.name) {
            case 'isCinema':
                state.summaryObject.cinema_id = payload['selectedItem'] ? payload.selectedItem.cine_id : null;
                state.summaryObject.cinema_name = payload['selectedItem'] ? payload.selectedItem.cine_name : null;
                break;
            default: console.log('FilmCollection :Sorry, we are enable to set value.');
        }
    },
    SET_SUMMARY_REPORT_FLAG: (state, payload) => {
        switch (payload.name) {
            case 'isCinema':
                state.flag.isCinema = payload['value'] ? payload.value === 0 ? false : true : false;
                state.summaryObject.cinema_id = payload['value'] && payload.value === 0 ? null : payload.value;
                break;
            case 'isCountry':
                state.summaryObject.country_id = payload['value'] && payload.value === 0 ? null : payload.value;
                break;
            case 'isExcelDownload':
                if (state.SummaryPDFFileName && state.SummaryPDFFileName.length > 0) {
                    store.dispatch("globalRemoveFile", { fileName: state.SummaryPDFFileName });
                };
                state.SummaryPDFFileName = null;
                break;
            default:
                console.log('Sorry, we are enable to set Flag.');
        }
    }
}

const actions = {
    onResetSummaryStoreState: async ({ dispatch, commit, state }, { ...payload }) => {
        await commit('ON_RESET_SUMMARIES_REPORT_STORE_STATE');
    },
    fetchSummaryReportList({ dispatch, commit, state }, payload) {
        commit('SET_SUMMARY_REPORT_LIST', []);
        state.SummaryPDFFileName ? store.dispatch("globalRemoveFile", { fileName: state.SummaryPDFFileName }) : '';

        // console.log('4 :', new Date(state.summaryObject.fromDate) > new Date(state.summaryObject.toDate));
        // console.log('4 :', new Date(state.summaryObject.fromDate), new Date(state.summaryObject.toDate));

        let fromDate = moment(new Date(state.summaryObject.fromDate)).format("YYYY-MM-DD");
        let toDate = moment(new Date(state.summaryObject.toDate)).format("YYYY-MM-DD");

        if (new Date(fromDate) >= new Date() && new Date(toDate) >= new Date()) {
            Vue.toasted.show("From date and To date should not be greater than the current date.");
        } else if (new Date(fromDate) >= new Date()) {
            Vue.toasted.show("From date should not be greater than the current date.");
        } else if (new Date(toDate) >= new Date()) {
            Vue.toasted.show("To date should not be greater than the current date.");
        } else if (new Date(fromDate) > new Date(toDate)) {
            Vue.toasted.show("From date should not be greater than To date.");
        } else if (!state.summaryObject.cinema_id) {
            Vue.toasted.show("Please Select any Cinema.");
        } else {
            let loader = Vue.$loading.show();

            ReportsService.GetSummaryReportList(state.summaryObject)
                .then((response) => {
                    const { data } = response;
                    // console.log('data :', data);
                    if (data && data.status) {
                        commit('SET_SUMMARIES_OBJECT', data.SummaryObject || {});
                        commit('SET_SUMMARIES_BOX_OFFICE_OBJECT', data.BoxOfficeObject || {});
                        commit('SET_SUMMARIES_CONCESSIONS', data.Concessions || {});
                        commit('SET_SUMMARIES_CONCESSIONS_PROFIT', data.ConcessionsProfit || {});
                        // PDFName
                        commit('SET_SUMMARIES_PDF_FILE_NAME', data.PDFName || null);



                        // commit('SET_SUMMARY_REPORT_LIST', data.Records || [])
                    } else Vue.toasted.show(data.message || 'Opps! Something went wrong');
                }).catch((error) => {
                    console.log('error :', error);
                    Vue.toasted.show("Catch Error on ReportStore fetchSummaryReportList", error);
                }).finally(() => {
                    setTimeout(() => loader.hide(), 1000);
                });
        }
    },

    // On Handlers
    onSummaryReportDateHandler: async ({ dispatch, commit, state }, payload) => {
        await commit('SET_SUMMARY_REPORT_DATES', payload);
        await commit('SET_SUMMARY_REPORT_FLAG', { name: 'isExcelDownload', value: false });
    },
    onSummaryReportDropdownHandler: async ({ dispatch, commit, state }, payload) => {
        await commit('SET_SUMMARY_REPORT_DROPDOWN', payload)
        await commit('SET_SUMMARY_REPORT_FLAG', { name: 'isExcelDownload', value: false });

    },
    onSummaryReportFlagHandler({ dispatch, commit, state }, payload) {
        commit('SET_SUMMARY_REPORT_FLAG', payload)
    }
}

const getters = {
    getSummaryReportFromDate: state => state.summaryObject.fromDate,
    getSummaryReportToDate: state => state.summaryObject.toDate,
    getSummaryReportSelectedCinema: state => state.summaryObject.cinema_name,
    getSummaryReportsIsCinema: state => state.flag.isCinema,
    getSummaryReportList: state => state.SummaryReportsList,
    getSummaries: state => state.Summaries,
    getSummariesBoxOffice: state => state.BoxOfficeObject,
    getSummariesConcession: state => state.Concessions,
    getSummariesConcessionProfit: state => state.ConcessionsProfit,
    // PDF
    getSummariesPDFFileName: state => state.SummaryPDFFileName,


};

export default {
    state,
    mutations,
    actions,
    getters
}
