import MasterService from "@/services/MasterService";
const state = {
    LanguagesList: [],
    ContentLanguagesList: [],
    StoreMoviePosters: [],
}

const mutations = {
    SET_LANGUAGES: (state, payload) => {
        state.LanguagesList = payload
    },
    SET_CONTENTLANGUAGE: async (state, payload) => {
        if (payload.length > 0) {
            state.ContentLanguagesList = [];
            await payload.map(async x => {
                let getlanguage = state.LanguagesList.filter(y => y.lang_id === x);
                if (getlanguage.length > 0) {
                    await state.ContentLanguagesList.push({
                        langaugeId: x,
                        langauge: getlanguage[0].lang_name || ""
                    });
                }
            });
        }

    },
    SET_MOVIE_POSTERS: (state, payload) => {
        state.StoreMoviePosters = []
        payload.map(x => {
            if (x.image_setting_is_boxoffice) {
                let obj = {}
                Object.assign(obj, x);
                obj['image_setting_path'] = "";
                obj['image_setting_static_path'] = "";
                obj['image_setting_baseurl'] = "";
                obj['image_setting_showpath'] = "";
                state.StoreMoviePosters.push(obj);
            }
        })
    }

}

const actions = {
    async setLanguagesToContentLang({ commit }, lang_ids) {

    },
    setLanguages({ commit }, payload) {
        let body = { "url": "/admin/ms_language", "method": "GET", "payload": {} }
        MasterService.GlobalRequest(body)
            .then(response => {
                const { data } = response;
                if (data.status && data.Records.length > 0) {
                    commit('SET_LANGUAGES', data.Records)
                } else {
                    // Show Errors
                    console.log("show error :", data.message);
                }
            })
            .catch(error => {
                // Show Catch Error
                console.log("show error :", error);
            });
    },
    setContentLanguages({ commit }, payload) {
        let body = { "url": "/admin/global_options", "method": "GET", "payload": {} }
        MasterService.GlobalRequest(body)
            .then(async response => {
                const { data } = response;
                if (data.status && data.Records.length > 0) {
                    let getOperations = data.Records.filter(
                        x => x.go_key === "OPERATION_COUNTRY"
                    );
                    let mapPromise = await data.Records.map(async x => {
                        if (
                            x.go_key === "CONTENT_LANGUAGE" &&
                            getOperations.length > 0
                        ) {
                            let parseValue = JSON.parse(x.go_value);
                            let filterArray = parseValue.filter(
                                y =>
                                    String(y.country_id) === String(getOperations[0].go_value)
                            );
                            if (filterArray.length > 0) {
                                // commit('SET_CONTENTLANGUAGE', await dispatch('setLanguagesToContentLang',
                                //     filterArray[0]["lang_id"] || []
                                // ));
                                commit('SET_CONTENTLANGUAGE', filterArray[0]["lang_id"]);
                            }
                        }
                    });
                    await Promise.all(mapPromise);

                } else {
                    // Show Errors
                    console.log("show error :", data.message);
                }
            })
            .catch(error => {
                // Show Catch Error
                console.log("show error :", error);
            });
    },
    setMoviePosters({ commit }, payload) {



        let body = { "url": "/admin/ms_image_settings", "method": "GET", "payload": {} }

        MasterService.GlobalRequest(body)
            .then(async response => {
                const { data } = response;
                if (data.status && data.Records.length > 0) {
                    await commit('SET_MOVIE_POSTERS', data.Records)
                } else {
                    // Show Errors
                    console.log("show error :", data.message);
                }
            })
            .catch(error => {
                // Show Catch Error
                console.log("show error :", error);
            });
    }
}

const getters = {
    getLanguages: state => state.LanguagesList,
    getContentLanguagesList: state => state.ContentLanguagesList,
    getMoviePostersList: state => state.StoreMoviePosters,
}

export default {
    state,
    mutations,
    actions,
    getters
}
