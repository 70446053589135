import Vue from 'vue';
import ReportsService from '@/services/ReportsService';
import { router } from '@/router';
import moment from "moment";
import store from '@/store';

function initialState() {
    return {
        screenCollectionReportsList: [],
        screenCollectionExcelFileName: null,
        screenCollectionPDFFileName: null,
        flag: {
            isCinema: false,
        },
        screenCollectionObject: {
            fromDate: new Date(),
            toDate: new Date(),
            cinema_id: null,
            cinema_name: null,
            d_id: null,
            d_company_name: "Select Distributor",
            movie_id: null,
            movie_title: "Select Movie",
            country_id: null,
        },
        paging: {},
        item: {}
    };
};

const state = initialState();

const mutations = {
    ON_RESET_SCREEN_COLLECTION_REPORT_STORE_STATE: (state, payload) => {
        // state = initialState();
        const s = initialState()
        Object.keys(s).forEach(key => {
            state[key] = s[key]
        })
    },
    SET_SCREEN_COLLECTION_EXCEL_FILE_NAME: (state, payload) => {
        state.screenCollectionExcelFileName = payload;
    },
    SET_SCREEN_COLLECTION_PDF_FILE_NAME: (state, payload) => {
        state.screenCollectionPDFFileName = payload;
    },
    SET_SCREEN_COLLECTION_REPORT_LIST: (state, payload) => {
        state.screenCollectionReportsList = payload;
    },
    SET_SCREEN_COLLECTION_REPORT_DATES: (state, payload) => {
        payload['name'] ? state.screenCollectionObject[payload.name] = payload['event'] ? payload.event : null : '';
    },
    SET_SCREEN_COLLECTION_REPORT_DROPDOWN: (state, payload) => {
        if (state.screenCollectionReportsList.length > 0) {
            state.screenCollectionReportsList = [];
        };
        switch (payload.name) {
            case 'Cinema':
                state.screenCollectionObject.cinema_id = payload['selectedItem'] ? payload.selectedItem.cine_id : null;
                state.screenCollectionObject.cinema_name = payload['selectedItem'] ? payload.selectedItem.cine_name : null;
                break;
            case 'Distributor':
                state.screenCollectionObject.d_id = payload['selectedItem'] ? payload.selectedItem.d_id : null;
                state.screenCollectionObject.d_company_name = payload['selectedItem'] ? payload.selectedItem.d_company_name : null;
                break
            case 'MasterMovie':
                state.screenCollectionObject.movie_id = payload['selectedItem'] ? payload.selectedItem.movie_id : null;
                state.screenCollectionObject.movie_title = payload['selectedItem'] ? payload.selectedItem.movie_title : null;
                break;
            default: console.log('FilmCollection :Sorry, we are enable to set value.');
        }
    },
    SET_SCREEN_COLLECTION_REPORT_FLAG: (state, payload) => {
        switch (payload.name) {
            case 'isCinema':
                state.flag.isCinema = payload['value'] ? payload.value === 0 ? false : true : false;
                state.screenCollectionObject.cinema_id = payload['value'] && payload.value === 0 ? null : payload.value;
                break;
            case 'isCountry':
                state.screenCollectionObject.country_id = payload['value'] && payload.value === 0 ? null : payload.value;
                break;
            case 'isExcelDownload':
                state.screenCollectionExcelFileName && state.screenCollectionExcelFileName.length > 0 ? store.dispatch("globalRemoveFile", { fileName: state.screenCollectionExcelFileName }) : '';
                state.screenCollectionPDFFileName && state.screenCollectionPDFFileName.length > 0 ? store.dispatch("globalRemoveFile", { fileName: state.screenCollectionPDFFileName }) : '';
                state.screenCollectionExcelFileName = payload.value;
                state.screenCollectionPDFFileName = payload.value;
                break;
            default:
                console.log('Sorry, we are enable to set Flag.');
                break;
        }
    }
}

const actions = {
    onResetScreenCollectionReportStoreState: async ({ dispatch, commit, state }, { ...payload }) => {
        await commit('ON_RESET_SCREEN_COLLECTION_REPORT_STORE_STATE');
    },
    fetchScreenCollectionReportList: async ({ dispatch, commit, state }, payload) => {
        state.screenCollectionExcelFileName ? store.dispatch("globalRemoveFile", { fileName: state.screenCollectionExcelFileName }) : '';
        state.screenCollectionPDFFileName ? store.dispatch("globalRemoveFile", { fileName: state.screenCollectionPDFFileName }) : '';
        commit('SET_SCREEN_COLLECTION_REPORT_LIST', []);
        let fromDate = moment(new Date(state.screenCollectionObject.fromDate)).format("YYYY-MM-DD");
        // let toDate = moment(new Date(state.dailyReportsObject.toDate)).format("YYYY-MM-DD");
        if (!state.screenCollectionObject.fromDate) {
            Vue.toasted.show("Please Select any Date.");
        } else if (new Date(fromDate) >= new Date()) {
            Vue.toasted.show("From date and To date should not be greater than the current date.");
        } else if (!state.screenCollectionObject.cinema_id) {
            Vue.toasted.show("Please Select any Cinema.");
        } else {

            let loader = Vue.$loading.show();
            // let body = {
            //     ...state.screenCollectionObject,
            //     country_id: payload['country_id'] || null
            // };

            ReportsService.GetScreenWiseCollectionReport(state.screenCollectionObject)
                .then(async (response) => {
                    const { data } = response;
                    if (data && data.status && data.Records && data.Records.length > 0) {
                        commit('SET_SCREEN_COLLECTION_REPORT_LIST', data.Records || [])
                        await commit('SET_SCREEN_COLLECTION_EXCEL_FILE_NAME', data['ExcelName'] || null);
                        await commit('SET_SCREEN_COLLECTION_PDF_FILE_NAME', data['PDFName'] || null);
                    } else Vue.toasted.show(data.message || 'Opps! Something went wrong');
                }).catch((error) => {
                    Vue.toasted.show("Catch Error on ReportStore fetchScreenCollectionReportList", error);
                }).finally(() => {
                    setTimeout(() => loader.hide(), 1000);
                });
        }
    },

    // On Handlers
    onScreenCollectionReportDateHandler: async ({ dispatch, commit, state }, payload) => {
        await commit('SET_SCREEN_COLLECTION_REPORT_DATES', payload);
        await commit('SET_SCREEN_COLLECTION_REPORT_FLAG', { name: 'isExcelDownload', value: null });
        if (state.screenCollectionObject.cinema_id) await dispatch('fetchScreenCollectionReportList')

    },
    onScreenCollectionReportDropdownHandler: async ({ dispatch, commit, state }, payload) => {
        await commit('SET_SCREEN_COLLECTION_REPORT_DROPDOWN', payload)
        await commit('SET_SCREEN_COLLECTION_REPORT_FLAG', { name: 'isExcelDownload', value: null });
    },
    onScreenCollectionReportFlagHandler({ dispatch, commit, state }, payload) {
        commit('SET_SCREEN_COLLECTION_REPORT_FLAG', payload)
    }
}

const getters = {
    getScreenCollectionReportList: state => state.screenCollectionReportsList,
    getScreenCollectionReportFromDate: state => state.screenCollectionObject.fromDate,
    getScreenCollectionReportToDate: state => state.screenCollectionObject.toDate,
    getScreenCollectionReportSelectedDistributor: state => state.screenCollectionObject.d_company_name,
    getScreenCollectionReportSelectedCinema: state => state.screenCollectionObject.cinema_name,
    getScreenCollectionReportsIsCinema: state => state.flag.isCinema,
    getScreenCollectionReportSelectedMovie: state => state.screenCollectionObject.movie_title,

    // Get File name
    getScreenCollectionExcelFileName: state => state.screenCollectionExcelFileName,
    getScreenCollectionPDFFileName: state => state.screenCollectionPDFFileName,

};

export default {
    state,
    mutations,
    actions,
    getters
}
