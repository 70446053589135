

import Api from './Api';
import axios from 'axios';
import { BASEURL } from "@/config/index";

export default {
    GetActiveDistributors(payload) {
        return Api().post('/active-list/ms_distributors', payload)
    },
    GetDistributorContractByMovieId(payload) {
        return Api().get(`/api/admin/get-distributorcontract-bymovieid/${payload.movie_id}`)
    },
    // Distributor Share types
    GetActiveDistributorShareTypes(payload) {
        return Api().post('/active-list/distributor_share_type', payload)
    },
    AddDistributorShareType(payload) {
        return Api().post(`/api/admin/distributorsharetype`, payload);
    },
    GetDistributorShareType(payload) {
        return Api().get(`/api/admin/distributorsharetype?limit=${payload.limit}&currentPage=${payload.currentPage}`);
    },
    GetDistributorShareTypeById(payload) {
        return Api().get(`/api/admin/distributorsharetype/${payload.dst_id}`);
    },
    updateDistributorShareType(payload) {
        return Api().put(`/api/admin/distributorsharetype/${payload.dst_id}`, payload);
    },
    // Distributor Contract
    AddDistributorContract(payload) {
        return Api().post(`/api/admin/distributorcontract`, payload);
    },
    GetDistributorContract(payload) {
        return Api().get(`/api/admin/distributorcontract?limit=${payload.limit}&currentPage=${payload.currentPage}`);
    },
    GetDistributorContractById(payload) {
        return Api().get(`/api/admin/distributorcontract/${payload.dc_id}`);
    },
    updateDistributorContract(payload) {
        return Api().put(`/api/admin/distributorcontract/${payload.dc_id}`, payload);
    },

    /**
     * Distributor Share
     */
    GetDistributorContractDates(payload) {
        return Api().post(`/api/admin/distributorsharecontractdates`, payload);
    },
    GetDistributorShares(payload) {
        // let query = ''
        // let length = Object.keys(payload).length
        // if (length > 0) {
        //     query = '?'
        //     Object.keys(payload).map((x, index) => {
        //         if (payload[x]) {
        //             query += index === length - 1 ? `${x}=${payload[x]}` : `${x}=${payload[x]}&`
        //         }
        //     })
        // }
        return Api().post(`/api/admin/distributorshare`, payload);
    },
    GetDistributorMoviesById(payload) {
        return Api().get(`/api/admin/distributormovies/${payload.distributor_id}/${payload.country_id}`)
    },
    GetDistributorMoviesWithFormat(payload) {
        return Api().get(`/api/admin/distributormovieswithformat/${payload.movie_id}`)
    },
    GetDistributorActiveMasterMovie() {
        return Api().get(`/api/admin/getactivemastermovie`)
    }
    /**
    * Distributor Share End
    */

}
