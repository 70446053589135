import Api from './Api';

export default {
    getBinNumbers(payload) {
        return Api().post(`/api/marketing/viewBinNumbers`, payload);
    },

    addBinNumbers(payload) {
        return Api().post('/api/marketing/addbinnumbers', payload)
    },
    viewBinNumbers(payload) {
        return Api().post('/api/marketing/getbinNumbers', payload)
    },



    // BANK API'S
    getAllBank(perPage, currentPage) {
        return Api().get(`/api/marketing/bank?limit=${perPage}&currentPage=${currentPage}`);
    },
    getBankDetails(payload) {
        return Api().get(`/api/marketing/bank/${payload.bank_id}`)
    },
    addBank(payload) {
        return Api().post('/api/marketing/addBank', payload)
    },
    editBank(payload) {
        return Api().put(`/api/marketing/updatebank/${payload.bank_id}`, payload)
    },

    //INSTITUTE API'S 

    addInstitution(payload) {
        return Api().post(`/api/marketing/addinstitution`, payload)
    },
    getAllInstitutes(perPage, currentPage) {
        return Api().get(`/api/marketing/viewinstitution?limit=${perPage}&currentPage=${currentPage}`);
    },
    getInstitutionDetails(payload) {
        return Api().get(`/api/marketing/viewinstitutedetail/${payload.institution_id}`)
    },
    editInstitution(payload) {
        return Api().put(`/api/marketing/updateinstitution/${payload.institution_id}`, payload)
    },

    //OFFER TYPE API'S 

    getAllOfferType(perPage, currentPage) {
        return Api().get(`/api/marketing/viewoffertype?limit=${perPage}&currentPage=${currentPage}`);
    },
    addOfferType(payload) {
        return Api().post('/api/marketing/addoffertype', payload)
    },
    getSingleOfferDetails(payload) {
        return Api().get(`/api/marketing/viewofferdetail/${payload.ot_id}`)
    },
    editOfferType(payload) {
        return Api().put(`/api/marketing/updateoffertype/${payload.ot_id}`, payload)
    },

    //CARD TYPE API'S 

    addCardType(payload) {
        return Api().post('/api/marketing/addcardtype', payload)
    },
    getAllCardType(perPage, currentPage) {
        return Api().get(`/api/marketing/viewcardtype?limit=${perPage}&currentPage=${currentPage}`);
    },
    getSingleCardDetails(payload) {
        return Api().get(`/api/marketing/viewcarddetail/${payload.card_id}`)
    },
    editCardType(payload) {
        return Api().put(`/api/marketing/updatecardtype/${payload.card_id}`, payload)
    },

    // CUSTOMERS API

    addCustomer(payload) {
        return Api().post('/api/marketing/addcustomer', payload)
    },
    getAllCustomers(perPage, currentPage) {
        return Api().get(`/api/marketing/viewcustomers?limit=${perPage}&currentPage=${currentPage}`);
    },
    getSingleCustomerDetails(payload) {
        return Api().get(`/api/marketing/viewcustomerdetail/${payload.cust_id}`)
    },
    editCustomer(payload) {
        return Api().put(`/api/marketing/updatecustomer/${payload.cust_id}`, payload)
    },

    /**
    * ADD PERMOTIONS OFFERS AND VOUCHERS START
    */
    GetAllFNBItems() {
        return Api().get(`/api/marketing/getallfnbitems`)
    },
    GetCustomerCategories() {
        return Api().get('/api/marketing/customeroffercategories')
    },
    GetActiveInstitites() {
        return Api().get('/api/marketing/viewactiveinstitution')
    },
    GetActiveBanks() {
        return Api().get('/api/marketing/viewactivebank');
    },
    GetActiveBanksCards() {
        return Api().get(`/api/marketing/viewactivebankcard`);
    },
    GetVoucherPartners() {
        return Api().get(`/api/marketing/activevoucherpartners`);
    },
    AddVochers(payload) {
        return Api().post(`/api/marketing/addvouchers`, payload);
    },
    GetVouchers(payload) {
        return Api().get(`/api/marketing/getactivevoucher?limit=${payload.limit}&currentPage=${payload.currentPage}`);
    },
    GetSingleVoucher(payload) {
        return Api().get(`/api/marketing/getactivevoucher/${payload.voucher_id}`);
    },
    UpdateVoucher(payload) {
        return Api().put(`/api/marketing/updatesinglevoucher/${payload.voucherid}`, payload);
    },
    GetPromotionAndOffers(payload) {
        // Not DONE
        // return Api().get(`/api/marketing/getactivevoucher?limit=${payload.limit}&currentPage=${payload.currentPage}`);
    },
    AddPromotionAndOffers(payload) {
        return Api().post(`/api/marketing/addpermotionsandoffers`, payload);
    },
    UpdatePromotionAndOffers(payload) {
        return Api().put(`/api/marketing/updatepermotionsandoffers/${payload.os_id}`, payload);
    },
    GetPrmotionAndoffers(payload) {
        return Api().get(`/api/marketing/getactivepermotionsandoffers?limit=${payload.limit}&currentPage=${payload.currentPage}`);
    },
    GetSinglePermotiosAndOffers(payload) {
        return Api().get(`/api/marketing/getactivepermotionsandoffers/${payload.offertypeid}`);
    },
    GetVerificationList() {
        return Api().get(`/api/marketing/getverificationdocs`);
    },
    /**
    * ADD PERMOTIONS OFFERS AND VOUCHERS END
    */
    /**
     * POS OFFERS START
     */
    GetOffersCollections(payload) {
        return Api().post(`/api/posadmin/getofferscollection`, payload);
    }
    /**
     * POS OFFERS END
     */

}