import Vue from "vue";
import Router from "vue-router";
import {
    Role
} from '@/helper/Role';
import {
    authenticationService
} from "@/services/AuthenticationService";

Vue.use(Router);


const ifNotAuthenticated = (to, from, next) => {
    if (Vue.cookie.get('BRHSID')) {
        return next()
    } else return next('/login')

    // Temp Testing
    // next('/login')
    // next()
};


const getPermissions = function (permissions, access, redirect) {
    let roleArray = []
    let promise = permissions.map((x) => {
        if (Role.hasOwnProperty(x)) {
            roleArray.push({
                role: Role[x],
                access: access,
                redirect: redirect
            })
        }
        return x;
    });
    Promise.all(promise)
    return roleArray;
}


export const router = new Router({
    // mode: "history",
    mode: "hash",
    scrollBehavior: () => ({
        y: 0
    }),
    /**
     * FIRST ROUTE START
     */
    routes: [{
        path: "*",
        name: "seatLayout",
        component: () =>
            import("@/components/Pages/posSeatLayout.vue")
    },{
        path: "/final-ticket",
        name: "finalTicket",
        component: () =>
            import("@/components/Pages/FinalTicket.vue")
    }]
});