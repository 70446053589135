import Vue from 'vue';
import MarketingService from '@/services/MarketingService';
import { router } from '@/router';
import moment from "moment";
import store from '@/store';

function initialState() {
    return {
        OffersList: [],
        CinemaSettings: {
            cine_sell_fnb_in_boxoffice: null
        },
        offersObject: {
            cinema_id: null,
            screen_id: null,
            seat_type_id: null,// sst_id: null,
            movie_details_id: null,
            show_date_time: null,

        },
        offersPosFlags: {
            isPosOffersShow: false,
        },
        offerVerificationList: [],
        offerCategoriesList: []
        // OfferAppl

    };
};

const state = initialState();

const mutations = {
    ON_RESET_OFFERS_STORE_STATE: (state, payload) => {
        // state = initialState();
        const s = initialState()
        Object.keys(s).forEach(key => {
            state[key] = s[key]
        })
    },
    SET_OFFERS_EMPTY: (state, payload) => {
        state.OffersList = [];

    },
    SET_OFFERS_LIST: (state, payload) => {
        state.OffersList = payload;

        state.OffersList.map((x) => {
            if (String(x.offer_type_id) === '6') {
                let list = x['booking_user_category_id'] && x['booking_user_category_id'].length > 0 ? x['booking_user_category_id'].split(', ') : [];
                x['booking_user_category_id'] = '';
                list.map((z) => {
                    let a = state.offerCategoriesList.filter((y) => String(z) === String(y.buc_id));
                    if (a.length > 0) {
                        x['booking_user_category_id'] += x['booking_user_category_id'].length > 0 ? `, ${a[0].buc_category_name}` : `${a[0].buc_category_name}`
                    }
                });
            }
        })

    },
    SET_CINEMA_SETTING: (state, payload) => {
        state.CinemaSettings = {
            ...payload
        };
    },
    SET_OFFERS_VERIFICATION_LIST: (state, payload) => {
        state.offerVerificationList = payload;
    },
    SET_OFFERS_CATEGORIES_LIST: (state, payload) => {
        state.offerCategoriesList = payload;
    },
    SET_OFFERS_STATES: (state, payload) => {
        switch (payload.name) {
            case 'isCinema':
                state.offersObject.cinema_id = payload.id;
                break;
            case 'isMovie':
                state.offersObject.movie_details_id = payload.id;
                break;
            case 'isScreen':
                state.offersObject.screen_id = payload.id;
                break;
            case 'isSeatType':
                state.offersObject.seat_type_id = payload.id;
                break;
            case 'isMovie_isScreen_isShowTime':
                state.offersObject.screen_id = payload.screen_id;
                state.offersObject.movie_details_id = payload.movie_details_id;
                state.offersObject.show_date_time = payload.show_date_time;
                console.log('state :', state);
                break;
            default: console.log('PosOffer: Sorry! we are enable to set value.');
        };
    },
    //// POS
    SET_POS_OFFERS_FLAGS: (state, payload) => {
        switch (payload.name) {
            case 'isPosOffersShow':
                state.offersPosFlags.isPosOffersShow = payload.value;
                break;
            default: console.log('FilmCollection :Sorry, we are enable to set value.');
        }
    },
};

const actions = {
    onResetOffersStoreState: async ({ dispatch, commit, state }, { ...payload }) => {
        await commit('ON_RESET_OFFERS_STORE_STATE');
    },
    onSetOffersFlags: async ({ dispatch, commit, state }, { ...payload }) => {
        await commit('SET_POS_OFFERS_FLAGS', payload);
        if (!payload.value) await commit('SET_OFFERS_EMPTY', []);
    },
    onSetSelectedOfferId: async ({ dispatch, commit, state }, { ...payload }) => {
        await commit('SET_OFFERS_STATES', payload);
    },
    fetchOffersList: async ({ dispatch, commit, state }, payload) => {
        // await ommit('SET_OFFERS_STATES', { seat_type_id: payload.seat_type_id });
        await dispatch('fetchCustomerCategoriesList')
        await commit('SET_POS_OFFERS_FLAGS', { name: "isPosOffersShow", value: true });
        await commit('SET_OFFERS_LIST', []);
        // let loader = Vue.$loading.show();
        console.log('state.offersObject :', state.offersObject);

        MarketingService.GetOffersCollections(state.offersObject)
            .then(async (response) => {
                const { data } = response;
                if (data && data.status) {
                    if (data.Records && data.Records.length > 0) {
                        await commit("SET_CINEMA_SETTING", data['CinemaSettings'] && data['CinemaSettings'].length > 0 ? data['CinemaSettings'][0] : {})
                        await commit('SET_OFFERS_LIST', data['Records'] || [])
                    }
                } else Vue.toasted.show(data.message || 'Opps! Something went wrong');
            }).catch((error) => {
                Vue.toasted.show("Catch Error on fetchOffersList", error);
            }).finally(() => {
                // setTimeout(() => loader.hide(), 1000);
            });
    },
    fetchVerificationList: async ({ dispatch, commit, state }, payload) => {
        MarketingService.GetVerificationList()
            .then(response => {
                const { data } = response;
                if (data && data.status) {
                    if (data.Records.length > 0) commit('SET_OFFERS_VERIFICATION_LIST', data.Records);
                } else Vue.toasted.show(data.message || 'Opps! Something went wrong');
            })
            .catch(error => Vue.toasted.show("Catch Error on fetchVerificationList", error))
            .finally(() => {
                // setTimeout(() => loader.hide(), 1000);
            });
    },
    fetchCustomerCategoriesList: async ({ dispatch, commit, state }, payload) => {
        MarketingService.GetCustomerCategories()
            .then(response => {
                const { data } = response;
                if (data && data.status && data.Records) {
                    if (data.Records.length > 0) {
                        commit('SET_OFFERS_CATEGORIES_LIST', data.Records)
                    }
                } else Vue.toasted.show(data.message || 'Opps! Something went wrong');
            })
            .catch(error => {
                Vue.toasted.show("Catch Error on fetchCustomerCategoriesList", error)
            })
            .finally(() => {
                // setTimeout(() => loader.hide(), 1000);
            });;
    },
    getOfferVerificationName: async ({ dispatch, commit, state }, payload) => {
        // state.offerVerificationList.filter((x)=> x.)
    },
    getOfferCategoriesName: async ({ dispatch, commit, state }, payload) => {

        let list = payload && payload.length > 0 ? payload.split(', ') : [];
        let StrName = '';
        let promiselist = list.map((x) => {
            let a = state.offerCategoriesList.filter((y) => String(x) === String(y.buc_id));
            if (a.length > 0) {
                StrName += StrName.length > 0 ? `, ${a[0].buc_category_name}` : `${a[0].buc_category_name}`
            }
        });
        await Promise.all(promiselist);
        console.log('StrName :', StrName);
        return StrName
    }
};

const getters = {
    getOffersCollection: state => state.OffersList,
    showViewOffers: state => state.offersPosFlags.isPosOffersShow,
    getCinemassettings: state => state.CinemaSettings
};

export default {
    state,
    mutations,
    actions,
    getters
}
