import Api from './Api';

export default {
    GetDailyReportList(payload) {
        return Api().post('/api/admin/getdailysalesreports', payload);
    },
    GetFilmWiseCollectionReport(payload) {
        return Api().post('/api/admin/getfilmwisecollectionreports', payload);
    },
    GetPerformanceReportList(payload) {
        return Api().post('/api/admin/getperformancereports', payload);
    },
    GetAggregatorsReportList(payload) {
        return Api().post('/api/admin/getaggregatorreports', payload);
    },
    GetScreenWiseCollectionReport(payload) {
        return Api().post('/api/admin/getscreenwisecollectionsreports', payload);
    },
    GetDistributorReportList(payload) {
        return Api().post('/api/admin/getdistributorwisecollectionsreports', payload);
    },
    GetDistributorMovie(payload) {
        return Api().post('/api/admin/getdistributorsmoviesbydistributor', payload);
    },
    GetSummaryReportList(payload) {
        return Api().post('/api/admin/getsummaries', payload);
    }

}
