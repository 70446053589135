export default {
  release: 'Asiento de liberació',
  seatorder: 'Orden',
  SeatType: 'Tipo de asiento',
  generatebill: 'Generar factura',
  noofpos: 'No. de POS',
  kiosk: 'No. de quiosco',
  noofsignage: 'No. de señalización',
  fnbcounter: 'Contador F & B',
  department_name: 'Departamento',
  employeecode: 'Código de empleado',
  select_user_Department: 'Por favor seleccione departamento',
  select_user_Cinema: 'Por favor seleccione nombre de usuario',
  select_user_Designation: 'Por favor seleccione designación',
  select_user_Roles: 'Por favor seleccione rol',
  user_select_Country: 'Por favor seleccione país',
  user_select_State: 'Por favor seleccione estado',
  selectgender: "Por favor seleccione el género.",
  selectmachine: "Seleccionar máquina",
  cinemacode: "Código de cine",
  mobilecode: "Código móvil",
  resend: "Reenviar",
  confirmpassword: "Confirmar contraseña",
  fnbitem: "Artículo F&B",
  itemcostprice: "Artículo Costo Precio",
  NoRecords: "No se encontrarón archivos",
  designation: 'Designacion | Designaciones',
  add: 'Añadir',
  orgcinema: 'No de cine',
  listofall: 'Lista de todos',
  incinematic: 'en cinemática',
  viewing: 'Visita',
  of: 'De',
  admin: 'Administración',
  status: 'Estado | Estados',
  edit: 'Editar',
  view: 'Ver',
  rawmaterials: 'Materias Prima | Materias Primas',
  sharetype: 'Tipo de acción',
  cinemaname: 'Nombre del cine',
  backuptype: 'Tipo de respaldo',
  backuptime: 'Tiempo de respaldo',
  active: 'Activo',
  list: 'Lista | Liza',
  blankfieldmsg: 'Este campo no puede estar vacío',
  update: 'Actualizar',
  reset: 'Reiniciar',
  code: 'Código',
  isocode: 'Código iso',
  statecode: 'Código del estado',
  cancel: 'Cancelar',
  details: 'Detalle | Detalles',
  createdby: 'Creado por',
  createdat: 'Creado en',
  updatedat: 'Actualizado en',
  updatedby: 'Actualizado por',
  modifiedby: 'Modificado por',
  modifiedat: 'Modificado en',
  save: 'Salvar',
  saveaddmore: 'Guardar y agregar más',
  home: 'Casa',
  master: 'Dominar | Maestros',
  bookingdiscount: 'Descuento de reserva | Descuento de reserva',
  movie: 'Película | Películas',
  schedule: 'Programar | Horarios',
  aggregator: 'Agregador | Agregadores',
  recipe: 'Receta | Recetas',
  bookingsource: 'Fuente de reserva | Fuentes de reserva',
  cinema: 'Cine | Cines',
  setting: 'Ajuste | Ajustes',
  backup: 'Apoyo | Copias de seguridad',
  stateprovince: 'Provincia del estado',
  charge: 'Cargar | Cargos',
  type: 'Tipo | Tipos',
  country: 'País',
  city: 'Ciudad | Ciudades',
  crew: 'Personal | Tripulaciones',
  currency: 'Moneda | Monedas',
  denomination: 'Denominación | Denominaciones',
  distributor: 'Distribuidor | Distribuidores',
  feature: 'Característica | Caracteristicas',
  fb: 'FnB | FnB',
  fnb: 'Bebida alimenticia | Alimentos y bebidas',
  price: 'Precio | Prices',
  configuration: 'Configuración | Configuraciones',
  food: 'Comida | Comida',
  category: 'Categoría | Las categorías',
  gender: 'Género | Género',
  genre: 'Género | Género',
  hardware: 'Hardware | Hardwares',
  language: 'Idioma | Idiomas',
  marital: 'Marital | Marital',
  module: 'Módulo | Módulos',
  format: 'Formato | Los formatos',
  payment: 'Pago | Pagos',
  mode: 'Modo | Modos',
  ticket: 'Boleto | Entradas',
  refund: 'Reembolso | Reembolsos',
  reason: 'Razón | Razones',
  role: 'Oficio | Trabajos',
  seat: 'Asiento | Asientos',
  reservation: 'Reserva | Reservaciones',
  shift: 'Cambio | Turnos',
  signage: 'Señalización | Señalizacion',
  state: 'Estado | Estados',
  unitofmeasurement: 'Unidad de medida | Unidad de Mediciones',
  vendor: 'Vendedor | Vendedores',
  weekday: 'Día laborable | Entre semana',
  ho: 'HO | HO',
  headoffice: 'Oficina central | Oficina central',
  productioncompany: 'Compania de produccion | Compañías de producción',
  name: 'Nombre',
  sistercompany: 'Compañía hermana',
  contactno: 'Contacto no.',
  emailid: 'Identificación de correo',
  tinnumber: 'Número de TIN',
  noof: 'No de',
  employee: 'empleada',
  taxnumber: 'Número de impuesto',
  taxtype: 'Tipo de impuesto',
  taxtypephld: 'Seleccione el formato de película',
  address: 'Dirección',
  manage: 'Gestionar',
  manager: 'Gerente',
  imagesetting: 'Ajuste de imagen',
  height: 'Altura',
  width: 'Anchura',
  ratio: 'Proporción',

  // NEW ADDED BY JAY
  trailer: ' Remolque | Remolques',
  poster: 'Póster | Carteles',

  // NEW ADDED BY AKASH
  releasedate: 'Fecha de lanzamiento',
  runtime: 'Tiempo de ejecución',
  nameofperson: 'Nombre de la persona',
  order: 'Orden',
  distribution: 'Distribución',
  content: 'Contenido',
  moviesearch: 'Búsqueda de peliculas',
  production: 'Producción',
  rate: 'Tarifa',
  description: 'Descripción',
  contract_start_date: 'Fecha de inicio del contrato',
  contract_end_date: 'Fecha de finalización del contrato',
  apiid: 'Id de api',
  authentcationcode: 'Código de autenticación',
  username: 'Nombre de usuario',
  password: 'Contraseña',
  ingredient: 'Ingrediente',
  quantity: 'Cantidad',
  selectusercategory: 'Seleccione la categoría de usuario',
  discount: 'Descuento',
  registration: 'Registro',
  license: 'Licencia',
  contact: 'Contacto',
  pincode: 'Código PIN',
  latitude: 'Latitud',
  longitude: 'Longitud',
  screen: 'Pantalla | Pantallas',
  operationtime: 'Tiempo de operacion',
  operationstarttime: 'Hora de inicio de la operación',
  operationendtime: 'Hora de finalización de la operación',
  cinemaconessionlevel: 'Nivel de concesión de cine',
  language: 'Idioma',
  onlinevisibility: 'Visibilidad en línea',
  sellinboxoffice: 'Vender en taquilla',
  cleaningtime: 'Hora de limpiar',
  cutofftime: 'Cortar el tiempo',
  advertisementtime: 'Tiempo de publicidad',
  seatreleasetime: 'Tiempo de lanzamiento del asiento',
  english: 'Inglesa',
  arabic: 'Arábica',
  spanish: 'Española',
  currencycode: 'Código de moneda',
  isfirstdayoftheweek: 'Es el primer día de la semana',
  weekdayisoff: 'Es semana libre',
  parent: 'Madre',
  workinghour: 'Horas laborales',
  starttime: 'Hora de inicio',
  endtime: 'Hora de finalización',
  applicableto: 'Aplicable a',
  categoryname: 'nombre de la categoría',
  backuplocationbrowse: 'Ubicación de copia de seguridad Navegar',
  provideavalidname: 'Proporcionar un nombre de ruta válido',
  daily: 'Diaria',
  select: 'Seleccionar',
  selection: 'Selección',
  howlongbackuptokeep: '¿Cuánto tiempo de copia de seguridad para mantener',
  selectdateandtime: 'Seleccionar fecha y hora',
  monthly: 'Mensual',
  yearly: 'Anual',
  fortnight: 'Quincena',
  weekly: 'Semanal',
  backuprestore: 'Copia de seguridad de restauracion',
  ratings: 'Calificaciones',
  selected: 'Seleccionada',
  plot: 'Trama',
  trailerlink: 'Enlace de remolque',
  imageurl: 'URL de la imagen',
  value: 'Valor',
  fromdate: 'Partir de la fecha',
  todate: 'Hasta la fecha',
  time: 'Hora',
  gold: 'Oro',
  silver: 'Plata',
  tax: 'Impuesto',
  taxamount: 'Importe del impuesto',
  receipename: 'Seleccionar receta',
  invalidentry: 'Entrada invalida',
  hardwareuniquecode: 'Código único de hardware',
  noteremarks: 'Notas / Observaciones',
  connectionport: 'Puerto de conexión',
  authenticationcode: 'Código de autenticación',
  key: 'llave',
  tooltip: 'información sobre herramientas',
  starttime: 'Hora de inicio',
  endtime: 'Hora de finalización',
  sun: 'Dom',
  mon: 'Lun',
  tue: 'mar',
  wed: 'Mie',
  thu: 'Jue',
  fri: 'Vie',
  sat: 'Sab',
  user: 'Usuaria | Usuarios',
  report: 'Informe',
  delete: 'Borrar',
  middlename: 'Segundo nombre',
  lastname: 'Apellido',
  titleempty: 'El título no puede estar vacío',
  dob: 'Fecha de nacimiento',
  doj: 'Fecha de inscripción',
  zipcode: 'Código postal',
  phone: 'Teléfono',
  mobiles: 'Móvil',
  employeeid: 'ID de empleado',
  employeetype: 'Tipo de empleo',
  no: 'Número',
  access: 'Acceso',
  forgot: 'Olvidó',
  signin: 'Registrarse',
  change: 'Cambio',
  old: 'Antigua',
  enternew: 'Entrar nuevo',
  confirmnew: 'Confirmar Nuevo',
  update: 'Actualizar',
  forgot: 'Olvidó',
  email: 'Correo electrónico',
  alert: 'Alerta',
  vendormapping: 'Vendor Mapping',
  generatepurchaseorder: 'Generar orden de compra',
  pricelabel: 'Etiqueta de precio',
  amount: 'Cantidad',
  allbooking: 'Todas las reservas',
  searchbooking: 'Buscar reservas',
  search: 'Buscar',
  datetime: 'Fecha y hora',
  booking: 'Reserva',
  confirmationid: 'ID de confirmación',
  paymentsummary: 'Resumen de pago',
  vendormapping: 'Mapeo de proveedores',
  submit: 'Enviar',
  platinum: 'Platino',
  startdate: 'Fecha de inicio',
  enddate: 'Fecha final',
  showenddate: 'Mostrar fecha de finalización',
  ratecard: 'Tarjeta de tarifa',
  inclusivoftax: 'Inclusivo de impuestos',
  calculatesplit: 'Calcular Split',
  clientlogo: 'Logo del cliente',
  organisationlogo: 'Logotipo de la organización',
  oganization: 'Organización',
  setupdate: 'Fecha de configuración',
  launchdate: 'Fecha de lanzamiento',
  isPercentage: 'Es porcentaje',
  isAddition: 'Es la suma',
  isTaxable: 'Es imponible',
  grossAmount: 'Cantidad bruta',
  netAmount: 'Importe neto',
  share: 'Compartir',
  shareValue: 'Valor de la acción',
  sharelabel: 'Compartir etiqueta',
  fixedValueToTheatre: 'Valor fijo al teatro',
  isPercentageValue: 'Es el valor porcentual',
  contract: 'Contrato',
  fixedValueShare: 'Cuota de valor fijo',
  percentgeOf: 'Porcentaje de',
  duration: 'Duración',
  floatAmount: 'Cantidad flotante',
  shiftno: 'Turno no.',
  date: 'Fecha',
  operation: 'Operación',
  currency: 'Moneda',
  onlineDisplay: 'Visualización en línea',
  officeBookingStatus: 'Estado de reserva de oficina',
  onlineBookingStatus: 'Estado de reserva en línea',
  show: 'Mostrar',
  showCancelReason: 'Mostrar motivo de cancelación',
  enable: 'Habilitar',
  profile: 'Perfil',
  management: 'Administración',
  counters: 'Contadores',
  bookingusercategory: 'categoría de usuario de reserva',
  organization: 'Organización',
  seattype: 'Tipo de asiento',
  imagesettingname: 'Nombre de configuración de imagen',
  quantityAlert: 'Alerta de cantidad',
  stockAlert: 'Alerta de stock',
  chargetypecode: 'Código de tipo de carga',
  countrycode: 'Código de país',
  screenname: 'Nombre de pantalla',
  noofseats: 'No de asientos',
  noofcols: 'No de cols',
  noofrows: 'No de filas',
  format: 'Formato',
  overandabove: 'Está por encima y por encima',
  sharewithdistributor: 'Se puede compartir con el distribuidor',
  taxable: 'Es imponible',
  chargesettingname: 'ajuste de carga',
  ratedCardName: 'Nombre de tarjeta clasificada',
  seatlayout: 'Diseño del asiento',
  noofscreens: 'No de pantallas',
  location: 'Ubicación',
  showtime: 'Tiempo de la funcion',
  refundReasons: 'Motivos de reembolso',
  selectConcessionLevel: 'Seleccionar nivel de concesión',
  purchase: 'Compra',
  items: 'Artículos',
  totalamount: 'Cantidad total',
  purchaseorderdate: 'Fecha de orden de compra',
  purchaseorderlock: 'Orden de compra de bloqueo',
  isdefaultvendor: 'Es el proveedor predeterminado',
  deparmentcode: 'Código Del Departamento',
  isperishable: 'Es perecedero',
  rm_rte: 'Listo para comer',
  recco: 'Recco',
  foodcategoryname: 'Nombre de categoría de alimentos',
  parentfoodcategoryname: 'Categoría de alimentos para padres',
  foodorder: 'Orden en pos',
  Profile: 'Perfil',
  isparent: 'Es padre',
  expiry: 'Expiración',
  days: 'Dias',
  recipeiscombo: 'La receta es comdo',
  fnbitems: 'Artículo F&B',
  recipename: 'Nombre de receta',
  itemname: 'Nombre del árticulo',
  itemcode: 'Código del objeto',
  foodcategory: 'Categoría de comida',
  uploadfoodimage: 'Subir imagen de comida',
  isveg: 'Es vegetal',
  boxoffice: 'Taquilla',
  usersshift: 'Cambio de usuario',
  start: 'comienzo',
  end: 'Fin',
  in: 'en',
  date: 'Fecha',
  concessionstock: 'Stock de concesión',
  concession: 'Concesión',
  fnbPricing: 'Precios de F&B',
  fnbWastage: 'F&B desperdicio',
  accountableuser: 'Usuario capaz de la cuenta',
  approvedby: 'Aprobado por',
  fineamount: 'Cantidad fina',
  netamount: 'Importe neto',
  grossamount: 'Cantidad bruta',
  concessionLevel: 'Nivel de concesión',
  orderNo: 'N º de pedido',
  stockinQuantity: 'Stock en cantidad',
  cash: 'Efectivo',
  given: 'Dada',
  all: 'Todas',
  release: 'Lanzamiento',
  running: 'Corriendo',
  action: 'Acción',
  adventure: 'Aventuras',
  scifi: 'Ciencia ficción',
  castCrew: 'Reparto',
  synopsis: 'Sinopsis',
  creditDebit: 'Tarjeta de crédito / débito',
  cashlessPayment: 'Pago sin efectivo',
  splitPayments: 'Pagos divididos',
  couponPromotion: 'Cupón / Promoción',
  voucher: 'Vale',
  loyaltyCard: 'Tarjeta de fidelidad',
  payBy: 'Pagado por',
  number: 'número',
  idNumber: 'Número de identificación',
  additionalChanges: 'Cargos adicionales',
  debitNcreditCard: 'Tarjeta de débito / tarjeta de crédito',
  occupancy: 'Ocupación',
  close: 'Cerrar',
  book: 'Libro',
  pickup: 'Recoger',
  damage: 'Dañar',
  roll: 'Rodar',
  new: 'nueva',
  checkout: 'Revisa',
  back: 'atrás',
  admits: 'Admite',
  unpaid: 'No pagado',
  available: 'Disponible',
  done: 'Hecho',
  print: 'Impresión',
  reprint: 'Reimprimir',
  image: 'Imagen',
  enter: 'Entrar',
  proceed: 'Proceder',
  addDirectlyToStore: 'Agregar directamente a la tienda',
  uploaduserimage: 'Subir imagen de usuario',
  collection: 'Colección',
  film: 'Película',
  wise: 'Sabia',
  sale: 'Ventas',
  uom: 'UOM',
  transferred: 'Transferir | Transferida',
  in: 'En',
  love: 'Amor',
  recliner: 'Sillón reclinable',
  wheelchair: 'Silla de ruedas',
  taken: 'Tomada',
  your: 'Tu',
  full: 'Completa',
  exit: 'Salida',
  screen_title: 'Todos los ojos de esta manera',
  skip: ' Omitir',
  total: 'Total',
  vipSeat: 'VIP Asiento',
  regularSeat: 'Regular Asiento',
  noSeat: 'No Asiento',
  sofa: 'Sofá',
  loveSeat: 'Amor Asiento',
  reports: 'Informes',
  rolemanagement: 'Gestión de roles',
  iscinemalevel: 'Rol de cine',
  departmentid: 'ID Del Departamento',
  shiftstartdate: 'Fecha de inicio de turno',
  shiftenddate: 'Fecha de finalización del turno',
  type: 'Tipo',
  dol: 'Fecha de partida',
  Renunciation: 'Renuncia',
  rol: 'Razón para irse',
  verificationtype: 'Tipo de verificación',
  uploadbinnumbers: 'Subir archivo de número de contenedor',
  uploadbanklogo: 'Subir logotipo del banco',
  bank: 'Banco',
  weekdaymsg: 'seleccione al menos un día',
  applicablemsg: 'seleccione al menos una casilla de verificación de tipo aplicable',
  redeematmsg: 'seleccione al menos un reedem en la casilla de verificación',
  yes: 'SI',
  no: 'NO',
  institution: 'Institución',
  offertypes: 'Tipos de oferta',
  offer: 'Oferta',
  customercode: 'Código de cliente',
  customer: 'cliente',
  marketingtype: 'Tipo de mercadeo',
  cardtype: 'Tipo de tarjeta',
  bankmsg: "Por favor seleccione Banco ",
  mobileno: "Número de contacto",
  marketTypemsg: "Por favor seleccione el tipo de comercialización",
  weekends: 'Fines de semana',
  binNumbers: 'Números de papelera',
  upload: 'Subir',
  customer: 'Cliente | Clientes',
  anniversary: 'Aniversario',
  verified: 'Verificada',
  customerisactive: 'El cliente está activo',
  icon: 'Icono',
  promotionsandoffer: 'Promociones & Ofertas',
  maxdiscountpertransaction: 'Descuento máximo por transacción',
  cancombinewithotheroption: 'Se puede combinar con otra opción',
  buyandgetcombo: 'Compra y obtén Combo',
  offerapplicableat: 'Oferta aplicable en',
  customercategoryoffer: 'Oferta de categoría de cliente',
  student: 'Estudiante',
  corporate: 'Corporativa',
  validateby: 'Validar por',
  offerprice: 'Precio de oferta',
  specialInstruction: 'Instrucción especial',
  inmins: 'en minutos',
  useallloyaltyofferswiththiscard: 'Use todas las ofertas de lealtad con esta tarjeta',
  loyaltycustomer: 'Cliente Lealtad',
  selectloyaltyprogram: 'Seleccione el programa de fidelización',
  initialloadamt: 'Carga inicial Amt',
  balance: 'Equilibrar',
  joinedprogramon: 'Programa unido en',
  expirydateifany: 'Fecha de vencimiento (si corresponde)',
  noofticketpurchased: 'No de boleto comprado',
  noffamountsaved: 'No de cantidad ahorrada',
  unitconverter: 'Convertidor de unidades',

  //Menu items
  cinemasettings: 'Cine Ajuste',
  backupsettings: 'Apoyo Ajuste',
  chargetypes: 'Cargar Tipo',
  fnbconfig: 'F&B Configuraciones',
  fnbprice: 'F&B Precio',
  foodcategories: 'Comida Las categorías',
  martialstatus: 'Estado civil',
  movieformat: 'Formato de película',
  paymentmode: 'Modo de pago',
  ticketpriceconfiguration: 'Configuración del precio del boleto',
  ticketprice: 'Precio del boleto',
  ticketpricing: 'Precios de los boletos',
  refundreason: 'Razones de reembolso',
  seatreservationtype: 'Tipo de reserva de asiento',
  moviecategory: 'Categoría de película',
  signagetype: 'Tipo de señal',
  rawmaterialsCategories: 'Categorías de materias primas',
  rawmaterialcategories: 'Categorías de materias primas',
  //Master dashboard descriptions  
  movie_dash_msg: 'Lista de todas las películas y su descripción en cinemática.',
  schedule_dash_msg: 'Todos los horarios de películas para cada pantalla en un cine.',
  aggregator_dash_msg: 'Aplicaciones de terceros que utilizan cinemática para la reserva de entradas y módulos de pedido de F&B.',
  recipe_dash_msg: 'Una lista de ingredientes con la cantidad requerida para preparar un plato y venderlo.',
  bookingsource_dash_msg: 'Los boletos se pueden reservar en línea o en POS o en KIOSK o aplicaciones de terceros, son fuentes de reservaciones',
  designation_dash_msg: 'Designación de todos los usuarios en cinemática. Por ejemplo: gerente de cuentas, gerente de cine, etc.',
  charge_dash_msg: 'Las adiciones o deducciones que se aplican en el monto del boleto, como impuestos, tarifa adicional, etc.',
  rawmaterial_dash_msg: 'Lista de todas las materias primas para Alimentos y Bebidas.',
  country_dash_msg: 'Lista de todos los países en cinemática.',
  city_dash_msg: 'Lista de todas las ciudades en un estado / provincia particular agregada a cinemática',
  crew_dash_msg: 'Un grupo de personas que trabajan en una película, por ejemplo: Director, Productor, Actor, Actriz, etc.',
  currency_dash_msg: 'Lista de todas las monedas en cinemática.',
  denomination_dash_msg: 'La denominación de moneda disponible para la moneda seleccionada en cinemática.',
  distributor_dash_msg: 'Un distribuidor es responsable de la comercialización de una película y obtienen una parte de los ingresos en los boletos vendidos',
  feature_dash_msg: 'Todas las funciones de CRUD en un módulo como agregar, editar, ver, actualizar, etc.',
  fb_price_config_dash_msg: 'El precio mínimo y máximo se establece para cada artículo que se vende en un cine.',
  fnb_price_dash_msg: 'Cálculo del precio de venta de un artículo en un cine.',
  food_category_dash_msg: 'Es una colección de alimentos que comparten propiedades similares como bebidas, bebidas calientes, bebidas frías, etc.',
  movie_category_dash_msg: 'La película clasificada como cuando se proyecta como Ahora se muestra, Próximo cambio, Próximamente, etc.',
  gender_dash_msg: 'Listado de género en cinemática.',
  genre_dash_msg: 'Ej .: Drama, suspense, romantico etc.',
  hardware_dash_msg: 'Máquinas y otros equipos utilizados en cine para Entradas y F&B.',
  language_dash_msg: 'Lista de todos los idiomas en el sistema utilizado para contenido o película',
  marital_dash_msg: 'Lista de estado civil en cinemática.',
  module_dash_msg: 'Los módulos en cinemática como película, programación, receta etc.',
  format_dash_msg: 'Por ejemplo: 2D, 3D, etc.',
  payment_dash_msg: 'Diferentes modos para comprar un boleto o pagar una factura.',
  ticket_price_config_dash_msg: 'El precio mínimo y máximo se establece para los asientos según el tipo de asiento en todas las pantallas',
  ticket_dash_msg: 'El precio de venta de un boleto derivado de todos los hechos, como el tipo de película, la hora de presentación, la fecha de presentación, etc.',
  refund_dash_msg: 'Lista de motivos para cancelar un ticket.',
  production_dash_msg: 'Listado de todas las productoras de películas',
  role_dash_msg: 'Roles de acceso para usuarios en cinemática.',
  seat_dash_msg: 'Reservas de asientos como asientos de la casa, asientos permanentes bloqueados, etc.',
  status_dash_msg: 'El estado del ticket y el pedido en F&B. Ej .: Boleto reservado, Boleto cancelado, F&B pedido realizado, etc.',
  shift_dash_msg: 'El cambio es un período de tiempo múltiple establecido en un día durante el cual diferentes grupos de personas realizan sus tareas',
  signage_dash_msg: 'La señalización es una pantalla para comunicar el espectáculo de una película en un cine.',
  state_dash_msg: 'Lista de todos los estados / provincias en un país',
  unitofmeasurement_dash_msg: 'Unidad de medida de materias primas. Ej .: Kilogramo, Litro, etc',
  vendor_dash_msg: 'Una empresa o una persona que ofrece materia prima para la venta al cine.',
  weekday_dash_msg: 'Todos los días en una semana. Te permite configurar el primer día de la semana.',
  cinemasettings_dash_msg: 'Lista de tripulación en películas, por ejemplo: Director, Actores, etc.',
  chargetypes_dash_msg: 'Lista de tipos de carga para la cinemática. Ej: IVA, impuesto de entretenimiento, etc.',
  martialstatus_dash_msg: 'administrar el estado civil',
  refunds_dash_msg: 'Cancelación de entradas o motivos de cancelación de la orden que lleva al reembolso.',
  managefearture_msg: 'Gestione toda la designación en su',
  cinemauser: 'Administrar todos los usuarios',
  cinemasscreens: 'Manage All Screens',
  cinemahardware_dash_msg: 'Administrar todo el hardware',
  cinemalist_dash_msg: 'Administrar todos los usuarios',
  roleaccess_dash_msg: 'Gestiona todos tus roles de acceso',
  bookingusercategory_dash_msg: 'La categoría de usuario para la cual se reserva el boleto, por ejemplo: estudiantes, personas mayores, personal de la fuerza armada, etc.',
  department_msg: 'Lista de todos los departamentos en Brij',
  unit: 'Unidad',
  subtotal: 'Total Parcial',
  shipping: 'Envío',
  iscountable: 'Es contable',
  promotionsandoffer_dashmsg: 'Administra todas las promociones y ofertas',
  title: 'Título',
  redemptionOutlet: 'Salida de redención',
  canavailonline: 'Puede aprovechar en línea',
  availatboxoffice: 'Avail at Box Office',
  availatconcession: 'Aprovechar en concesión',
  offerconfiguration: 'Configuración de oferta',
  applicableforparticularmovie: 'Aplicable para una película en particular?',
  applicableforspecificdaysdatetime: 'Aplicable para días específicos, fecha, hora',
  applicableforselectedcinemasnclasses: 'Aplicable para cines & clases seleccionados',
  buyngetfreeticket: 'Compre y obtenga un boleto gratis',
  buy: 'Comprar',
  get: '& obtener',
  ofticketsfree: 'de entradas gratis',
  minimumticketpurchase: 'Compra mínima de boletos',
  maximumfreeticketpertransaction: 'Máximo boleto gratis por transacción',
  cancombinewithotheroffers: 'Se puede combinar con otras ofertas',
  discounts: 'Descuentos',
  specialinstructonregardingoffer: 'Instrucción especial sobre la oferta',
  promocode: 'Código promocional',
  send: 'Enviar',
  applicablefor: 'aplicable para',
  transactions: 'Actas',
  show: 'espectáculo',
  vouchersissuedbypartnername: 'Cupones emitidos por nombre de socio',
  noofvoucherstobegenerated: 'No de vales para generar',
  foramount: 'por cantidad',
  redemptionvalidfrom: 'Redención válida desde',
  termsandconditions: 'Términos y condiciones',
  generate: 'Generar',
  loyalty: 'Lealtad',
  program: 'Programa',
  level: 'Nivel',
  minimumpointsforredemption: 'Puntos mínimos para canjear',
  maximumpointstoberedeemedpertransaction: 'Puntos máximos para canjear por transacción',
  requirementtoenroll: 'Requisito para inscribirse',
  age: 'Años',
  proof: 'Prueba',
  eligibilitytoenroll: 'Elegibilidad para inscribirse',
  earnpoints: 'Gana puntos',
  pointsfromamount: 'puntos de cantidad',
  spent: 'gastado',
  burnpoints: 'Grabar puntos',
  earnpointsonlyonnondiscountedamount: 'Gane puntos solo por un monto sin descuento',
  eligiblewithotheroffers: 'Elegible con otras ofertas',
  eligibleforfreetickets: 'Elegible para entradas gratis',
  ticketsper: 'Entradas por',
  showpriority: 'Mostrar prioridad',
  prepaidcard: 'Tarjeta de prepago',
  cardname: 'Tarjeta de prepago',
  cardserialnostart: 'Tarjeta de serie sin inicio',
  cardserialnoends: 'Tarjeta de serie sin extremos',
  cardvalidityfromdate: 'Validez de la tarjeta desde la fecha',
  cardexpirydate: 'fecha de expiración de la tarjeta',
  loadamount: 'Load amount',
  getextra: 'obtener más',
  maxloadamount: 'cantidad máxima de carga',
  maxextraamount: 'cantidad extra máxima',
  freemovieoffer: 'Oferta de películas gratis',
  watch: 'Reloj',
  free: 'Gratis',
  spendamount: 'Gastar cantidad',
  for: 'para',
  moviesandget: 'películas y obtener',
  useallloyaltyofferswiththiscard: 'Use all Loyalty offers with this card',
  storemanagement: 'Administración de la tienda',
  documentvarification: 'Verificación de documentos',
  document: 'Documento',
  toverify: 'Para verificar',

  // manage: 'gestionar',
  // manager: 'gerente',
  // //Menu items
  // cinemasettings: 'Cine Ajuste',
  // backupsettings: 'Apoyo Ajuste',
  // chargetypes: 'Cargar Tipo',
  // fnbconfig: 'F&B Configuraciones',
  // fnbprice: 'F&B Precio',
  // foodcategories: 'Comida Las categorías',
  // martialstatus: 'Estado civil',
  // movieformat: 'Formato de película',
  // paymentmode: 'Modo de pago',
  // ticketpriceconfiguration: 'Configuración del precio del boleto',
  // ticketprice: 'Precio del boleto',
  // refundreason: 'Razones de reembolso',
  // seatreservationtype: 'Tipo de reserva de asiento',
  // moviecategory: 'categoría de película',
  // signagetype: 'Tipo de señal',
  // //Master dashboard descriptions  
  // movie_dash_msg: 'Lista de todas las películas y su descripción en cinemática.',
  // schedule_dash_msg: 'Todos los horarios de películas para cada pantalla en un cine.',
  // aggregator_dash_msg: 'Aplicaciones de terceros que utilizan cinemática para la reserva de entradas y módulos de pedido de F&B.',
  // recipe_dash_msg: 'Una lista de ingredientes con la cantidad requerida para preparar un plato y venderlo.',
  // bookingsource_dash_msg: 'Los boletos se pueden reservar en línea o en POS o en KIOSK o aplicaciones de terceros, son fuentes de reservaciones',
  // designation_dash_msg: 'Designación de todos los usuarios en cinemática. Por ejemplo: gerente de cuentas, gerente de cine, etc.',
  // charge_dash_msg: 'Las adiciones o deducciones que se aplican en el monto del boleto, como impuestos, tarifa adicional, etc.',
  // rawmaterial_dash_msg: 'Lista de todas las materias primas para Alimentos y Bebidas.',
  // country_dash_msg: 'Lista de todos los países en cinemática.',
  // city_dash_msg: 'Lista de todas las ciudades en un estado / provincia particular agregada a cinemática',
  // crew_dash_msg: 'Un grupo de personas que trabajan en una película, por ejemplo: Director, Productor, Actor, Actriz, etc.',
  // currency_dash_msg: 'Lista de todas las monedas en cinemática.',
  // denomination_dash_msg: 'La denominación de moneda disponible para la moneda seleccionada en cinemática.',
  // distributor_dash_msg: 'Un distribuidor es responsable de la comercialización de una película y obtienen una parte de los ingresos en los boletos vendidos',
  // feature_dash_msg: 'Todas las funciones de CRUD en un módulo como agregar, editar, ver, actualizar, etc.',
  // fb_price_config_dash_msg: 'El precio mínimo y máximo se establece para cada artículo que se vende en un cine.',
  // fnb_price_dash_msg: 'Cálculo del precio de venta de un artículo en un cine.',
  // food_category_dash_msg: 'Es una colección de alimentos que comparten propiedades similares como bebidas, bebidas calientes, bebidas frías, etc.',
  // movie_category_dash_msg: 'La película clasificada como cuando se proyecta como Ahora se muestra, Próximo cambio, Próximamente, etc.',
  // gender_dash_msg: 'Listado de género en cinemática.',
  // genre_dash_msg: 'Ej .: Drama, suspense, romantico etc.',
  // hardware_dash_msg: 'Máquinas y otros equipos utilizados en cine para Entradas y F&B.',
  // language_dash_msg: 'Lista de todos los idiomas en el sistema utilizado para contenido o película',
  // marital_dash_msg: 'Lista de estado civil en cinemática.',
  // module_dash_msg: 'Los módulos en cinemática como película, programación, receta etc.',
  // format_dash_msg: 'Por ejemplo: 2D, 3D, etc.',
  // payment_dash_msg: 'Diferentes modos para comprar un boleto o pagar una factura.',
  // ticket_price_config_dash_msg: 'El precio mínimo y máximo se establece para los asientos según el tipo de asiento en todas las pantallas',
  // ticket_dash_msg: 'El precio de venta de un boleto derivado de todos los hechos, como el tipo de película, la hora de presentación, la fecha de presentación, etc.',
  // refund_dash_msg: 'Lista de motivos para cancelar un ticket.',
  // production_dash_msg: 'Listado de todas las productoras de películas',
  // role_dash_msg: 'Roles de acceso para usuarios en cinemática.',
  // seat_dash_msg: 'Reservas de asientos como asientos de la casa, asientos permanentes bloqueados, etc.',
  // status_dash_msg: 'El estado del ticket y el pedido en F&B. Ej .: Boleto reservado, Boleto cancelado, F&B pedido realizado, etc.',
  // shift_dash_msg: 'El cambio es un período de tiempo múltiple establecido en un día durante el cual diferentes grupos de personas realizan sus tareas',
  // signage_dash_msg: 'La señalización es una pantalla para comunicar el espectáculo de una película en un cine.',
  // state_dash_msg: 'Lista de todos los estados / provincias en un país',
  // unitofmeasurement_dash_msg: 'Unidad de medida de materias primas. Ej .: Kilogramo, Litro, etc',
  // vendor_dash_msg: 'Una empresa o una persona que ofrece materia prima para la venta al cine.',
  // weekday_dash_msg: 'Todos los días en una semana. Te permite configurar el primer día de la semana.',
  // cinemasettings_dash_msg: 'Lista de tripulación en películas, por ejemplo: Director, Actores, etc.',



  // TOOLTIP  NEW ADDED BY AKASH
  crew_tooltip: 'Nombre de la tripulación, por ejemplo: Actor, Director.',
  genre_tooltip: 'Un tipo o variedad particular a la que pertenece una película, por ejemplo: Thriller, Romántico, etc.',
  country_tooltip: 'Nombre del país',
  country_code_tooltip: 'Forma corta para el nombre del país',
  country_iso_tooltip: 'Código definido por la Organización Internacional de Normalización (ISO) para el nombre de los países',
  city_tooltip: 'Nombre de la ciudad',
  distributor_name_tooltip: 'Nombre de la empresa del distribuidor',
  sistercompany_tooltip: 'Hermana Nombre de la empresa si existe',
  contactno_tooltip: 'Número de contacto del distribuidor para la comunicación.',
  emailid_tooltip: 'ID de correo electrónico del distribuidor para la comunicación',
  tinnumber_tooltip: 'Número registrado de la empresa',
  taxnumber_tooltip: 'Número de registro fiscal del distribuidor',
  distributor_address: 'Cuando la materia prima alcanza una cantidad particular, el administrador debe ser alertado de la misma para que pueda ordenar la materia prima.',
  distributor_cityname: 'Ciudad nombre del distribuidor',
  language_tooltip: 'Idioma para contenido o idioma de la película.',
  movie_format_tooltip: 'La dimensión visual de la película.',
  adddesignations_tooltip: 'Nombre de la designación, por ejemplo: gerente de cine, contador',
  searchmovie_tooltip: 'Buscar pelicula',
  aggregator_tooltip: 'Nombre del agregador',
  contract_start_date_tooltip: 'La fecha en que se habilitó el contrato en cinemática.',
  contract_end_date_tooltip: 'La fecha en la que se rescindirá el contrato.',
  apiid_tooltip: 'La ID única asignada al agregador para acceder a la API cinemática',
  authentcation_code_tooltip: 'El código de autenticación para proporcionar autenticación para acceder a la API cinemática.',
  username_tooltip: 'El nombre de usuario para acceder a la API cinemática.',
  aggregator_password_tooltip: 'La contraseña para acceder a la api cinemática.',
  recipe_name_tooltip: 'Nombre de la receta que se venderá en concesión.',
  ingredient_tooltip: 'Ingredientes / materias primas requeridas para preparar la receta.',
  recipe_quantity_tooltip: 'Cantidad de materia prima necesaria para preparar la receta.',
  recipe_uom_tooltip: 'Unidad de medida de materia prima necesaria para preparar la receta.',
  bookingdiscount_noof_tooltip: 'La fuente desde donde se reserva el ticket, por ejemplo: en línea, KIOSK, etc.',
  bookingdiscount_discount_tooltip: 'La fuente desde donde se reserva el ticket, por ejemplo: en línea, KIOSK, etc.',
  chargetypes_tooltip: 'Nombre del tipo de cargo, por ejemplo: impuesto sobre el valor añadido, impuesto sobre el servicio, impuesto sobre el entretenimiento',
  chargetype_code_tooltip: 'Forma corta del tipo de cargo, si corresponde, por ejemplo: IVA, ST, impuesto sobre el entretenimiento.',
  currency_tooltip: 'Nombre de la moneda',
  currencycode_tooltip: 'Los códigos de moneda ISO alfabéticos de tres letras que representan las distintas monedas utilizadas en todo el mundo',
  currency_country_tooltip: 'País al que pertenece la moneda.',
  isweekof_tooltip: 'El título no puede estar vacío',
  vendorname_tooltip: 'Nombre del vendedor',
  vendorsaddress_tooltip: 'Cuando la materia prima alcanza una cantidad particular, el administrador debe ser alertado de la misma para que pueda ordenar la materia prima.',
  status_tooltip: 'Nombre del estado para cada etapa de reserva',
  statuscode_tooltip: 'Forma corta para el nombre de estado',
  shiftname_tooltop: 'Nombre de turno por ejemplo: turno de mañana',
  shift_workinghour_tooltip: 'No de horas de trabajo en un turno.',
  shift_starttime_tooltip: 'Hora de inicio del turno.',
  shift_endtime_tooltip: 'Hora final del turno.',
  seatreservationtype_tooltip: 'Nombre de los diferentes tipos de asientos',
  role_tooltip: 'Nombre del Rol',
  productioncompany_tooltip: 'Nombre de la productora de cine.',
  paymentmode_tooltip: 'Modo de pago para comprar boletos o pedidos Alimentos y bebidas, por ejemplo: efectivo, tarjeta de crédito, billetera, etc.',
  hardware_tooltip: 'Las máquinas instaladas en el cine para ejecutar un cine, por ejemplo: POS, KIOSK, impresora, etc.',
  foodcategories_tooltip: 'Nombre de la categoría de alimentos, por ejemplo: bocadillos, bebidas, etc. Estas categorías también pueden tener subcategorías.',
  feature_tooltip: 'Cada módulo tiene diferentes características como Agregar usuario, Editar usuario, Ver detalles del usuario, etc.',
  backuplocationbrowse_tooltip: 'Seleccione la carpeta donde desea almacenar todas las copias de seguridad para Brij',
  denominationvalue_tooltip: 'El valor de la denominación, por ejemplo: 100, 200, etc.',
  denominationcurreny_tooltip: 'La moneda para la cual se guardará la denominación.',
  denominationcountry_tooltip: 'Seleccione el nombre del país para las denominaciones',
  tpcfromdate_tooltip: 'Fecha a partir de la cual se aplicará el precio.',
  tpctodate: 'El precio será aplicable hasta la fecha.',
  hardware_selectname_tooltip: 'nivel de concesión en cine',
  hardware_selecthardware_tooltip: 'información sobre herramientas',
  hardware_username_tooltip: 'información sobre herramientas',
  hardware_password_tooltip: 'información sobre herramientas',
  hardware_uniquecode_tooltip: 'información sobre herramientas',
  hardware_name_tooltip: 'información sobre herramientas',
  hardware_connectionport_tooltip: 'información sobre herramientas',
  hardware_authenticationcode_tooltip: 'informacion sobre herramientas',
  hardware_selectkey_tooltip: 'informacion sobre herramientas',
  booking_source_tooltip: 'Fuente de reserva de entradas, por ejemplo: POS, KIOSK, en línea, etc.',
  adddistributor_tooltip: 'Nombre de la empresa distribuidora',
  adddistributorsiscomp_tooltip: 'Nombre de la compañía hermana si existe',
  addDistributorcontactno_tooltip: 'Número de teléfono fijo o móvil para contactar',
  addDistributoremailId_tooltip: 'Identificación de correo electrónico para la comunicación',
  addDistributorTin_tooltip: 'Número registrado de estaño',
  addDistributorTax_tooltip: 'Número de información fiscal',
  distributorCountry_tooltip: 'El país donde se encuentra el distribuidor.',
  distributorState_tooltip: 'El estado o provincia donde se encuentra el distribuidor',
  distributorCity_tooltip: 'La ciudad donde se encuentra el distribuidor.',
  imagesetting_dash_msg: 'Tamaño de imagen predefinido que se cargará en el sistema',
  org_dash_msg: 'Detalles de la organización',
  bookingusercategory_tooltip: 'Categoría de usuario para el que se reserva el asiento. Por ejemplo: estudiantes, jubilados, personal de la fuerza armada, etc.',
  imagesettingname_tooltip: 'Nombra la configuración de la imagen con un resumen',
  movieFormatname: 'Video formalidad para película Ej .: 2D, 3D etc.',
  rating_tooltip: 'Califique la idoneidad de una película para ciertos públicos en función de su contenido, por ejemplo: PG 13+, PG 18, etc.',
  refundReasons_tooltip: 'Motivo por el que se debe iniciar un reembolso Por ejemplo: mostrar cancelado',
  moviecategory_tooltip: 'El estado del programa de películas, ya sea que se publique pronto o que ahora se muestre, etc.',
  state_tooltip: 'Nombre del estado o provincia',
  statecode_tooltip: 'Forma corta o código (si existe) para el estado o provincia',
  unitofmeasurement_tooltip: 'Magnitud definida de una cantidad, por ejemplo: kilogramo',
  uom_parent_tooltip: 'La mayor magnitud de una cantidad. No es obligatorio',
  uom_description: 'Explicación de la unidad de medida.',
  movietitle_tooltip: 'Nombre de la película',
  movieSelectCountry: 'El país de producción de la película.',
  movieproductioncompany_tooltip: 'La compañía o compañías que produjeron la película.',
  movieselectgenre_tooltip: 'Categoría de la película basada en similitudes, ya sea en los elementos narrativos o en la respuesta emocional, por ejemplo: documental de comedia.',
  movieselectlanguage_tooltip: 'El lenguaje hablado de la película.',
  movietrailer_tooltip: 'El enlace para el trailer de la película.',
  movieuti_tooltip: 'La imagen que se mostrará para representar el tamaño de la película 197X295, máximo 1mb',
  movieselectdistributor_tooltip: 'La compañía que distribuye la película en un área determinada.',
  movieselectcountry_tooltip: 'El país donde el distribuidor distribuye la película',
  movieselectstates_tooltip: 'Los estados donde el distribuidor distribuye la película',
  moviecity_tooltip: 'La ciudad donde el distribuidor distribuye la película.',
  moviefromdate_tooltip: 'Fecha de inicio de distribución',
  movietodate_tooltip: 'Fecha de finalización de distribución',
  movieselectcountry_tooltip: 'Detalles basados ​​en el país seleccione el país e ingrese otros detalles en consecuencia',
  movieReleaseDate_tooltip: 'La fecha de lanzamiento de la película en el país seleccionado.',
  movieRuntime_tooltip: 'El tiempo total de la película en minutos.',
  movieContentTitle_tooltip: 'El título de la película es el idioma seleccionado',
  movieDescription_tooltip: 'La descripción de la película para el idioma seleccionado',
  movieSelectCrew_tooltip: 'Personas que participan en la realización de una película, por ejemplo: productor, director.',
  movieNameOfThePerson_tooltip: 'Nombre de la tripulación seleccionada',
  movieOrder_tooltip: 'Orden en el que debe aparecer el miembro de la tripulación',
  cinemaSettingsName_tooltip: 'Nombre del cine con el nombre de la ubicación para ser precisos',
  cinemaSettingsRegistration_tooltip: 'El número de registro del gobierno para comenzar un cine / unidad',
  cinemaSettingsLicenseNo_tooltip: 'El número de licencia del cine / unidad',
  cinemaSettingsEmail_tooltip: 'Dirección de correo electrónico para la comunicación',
  cinemaSettingContactNo_tooltip: 'El número de contacto para la comunicación',
  cinemaSettingsAddress_tooltip: 'La ubicación del cine / unidad',
  cinemaSettingsCities_tooltip: 'La ciudad donde se encuentra el cine / unidad',
  cinemaSettingStates_tooltip: 'El estado o provincia donde se encuentra el cine / unidad',
  cinemaSettingsCountry_tooltip: 'El país donde se encuentra el cine / unidad',
  cinemaSettingsPincode_tooltip: 'El código PIN de la ubicación donde se encuentra el cine / unidad',
  cinemaSettingsLatitude_tooltip: 'La coordenada geográfica que especifica la posición norte-sur de la tierra.',
  cinemaSettingsLongitude_tooltip: 'La coordenada geográfica que especifica la posición este-oeste de la tierra.',
  cinemaSettingsNoofScreens_tooltip: 'El número de pantallas en el cine',
  cinemaSettingsOperation_tooltip: 'La hora de inicio de la operación en el cine',
  cinemaSettingsCleaningTime_tooltip: 'El tiempo de limpieza en minutos para el cine después de cada película',
  cinemaSettingsCutofTime_tooltip: 'El tiempo en minutos después del inicio de la película después del cual no se permite reservar',
  cinemaSettingsAdvertisementTime_tooltip: 'El tiempo extra antes del inicio de los anuncios de la película / Tráiler',
  cinemaSettingsSeatReleaseTime_tooltip: 'El tiempo en minutos antes del cual el asiento reservado necesita ser liberado para reservar',
  backupSettingCinemaCountry_tooltip: 'Seleccione cine para copia de seguridad de datos',
  backupSettingBackupLocationBrowse_tooltip: 'Ruta para el almacenamiento de respaldo',
  backupSettingDaily_tooltip: 'Diariamente a qué hora se realizará la copia de seguridad',
  backupSettingWeekly_tooltip: '¿En qué día de la semana se debe hacer una copia de seguridad?',
  backupSettingFortnight_tooltip: 'Dos fechas en un mes con un intervalo de 15 días cuando se realizará una copia de seguridad',
  backupSettingMonthly_tooltip: 'Cualquier fecha del mes en que se realizará la copia de seguridad',
  backupSettingAuto_tooltip: 'Copia de seguridad que se tomará automáticamente',
  quantityalert: 'Copia de seguridad que se tomará automáticamente',
  backupSettingBackupToKeep_tooltip: 'Seleccionó la carpeta de respaldo para restaurar',
  citiesCityName_tooltip: 'Nombre de la ciudad',
  citiesLatitude_tooltip: 'Punto 11',
  citiesLongitude_tooltip: 'Punto 12',
  citiesCountry_tooltip: 'Nombre del país al que pertenece la ciudad.',
  citiesState_tooltip: 'Nombre del estado al que pertenece el estado',
  imageSettingHeight_tooltip: 'altura de la imagen',
  imageSettingWidth_tooltip: 'Ancho de la imagen',
  imageSettingRatio_tooltip: 'Ratio de imagen',
  organizationName_tooltip: 'nombre de la organización',
  organizationLogourl_tooltip: 'Examine el tamaño y la dimensión de la URL del logotipo',
  organizationAddress_tooltip: 'La dirección de la oficina central de la organización',
  organizationPincode_tooltip: 'Código postal o código PIN de la organización',
  organizationCountry_tooltip: 'País donde se ubica la organización',
  organizationState_tooltip: 'Nombre del estado o provincia',
  organizationCity_tooltip: 'Ciudad donde se ubica la organización',
  organizationContactNumber_tooltip: 'Número de contacto para comunicación',
  organizationSetupDate_tooltip: 'Fecha en que se configuró la aplicación (BRIJ)',
  organizationLaunchDate_tooltip: 'Fecha de lanzamiento del software',
  signage_type: 'La pantalla de visualización donde se muestra la información de los horarios de películas',
  weekdays_tooltip: 'Todos los días de la semana solo establece el día de inicio de la semana',
  vendorsContactNumber_tooltip: 'Número de contacto del vendedor de comunicación',
  vendorsEmailId_toolip: 'El ID de correo electrónico para la comunicación',
  vendorsTinNumber: 'Número de identificación fiscal si existe o número GST',
  vendorsAddress_tooltip: 'La dirección del vendedor',
  vendorsCountry_tooltip: 'El pais del vendedor',
  vendorStates_tooltip: 'Estado / Provincia del vendedor',
  vendorCity_tooltip: 'Ciudad donde se encuentra el vendedor',
  select_start_date: 'seleccione fecha de inicio',
  select_end_date: 'seleccione fecha de finalización',
  deptname: 'Nombre de Departamento',
  gendername: 'Ingrese un género',
  maritalname: 'Ingresar estado civil',

  cinema_CinemaSettings: 'Nombre del cine o unidad. Un cine es un múltiplex en un lugar particular.',
  cinema_Location: 'Google ubicación del cine',
  cinema_Address: 'Complete address of the cinema/unit',
  cinema_Pincode: 'Código postal o código postal de la ubicación donde se encuentra el cine',
  cinema_ContactNumber: 'Número de contacto del director de cine para cualquier comunicación',
  cinema_EmailId: 'Identificación de correo electrónico para cualquier comunicación electrónica',
  cinema_NoOfScreens: 'No. de pantallas que tiene un cine',
  cinema_latitude: 'Latitud de la ubicación del cine',
  cinema_longitude: 'Longitud de la ubicación del cine',
  cinema_Cutoftime: 'El tiempo en minutos después del inicio del espectáculo cuando se mira se cerrará',
  cinema_license: 'El número de licencia registrado por el gobierno para el cine',
  cinema_RegistrationNo: 'El número de registro de cine registrado por el gobierno',
  cinema_OperationStarttime: 'El momento en que la operación comienza en el cine.',
  cinema_OperationEndtime: 'La hora de finalización cuando la operación comienza en el cine',
  screen_ScreenName: 'Nombre de las pantallas en cine Ej : Pantalla 1',
  screen_NoOfSeats: 'No total de asientos en pantallas particulares',
  screen_NoofCols: 'No. de columnas de asientos en una pantalla para crear una cuadrícula para el diseño del asiento',
  screen_NoofRows: 'No. de filas de asientos en una pantalla. para crear una cuadrícula para el diseño del asiento',
  screen_Cinema: 'Lista de cine. Seleccione un cine al que pertenece la pantalla',
  screen_Format: 'El formato de video compatible con la pantalla, por ejemplo: 2D, 3D, 4D, etc.',
  seatType_SelectCinemas: 'Seleccione Cine / Nombre de la unidad para agregar el tipo de asiento',
  seatType_SelectScreen: 'Seleccione la pantalla para agregar el tipo de asiento',
  seatType_SeatsTypes: 'Ingrese el nombre del tipo de asiento, por ejemplo: oro, plata, etc.',
  chargeSettings_selectCinema: 'Seleccione cine para la configuración de carga',
  chargeSettings_ChargeSettingsName: 'Nombre de referencia para la configuración de carga',
  chargeSettings_ChargeTypes: 'Diferentes tipos de cargos aplicables para boleto',
  chargeSettings_FromDate: 'Cargos aplicables a partir de qué fecha',
  chargeSettings_ToDate: 'Cargo aplicable hasta qué fecha. No obligatorio',
  chargeSetting_chargeValue: 'El valor del cambio Ej : 8%, $ 100 etc.',
  rateCard_Cinema: 'Seleccione cine para la hoja de tarifas',
  rateCard_ChargeSettingName: 'Seleccione el nombre de referencia para la configuración de carga',
  rateCard_RateCardName: 'Nombre de la hoja de tarifas',
  rateCard_Amount: 'La cantidad por la cual se calcularán los cargos',
  ticketPriceConfiguration_cinemas: 'Seleccione cine para configurar el precio del boleto',
  ticketPriceConfiguration_Movie: 'Seleccione la película para la que debe hacerse el precio del boleto',
  ticketPriceConfiguration_Screens: 'Seleccione la pantalla en la que se proyectará la película',
  ticketPriceConfiguration_FromDate: 'El precio del boleto aplicable a partir de qué fecha',
  ticketPriceConfiguration_ToDate: 'Precio del boleto aplicable hasta qué fecha',
  user_NameTooltip: 'Nombre del empleado',
  user_MiddleName: 'Segundo nombre del empleado',
  user_LastName: 'Apellido del empleado',
  user_Gender: 'El género del empleado.',
  user_DateOfBirth: 'Fecha de nacimiento del empleado',
  user_DateOfJoining: 'Fecha de inscripción',
  user_Address: 'Domicilio residencial del empleado',
  user_Country: 'País donde reside el empleado',
  user_State: 'Indique de dónde es el empleado',
  user_Cities: 'Ciudad de residencia del empleado',
  user_ZipCode: 'Código postal / código postal o código PIN de la ubicación',
  user_EmailId: 'Identificación de correo electrónico del empleado para la comunicación',
  user_phone: 'Contacto no. de empleado',
  user_mobile: 'Contacto no. de empleado',
  user_Username: 'Nombre de usuario para iniciar sesión en el sistema',
  user_Password: 'Contraseña para iniciar sesión en el sistema',
  user_Roles: 'Rol de acceso para empleado en brij',
  user_Designation: 'Designación de empleado',
  user_Cinema: 'Cine al que pertenece el empleado',
  user_Employee: 'Código único de identificación del empleado en la organización',
  floatAmount_User: 'Seleccione el empleado o usuario POS al que se le da la cantidad flotante',
  floatAmount_Shift: 'Seleccione el turno del empleado para la cantidad flotante actual',
  floatAmount_PosOperation: 'Seleccione la máquina POS asignada al empleado',
  floatAmount_Amount: 'Ingrese la cantidad total dada al empleado como cantidad flotante',
  refund_Cinema: 'Selecciona el nombre del cine',
  refund_Screens: 'Seleccionar pantallas',
  refund_Movie: 'Seleccionar pelicula',
  refund_showTime: 'Seleccionar hora del espectáculo',
  refund_RefundReason: 'Motivo de reembolso que reembolso se debe hacer',
  hardwareSettings_SelectCinema: 'Seleccione el cine donde está configurado el hardware',
  hardwareSettings_Username: 'Nombre de usuario para el sistema, si lo hay',
  hardwareSettings_Password: 'Contraseña para el sistema, si la hay',
  hardwareSettings_SelectedHardwareType: 'Seleccione el tipo de hardware',
  hardwareSettings_HardwareUniqueCode: 'Ingrese el código único / dirección Mac / ID de CPU del hardware',
  hardwareSettings_HardwareName: 'Nombre del hardware',
  hardwareSettings_ConnectionPort: 'Puerto de conexión del hardware',
  hardwareSettings_installBy: 'Quien instaló el hardware',
  rawmaterial_tooltip: 'El material básico del que se hacen las recetas de F&B',
  rawmaterial_Code: 'Código único',
  quantityalert_tooltip: 'El límite de umbral en el cual el sistema notificará al gerente para ordenar la materia prima',
  description_tooltip: 'Informar a la materia prima',
  us_start_date: 'Fecha de inicio de un turno',
  us_end_date: 'Fecha de finalización de un turno',
  cinemas_code: 'Ingrese el código del cine',
  cinema_Organization: 'Seleccione organizacion',
  rawMaterial_unitofmeasurement: 'Magnitud definida de una cantidad definida y adoptada por convención',
  vendor_TinNumber: 'Número de información fiscal del vendedor',
  vendor_TaxNumber: 'Número de impuesto o número de PAN del vendedor',
  vendor_TaxType: 'Tipo de impuesto',
  vendor_address: 'Dirección del vendedor',
  vendor_location: 'Ubicación en el mapa de Google del proveedor',
  vendorMapping_selectCinema: 'Nombre del cine para vincular al proveedor que suministra la materia prima',
  vendorMapping_selectVendor: 'Nombre del vendedor que suministra la materia prima.',
  vendorMapping_selectRawMaterial: 'Materia prima suministrada por el proveedor seleccionado',
  vendorMapping_Quantity: 'Cantidad de materia prima para establecer el costo',
  vendorMapping_SelectUnitOfMeasurement: 'UOM para la cantidad seleccionada para establecer el costo',
  vendorMapping_rate: 'Costo al que se suministrará la materia prima',
  cinemaCurrency_tooltip: 'Moneda utilizada para transacciones en el cine.',
  cinemaCountry_tooltip: 'El país donde se encuentra el cine.',
  cinemaState_tooltip: 'Estado donde se encuentra el cine',
  cinemaCity_tooltip: 'Ciudad donde se ubica el cine',
  seatTypeSeatno_tooltip: 'Número total de asientos en cine',
  movieselectcinema_tooltip: 'Seleccione el cine donde el distribuidor está compartiendo la película',
  purchaseOrder_selectCinema: 'Seleccione el cine para el que se crea la orden de compra',
  purchaseOrder_selectVendor: 'Seleccione el proveedor que proporcionará las materias prima',
  purchaseOrder_RawMaterials: 'Las materias primas utilizadas en el cine para concesión u oficina',
  purchaseOrder_Quantity: 'La cantidad de materia prima requerida',
  purchaseOrder_selectUOM: 'La unidad de medida de la materia prima',
  purchaseOrder_TotalAmount: 'Cantidad bruta',
  purchaseOrder_PurchaseOrderDate: 'Fecha de orden de compra',
  recipe_code_tooltip: 'Código único para receta',
  fnbitems_tooltip: 'Nombre de la receta',
  fnbitems_itemCode_tooltip: 'Código único para un artículo',
  fnbitems_foodcategory_tooltip: 'La categoría a la que pertenece el artículo',
  foodorder_tooltip: 'El orden en que aparecerán las categorías en Pos',
  itemcostprice_fnb: 'Seleccione un artículo de F&B',
  itemcostprice_costPrice: 'Precio de costo del artículo',
  ratecard_cinema: 'Seleccione el cine al que está vinculada la hoja de tarifas',
  ratecard_chargesettingname: 'Nombre de la configuración de carga',
  ratecard_ratecardname: 'Nombre la hoja de tarifas para identificar temprano',
  ratecard_Amount: 'Precio por asiento',
  fnbpricing_Cinemaname: 'Seleccione cine para agregar precio F&B',
  fnbpricing_Fnbitem: 'El artículo F&B que se venderá',
  fnbpricing_ratecard: 'La hoja de tarifas define la cantidad para el artículo F&B',
  fnbpricing_netAmount: 'Cantidad neta para el artículo',
  fnbpricing_totalamount: 'Cantidad bruta para el artículo',
  fnbpricing_fromdate: 'La fecha a partir de la cual la cantidad es válida',
  fnbpricing_todate: 'Los datos a los que es válido el importe',
  fnbwastage_selectcinema: 'Seleccione cine para descartar desperdicio',
  fnbwastage_selectconcession: 'Seleccionar nivel de concesión',
  fnbwastage_rawmaterials: 'Seleccione las materias primas',
  fnbwastage_selectunitofmeasurements: 'Seleccionar unidad de medida',
  fnbwastage_selectquantity: 'Selecciona la cantidad',
  remark: 'Observación',
  fnbwastage_accountableuser: 'Desperdicio realizado por el usuario',
  fnbwastage_approvedby: 'El gerente que necesita aprobar',
  fnbwastage_reasons: 'Razón por la cual pasó el desperdicio',
  fnbwastage_fireamount: 'La cantidad que debe pagar el usuario',
  fnbwastage_fromdate: 'Fecha de desperdicio registrada',

  // MENU

  exhibitor_management: 'Gestión de expositores',
  user_management: 'Gestión de usuarios',
  department: 'Departamento',
  seatType: 'Tipo de asiento',
  seatLayout: 'Diseño del asiento',
  chargesettings: 'Configuraciones de carga',
  floatAmount: 'Cantidad flotante',
  hardwareSettings: 'Configuraciones de hardware',
  shareType: 'Tipo de acción',
  cinemaMasters: 'Maestros de cine',
  rating: 'Clasificación',
  vendorMapping: ' Mapeo de proveedores',
  purchaseOrder: 'Orden de compra',
  foodCategories: 'Categorías de alimentos',
  fbItems: 'Artículos F&B',
  store: 'Tienda',
  stock: 'Valores',
  concessionStock: 'Stock de concesión',
  rateCard: 'Tarjeta de tarifa',
  fbPricing: 'Precios de F&B',
  wastemanagement: 'Gestión de residuos',
  roleAccess: 'Acceso a roles',
  foodNbev: 'Alimentos y bebidas',
  concession: 'Concesión',
  storeManagement: 'Administración de la tienda',
  storemanagement: 'Administración de la tienda',
  sm_store_name: 'Nombre de la tienda',
  sm_store_slug: 'Slug de tienda',
  sm_order: 'Orden',
  sm_store_name_tooltip: 'Nombre de la tienda para ser visible en el sistema',
  sm_store_slug_tooltip: 'Identificador de una tienda particular utilizada para configuraciones técnicas',
  sm_order_tooltip: 'El orden en que se mostrará el nivel de tienda múltiple'
}
