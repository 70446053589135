const state = {
    currentSideNavRoute: null,
    cureentRoute: null,
    //    currentSideNavRoute: null,
}

const mutations = {
    SET_CURRENT_SIDE_NAV_ROUTE: (state, payload) => {
        state.currentSideNavRoute = payload
    },
}

const actions = {
    setCurrentSideNavRoute({ commit }, payload) {
        console.log('payload :', payload);
        commit('SET_CURRENT_SIDE_NAV_ROUTE', payload)
    },

}

const getters = {
    getCurrentSideNavRoute: state => state.currentSideNavRoute,
}

export default {
    state,
    mutations,
    actions,
    getters
}
