import moment from "moment";
import Vue from 'vue';
import ReportsService from '@/services/ReportsService';
import { router } from '@/router';
import store from '@/store';

function initialState() {
    return {
        performanceReportsList: [],
        performanceRecordsCharges: [],
        performanceExcelFileName: null,
        flag: {
            isCinema: false,
        },
        performanceObject: {
            fromDate: new Date(),
            toDate: new Date(),
            cinema_id: null,
            cinema_name: "Select Cinema",
            d_id: null,
            d_company_name: "Select Distributor",
            movie_id: null,
            movie_title: "Select Movie",
            country_id: null,
        },
        paging: {},
        item: {}
    }
};

const state = initialState();

const mutations = {
    ON_PERFORMANCE_REPORT_STORE_STATE: (state, payload) => {
        // state = initialState();
        const s = initialState()
        Object.keys(s).forEach(key => {
            state[key] = s[key]
        })
    },
    SET_PERFORMANCE_EXCEL_FILE_NAME: (state, payload) => {
        state.performanceExcelFileName = payload;
    },
    // 
    SET_PERFORMANCE_REPORT_LIST: (state, payload) => {
        state.performanceReportsList = payload;
    },
    SET_PERFORMANCE_REPORT_CHARGES_LIST: (state, payload) => {
        state.performanceRecordsCharges = payload;
    },
    SET_PERFORMANCE_REPORT_DATES: (state, payload) => {
        payload['name'] ? state.performanceObject[payload.name] = payload['event'] ? payload.event : null : '';
    },
    SET_PERFORMANCE_REPORT_DROPDOWN: (state, payload) => {
        if (state.performanceReportsList.length > 0) {
            state.performanceReportsList = [];
        };
        switch (payload.name) {
            case 'Cinema':
                state.performanceObject.cinema_id = payload['selectedItem'] ? payload.selectedItem.cine_id : null;
                state.performanceObject.cinema_name = payload['selectedItem'] ? payload.selectedItem.cine_name : null;
                break;
            // case 'Distributor':
            //     state.performanceObject.d_id = payload['selectedItem'] ? payload.selectedItem.d_id : null;
            //     state.performanceObject.d_company_name = payload['selectedItem'] ? payload.selectedItem.d_company_name : null;
            //     break
            // case 'MasterMovie':
            //     state.performanceObject.movie_id = payload['selectedItem'] ? payload.selectedItem.movie_id : null;
            //     state.performanceObject.movie_title = payload['selectedItem'] ? payload.selectedItem.movie_title : null;
            //     break
            default:
                console.log('FilmCollection :Sorry, we are enable to set value.');
                break;
        }
    },
    SET_PERFORMANCE_REPORT_FLAG: (state, payload) => {
        switch (payload.name) {
            case 'isCinema':
                state.flag.isCinema = payload['value'] ? payload.value === 0 ? false : true : false;
                state.performanceObject.cinema_id = payload['value'] && payload.value === 0 ? null : payload.value;
                break;
            case 'isCountry':
                state.performanceObject.country_id = payload['value'] && payload.value === 0 ? null : payload.value;
                break;
            case 'isExcelDownload':
                state.performanceExcelFileName && state.performanceExcelFileName.length > 0 ? store.dispatch("globalRemoveFile", { fileName: state.performanceExcelFileName }) : '';
                state.performanceExcelFileName = payload.value;
                break;
            default:
                console.log('Sorry, we are enable to set Flag.');
                break;
        }
    }
}

const actions = {
    onResetPerformanceReportStoreState: async ({ dispatch, commit, state }, { ...payload }) => {
        await commit('ON_PERFORMANCE_REPORT_STORE_STATE');
    },
    fetchPerformanceReportList: async ({ dispatch, commit, state }, payload) => {
        state.performanceExcelFileName ? store.dispatch("globalRemoveFile", { fileName: state.performanceExcelFileName }) : '';
        await commit('SET_PERFORMANCE_REPORT_LIST', []);
        await commit('SET_PERFORMANCE_REPORT_CHARGES_LIST', []);
        let fromDate = moment(new Date(state.performanceObject.fromDate)).format("YYYY-MM-DD");
        let toDate = moment(new Date(state.performanceObject.toDate)).format("YYYY-MM-DD");

        if (new Date(fromDate) >= new Date() && new Date(toDate) >= new Date()) {
            Vue.toasted.show("From date and To date should not be greater than the current date.");
        } else if (new Date(fromDate) >= new Date()) {
            Vue.toasted.show("From date should not be greater than the current date.");
        } else if (new Date(toDate) >= new Date()) {
            Vue.toasted.show("To date should not be greater than the current date.");
        } else if (new Date(fromDate) > new Date(toDate)) {
            Vue.toasted.show("From date should not be greater than To date.");
        } else if (!state.performanceObject.cinema_id) {
            Vue.toasted.show("Please Select any Cinema.");
            // } else if (!state.filmCollectionObject.d_id) {
            //     Vue.toasted.show("Please Select any Distributor.");
            // } else if (!state.filmCollectionObject.movie_id) {
            //     Vue.toasted.show("Please Select any Movie.");
        } else {
            commit('SET_PERFORMANCE_REPORT_LIST', []);
            commit('SET_PERFORMANCE_REPORT_CHARGES_LIST', []);
            let loader = Vue.$loading.show();
            ReportsService.GetPerformanceReportList(state.performanceObject)
                .then(async (response) => {
                    const { data } = response;
                    console.log('data :', data);
                    if (data && data.status && data.Records) {
                        await commit('SET_PERFORMANCE_REPORT_LIST', data.Records || []);
                        await commit('SET_PERFORMANCE_REPORT_CHARGES_LIST', data['FinalRecordsCharges'] || []);
                        await commit('SET_PERFORMANCE_EXCEL_FILE_NAME', data['ExcelName'] || null);
                    } else Vue.toasted.show(data.message || 'Opps! Something went wrong');
                }).catch((error) => {
                    Vue.toasted.show("Catch Error on ReportStore fetchPerformanceReportList", error);
                }).finally(() => {
                    setTimeout(() => loader.hide(), 1000);
                });
        }
    },

    // On Handlers
    onPerformanceReportDateHandler: async ({ dispatch, commit, state }, payload) => {
        commit('SET_PERFORMANCE_REPORT_DATES', payload)
        await commit('SET_PERFORMANCE_REPORT_FLAG', { name: 'isExcelDownload', value: null });
        if (state.performanceObject.cinema_id) await dispatch('fetchPerformanceReportList')

    },
    onPerformanceReportDropdownHandler: async ({ dispatch, commit, state }, payload) => {
        await commit('SET_PERFORMANCE_REPORT_DROPDOWN', payload)
        await commit('SET_PERFORMANCE_REPORT_FLAG', { name: 'isExcelDownload', value: null });
    },
    onPerformanceReportFlagHandler({ dispatch, commit, state }, payload) {
        commit('SET_PERFORMANCE_REPORT_FLAG', payload)
    }
}

const getters = {
    getPerformanceReportList: state => state.performanceReportsList,
    getPerformanceReportFromDate: state => state.performanceObject.fromDate,
    getPerformanceReportToDate: state => state.performanceObject.toDate,
    // getFilmCollectionReportSelectedDistributor: state => state.performanceObject.d_company_name,
    getPerformanceReportSelectedCinema: state => state.performanceObject.cinema_name,
    getPerformanceReportsIsCinema: state => state.flag.isCinema,
    // getFilmCollectionReportSelectedMovie: state => state.performanceObject.movie_title,

    // Get File name
    getPerformanceExcelFileName: state => state.performanceExcelFileName,

    // GET RECORDS CHARGES
    getPerformanceReportChargeList: state => state.performanceRecordsCharges,
};

export default {
    state,
    mutations,
    actions,
    getters
}
