import Vue from 'vue'
// import createPersistedState from 'vuex-persistedstate'
import Vuex from 'vuex'

import modules from './modules'
Vue.use(Vuex)

export default new Vuex.Store({
  modules,
  plugins: [
    // createPersistedState()
  ],
  strict: process.env.NODE_ENV !== 'production'
})
