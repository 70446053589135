import Api from './Api'

export default {
    GetFnBList(payload) {
        return Api().post('/pos/admin/getfnbitems', payload)
    },
    GetFnBCategories() {
        return Api().get('/pos/admin/getfnbcategories')
    },
    GetFnBSubCategories(payload) {
        return Api().get(`/pos/admin/getfnbsubcategories/${payload.parentid}`)
    },
    CallCheckout(payload) {
        return Api().post(`/pos/admin/checkout`, payload)
    },
    GetFnBAdditionalCharges(payload) {
        return Api().get(`/pos/admin/getfnbaddtionalprice/${payload.fp_id}`)
    },
    OnFinalOrderSubmit(payload) {
        return Api().post(`/pos/admin/fnbtransaction`, payload)
    },
    OnAddRawMaterialIntoStore(payload) {
        return Api().post('/pos/admin/addrawmaterialintostore', payload)
    },
    OnSaveFnBPhoneNumber(payload) {
        return Api().put('/pos/admin/fnbsavephonenumber', payload)
    },
    OnChangeFnBPaymnetStatus(payload) {
        return Api().put('/pos/admin/fnbsetpaymentstatus', payload)
    }
}